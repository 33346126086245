"use client";

import React from "react";

import { getProfileName, TaskStatus } from "@palette.tools/model";
import { Asset, Category, Project, Task, UserProfile, Workspace } from "@palette.tools/model.client";
import { Group, Item } from "@palette.tools/react.dogs";

const DAY_IN_MS = 86400000;


export type TaskRow = {
  rowType: 'task',
  id: string;
  title: string;
  duration: number | undefined;
  end: Date | undefined;
  updated_at: number | undefined;
  status: TaskStatus;
  asset_name: string | undefined;
  assignee_id: string | undefined;
  assignee_name: string | undefined;
  assignee_image_url: string | undefined;
}


export type TaskTypeRow = {
  rowType: 'task_type',
  id: string;
  title: string;
  duration: number | undefined;
  end: Date | undefined;
  updated_at: number | undefined;
}


export type CategoryRow = {
  rowType: 'category',
  id: string;
  title: string;
  duration: number | undefined;
  end: Date | undefined;
  updated_at: number | undefined;
  emoji: string | undefined;
}


export type CategoryTimelineRow = TaskRow | TaskTypeRow | CategoryRow;


function expandDateRange(durationA: number | undefined, endA: Date | undefined, durationB: number | undefined, endB: Date | undefined): [number | undefined, Date | undefined] {

  if (durationA === undefined || endA === undefined)
    if (durationB !== undefined && endB !== undefined)
      return [durationB, endB];
    else return [undefined, undefined];

  if (durationB === undefined || endB === undefined)
    if (durationA !== undefined && endA !== undefined)
      return [durationA, endA];
    else return [undefined, undefined];

  const startA = endA.getTime() - durationA;
  const startB = endB.getTime() - durationB;
  const start = Math.min(startA, startB);
  const end = Math.max(endA.getTime(), endB.getTime());
  return [end - start, new Date(end)];
}


export interface CategoryTimelineModel {
  workspace: Workspace | null,
  project: Project | null,
  categories: Category[],
  tasksById: Record<string, Task>,
  categoryIndexById: Record<string, number>,
  assetByTask: Record<string, Asset>,
  assetIndexByTaskId: Record<string, number>,
  taskTypesByCategory: Record<string, string[]>,
  tasksByCategoryAndTaskType: Record<string, Record<string, Task[]>>,
  profileByTask: Record<string, UserProfile | null>,
  profiles: UserProfile[],
  showCompletedTasks?: boolean,
}


export interface CategoryTimelineCallbacks {
  getCanEditTask?: (task_id: string) => boolean,
  onAssignTasks?: (task_ids: string[], assignee: UserProfile | null) => Promise<void>,
  onScheduleTasks?: (updates: { id: string, estimate: number, deadline: number }[]) => Promise<void>,
  onClickTask?: (task_id: string) => void,
  onClickCategory?: (category_id: string) => void,
  onSwapTimeline?: (timelineKey: string) => Promise<void>,
  setShowCompletedTasks?: (showCompletedTasks: boolean) => void,
  onUnscheduleTasks?: (task_ids: string[]) => Promise<void>,
}


export const constructTimelineTree = ({
  categories,
  categoryIndexById,
  assetByTask,
  assetIndexByTaskId,
  taskTypesByCategory,
  tasksByCategoryAndTaskType,
  profileByTask,
  showCompletedTasks,
  optimisticUpdates,
  isPreviewingUpdates,
}: {
  optimisticUpdates: Record<string, {
    id: string,
    estimate: number,
    deadline: number,
    updated_at: number,
  }>,
  isPreviewingUpdates: boolean,
} & CategoryTimelineModel) => {

  let lastModified = 0;
  const prePreviewValues: Record<string, { estimate: number, deadline: number }> = {};

  const getMinimumValue = (key: 'estimate', value: number | null | undefined) => {
    if (value !== null && value !== undefined) return value;
    return DAY_IN_MS * 2;
  };

  const getOptimisticValue = (id: string, key: 'estimate' | 'deadline' | 'updated_at', defaultValue: number) => {
    return optimisticUpdates[id] ? optimisticUpdates[id][key] : defaultValue;
  };
  
  const getSortValue = (id: string, key: 'estimate' | 'deadline', defaultValue: number) => {
    return isPreviewingUpdates ? defaultValue : getOptimisticValue(id, key, defaultValue);
  };

  return categories
    .sort((a, b) => (categoryIndexById[a.id] || 0) - (categoryIndexById[b.id] || 0))
    .reduce((acc, category) => {
      const [items, rowsById, ancestorsById] = acc;
      ancestorsById[category.id] = [];
      lastModified = Math.max(lastModified, category.data.updated_at || 0);

      const [categoryItems, categoryDuration, categoryEnd] = taskTypesByCategory[category.id]
        .reduce((acc, taskType) => {
          const [categoryItems, categoryDuration, categoryEnd] = acc;
          const taskTypeId = category.id + ":" + taskType;

          const [taskTypeTasks, taskTypeDuration, taskTypeEnd, taskTypeUpdatedAt] = tasksByCategoryAndTaskType[category.id][taskType]
            .filter(task => showCompletedTasks ? true : task.data.status !== TaskStatus.Complete)
            .sort((a, b) => {
              if (!a.data.deadline && !b.data.deadline) return 0;
              if (!a.data.deadline) return 1;
              if (!b.data.deadline) return -1;
              if (!a.data.estimate && !b.data.estimate) return 0;
              if (!a.data.estimate) return 1;
              if (!b.data.estimate) return -1;

              const aEnd = new Date(getSortValue(a.id, 'deadline', a.data.deadline || Date.now()));
              const bEnd = new Date(getSortValue(b.id, 'deadline', b.data.deadline || Date.now()));
              if (aEnd.getTime() !== bEnd.getTime()) return aEnd.getTime() - bEnd.getTime();
              
              const aEstimate = getSortValue(a.id, 'estimate', getMinimumValue('estimate', a.data.estimate));
              const bEstimate = getSortValue(b.id, 'estimate', getMinimumValue('estimate', b.data.estimate));
              const aStart = new Date(aEnd.getTime() - aEstimate);
              const bStart = new Date(bEnd.getTime() - bEstimate);
              if (aStart.getTime() !== bStart.getTime()) return aStart.getTime() - bStart.getTime();

              if (assetIndexByTaskId[a.id] !== undefined && assetIndexByTaskId[b.id] !== undefined) return assetIndexByTaskId[a.id] - assetIndexByTaskId[b.id];
              
              if (a.data.name && b.data.name && a.data.name !== b.data.name) return a.data.name.localeCompare(b.data.name);
              
              return a.id.localeCompare(b.id);
            })
            .reduce((acc, task) => {
              if (!prePreviewValues[task.id]) {
                prePreviewValues[task.id] = {
                  estimate: task.data.estimate || 0,
                  deadline: task.data.deadline || Date.now()
                };
              }
              const [taskTypeItems, taskTypeDuration, taskTypeEnd, taskTypeUpdatedAt] = acc;

              const taskRow: Item<CategoryTimelineRow> = {
                __id: task.id,
                data: {
                  rowType: 'task' as const,
                  id: task.id,
                  title: task.data.name || "",
                  duration: getOptimisticValue(task.id, 'estimate', getMinimumValue('estimate', task.data.estimate)),
                  end: task.data.deadline == null || task.data.deadline == undefined ? undefined : new Date(getOptimisticValue(task.id, 'deadline', task.data.deadline)),
                  updated_at: task.data.updated_at == null || task.data.updated_at == undefined ? undefined : getOptimisticValue(task.id, 'updated_at', task.data.updated_at),
                  status: task.data.status || TaskStatus.NotStarted,
                  asset_name: assetByTask[task.id]?.data.name,
                  assignee_id: profileByTask[task.id]?.id,
                  assignee_name: profileByTask[task.id] ? getProfileName(profileByTask[task.id]) : undefined,
                  assignee_image_url: profileByTask[task.id]?.data.image_url,
                },
              };

              taskTypeItems.push(taskRow);
              rowsById[task.id] = taskRow.data;

              const [expandedDuration, expandedEnd] = expandDateRange(taskTypeDuration, taskTypeEnd, taskRow.data.duration, taskRow.data.end);

              return [taskTypeItems, expandedDuration, expandedEnd, taskTypeUpdatedAt] as [Item<CategoryTimelineRow>[], number | undefined, Date | undefined, number | undefined];
            }, [[] as Item<CategoryTimelineRow>[], undefined, undefined, undefined] as [Item<CategoryTimelineRow>[], number | undefined, Date | undefined, number | undefined]);

          const taskTypeItem: Group<CategoryTimelineRow> = {
            __id: taskTypeId,
            data: {
              rowType: 'task_type' as const,
              id: taskTypeId,
              title: taskType,
              duration: taskTypeDuration,
              end: taskTypeEnd,
              updated_at: taskTypeUpdatedAt,
            },
            __children: taskTypeTasks,
          };

          categoryItems.push(taskTypeItem);
          rowsById[taskTypeId] = taskTypeItem.data;
          
          const [expandedDuration, expandedEnd] = expandDateRange(categoryDuration, categoryEnd, taskTypeDuration, taskTypeEnd);

          return [categoryItems, expandedDuration, expandedEnd, taskTypeUpdatedAt, prePreviewValues] as [Item<CategoryTimelineRow>[], number | undefined, Date | undefined, number | undefined, Record<string, { estimate: number, deadline: number }>];
        }, [[] as Item<CategoryTimelineRow>[], undefined, undefined, undefined, {}] as [Item<CategoryTimelineRow>[], number | undefined, Date | undefined, number | undefined, Record<string, { estimate: number, deadline: number }>]);

      const categoryRow: Group<CategoryTimelineRow> = {
        __id: category.id,
        data: {
          rowType: 'category' as const,
          id: category.id,
          title: category.data.name || "",
          duration: categoryDuration,
          end: categoryEnd,
          updated_at: category.data.updated_at,
          emoji: category.data.emoji,
        },
        __children: [
          ...categoryItems,
        ],
      };

      items.push(categoryRow);
      rowsById[category.id] = categoryRow.data;

      return [items, rowsById, ancestorsById, lastModified, prePreviewValues] as [(Item<CategoryTimelineRow> | Group<CategoryTimelineRow>)[], Record<string, CategoryTimelineRow>, Record<string, string[]>, number, Record<string, { estimate: number, deadline: number }>];
    }, [[], {}, {}, 0, {}] as [(Item<CategoryTimelineRow> | Group<CategoryTimelineRow>)[], Record<string, CategoryTimelineRow>, Record<string, string[]>, number, Record<string, { estimate: number, deadline: number }>]);

}



export const useCategoryTimeline = (model: CategoryTimelineModel) => {

  // Preview optimistic updates
  const [isPreviewingUpdates, setIsPreviewingUpdates] = React.useState(false);
  const lastOptimisticUpdate = React.useRef(0);
  const [optimisticUpdates, setOptimisticUpdates] = React.useState<Record<string, {
    id: string,
    estimate: number,
    deadline: number,
    updated_at: number,
  }>>({});

  const [items, rowsById, ancestorsById, lastModified, prePreviewValues] = React.useMemo(() => {
    const result = constructTimelineTree({
      ...model,
      optimisticUpdates,
      isPreviewingUpdates,
    });
    return result;
  }, [model, optimisticUpdates, isPreviewingUpdates]);

  React.useEffect(() => {
    if (lastModified != lastOptimisticUpdate.current && !isPreviewingUpdates) {
      lastOptimisticUpdate.current = lastModified;
      setOptimisticUpdates({});
    }
  }, [lastModified, isPreviewingUpdates]);

  return {
    items,
    rowsById,
    ancestorsById,
    lastModified,
    prePreviewValues,
    setOptimisticUpdates: React.useCallback((updates: Record<string, {
      id: string,
      estimate: number,
      deadline: number,
    }>) => {
      setIsPreviewingUpdates(false);
      const updated_at = Date.now();
      setOptimisticUpdates(Object.fromEntries(Object.entries(updates).map(([id, update]) => [id, { ...update, updated_at }])));
    }, [setOptimisticUpdates]),
    setPreviewUpdates: React.useCallback((updates: Record<string, {
      id: string,
      estimate: number,
      deadline: number,
    }>) => {
      if (Object.keys(updates).length === 0) {
        setIsPreviewingUpdates(false);
      } else {
        const updated_at = Date.now();
        setOptimisticUpdates(Object.fromEntries(Object.entries(updates).map(([id, update]) => [id, { ...update, updated_at }])));
        setIsPreviewingUpdates(true);
      }
    }, [setOptimisticUpdates, setIsPreviewingUpdates]),
  };
}