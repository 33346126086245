"use client";

import React from "react";
import { Command, CommandGroup, CommandInput, CommandItem } from "../shadcn/components/ui/command";
import { Button } from "../shadcn/components/ui/button";
import { Avatar } from "../image";
import { TypographySmall, TypographyXSmall } from "../typography";
import { Show } from "../utils";
import { UserProfile } from "@palette.tools/model.client";
import { getProfileName } from "@palette.tools/model";

interface UserSelectorProps {
  selectableUsers?: UserProfile[],
  onSelectUser?: (selectedUser: UserProfile) => void,
  onClearSelection?: () => void,
}

export const UserSelector = React.forwardRef<HTMLDivElement, UserSelectorProps>(({
  selectableUsers = [],
  onSelectUser,
  onClearSelection,
}, ref) => {

  return <div ref={ref} className="w-full flex flex-col">
    <Command className="max-h-[250px]">
      <CommandInput placeholder="Search users..." />
      <CommandGroup className="overflow-auto">
        {selectableUsers.map((user) => (
          <CommandItem
            key={user.id}
            onSelect={() => {
              onSelectUser && onSelectUser(user);
            }}
            className="flex flex-row gap-x-2"
          >
            <Avatar user={user} size={35} className="select-none rounded-full" />
            <div className="flex flex-col">
              <TypographySmall>{getProfileName(user)}</TypographySmall>
              <TypographyXSmall>{user.data.email}</TypographyXSmall>
            </div>
          </CommandItem>
        ))}
      </CommandGroup>
    </Command>

    <div className="h-4" />

    <Show when={!!onClearSelection}>
      <div className="w-full flex flex-row">
        <>
          <div className="flex-1" />
          <Button variant="destructive" onClick={() => onClearSelection && onClearSelection()}>Clear</Button>
        </>
      </div>
    </Show>
  </div>

});
