"use client";

import React, { useState } from "react";
import { Modal } from "./Modal";
import { LoadingIcon } from "@palette.tools/react.icons";
import { TypographyMedium } from "../typography";
import { Doc, Project, Workspace, transact, useAuth, usePermissions } from "@palette.tools/model.client";
import { delete_entity } from "@palette.tools/api.client";


export const DeleteDocModal: React.FC<{
  open: boolean,
  onClose: () => void,
  workspace: Workspace | null,
  project?: Project | null,
  doc: Doc | null,
}> = ({
  open,
  onClose,
  workspace,
  project,
  doc,
}) => {

  const { profile, isLoading: isLoadingProfile } = useAuth();

  // Permissions hooks.
  const { canEditWorkspace, canEditProject } = usePermissions({ workspace, project });

  // Regular hooks
  const [isDeleting, setDeleting] = useState(false);

  let content: React.ReactNode;
  if (isLoadingProfile) {
    content = <div className="w-[24px] h-[24px]"><LoadingIcon /></div>;
  }
  else if (profile) {
    if (!canEditWorkspace && !canEditProject) {
      content = <TypographyMedium className="italic">You are not allowed to delete this document.</TypographyMedium>
    }
    else {
      content = <TypographyMedium>Are you sure you want to delete this document?</TypographyMedium>
    }
  }
  else {
    content = <TypographyMedium className="text-destructive">An error occurred!</TypographyMedium>
  }

  const submitDisabled = (
    (!canEditWorkspace && !canEditProject)
    || !doc
    || isLoadingProfile
  );

  return <Modal
    open={open}
    header={"Delete Document"}
    description={""}
    primaryButtonClass="bg-destructive hover:bg-destructive/50 text-destructive-foreground"
    primaryButtonContent={isDeleting ? <><div className="w-[16px] h-[16px]"><LoadingIcon /></div>{" Deleting"}</> : "Delete"}
    secondaryButtonContent="Cancel"
    primaryButtonDisabled={submitDisabled}
    onClose={() => {
      onClose();
    }}
    onSubmit={async () => {
      if (!submitDisabled && !!doc && !!workspace) {
        setDeleting(true)

        await delete_entity('doc', doc.id)
          .finally(() => {
            setDeleting(false);
            onClose && onClose();
          });
      }
    }}
  >
    {content}
    <div className="min-h-[10px]" />
  </Modal>

}
