"use client";

import React from "react";
import { LogOutIcon, MoreHorizontalIcon, PlusIcon, SettingsIcon, Trash2Icon } from "lucide-react";

import { Project, ProjectGroup, Workspace, getPermissions, useAuth, usePermissions  } from "@palette.tools/model.client";
import { GroupDeadZoneFn, useDogs } from "@palette.tools/react.dogs";
import ImageFallback from "../../image/ImageFallback";
import { DropdownMenu, DropdownMenuItem, DropdownMenuSeparator, DropdownMenuTrigger, DropdownMenuContent } from "../../shadcn/components/ui/dropdown-menu";
import { ContextMenu, ContextMenuContent, ContextMenuItem, ContextMenuSeparator, ContextMenuTrigger } from "../../shadcn/components/ui/context-menu";
import { useWorkspaceProjectCardList, WorkspaceProjectCardListCallbacks, WorkspaceProjectCardListModel } from "./model";
import { cn } from "@/lib/utils";


const ProjectCard = React.forwardRef<HTMLDivElement, {
  hoverable?: boolean,
  project: Project,
  group?: ProjectGroup,
  workspace: Workspace,
  callbacks: WorkspaceProjectCardListCallbacks,
} & React.ComponentPropsWithoutRef<"div">>(({
  hoverable = true,
  project,
  group,
  workspace,
  callbacks,
  ...props
}, ref) => {

  const { profile } = useAuth();
  const { canEditWorkspace, canEditProject, canDeleteProject } = getPermissions({ profile, workspace, project });

  const getProjectDropdownMenuContent = (
    project: Project,
  ) => {
    let items: React.ReactNode[] = [];

    if (!profile || !project) return items;

    if (canEditWorkspace && group) {
      items.push(<DropdownMenuItem
        key="ungroup"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          callbacks.onClickUngroupProject?.(project, group);
        }}>
        <LogOutIcon width={16} height={16} />&nbsp;&nbsp;Remove from Group
      </DropdownMenuItem>)
    }

    if (canEditWorkspace && !group) {
      items.push(<DropdownMenuItem
        key="create_group"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          callbacks.onClickCreateProjectGroup?.(project)
        }}>
        <PlusIcon />&nbsp;&nbsp;Create Group
      </DropdownMenuItem>)
    }

    if (canEditProject) {
      items.push(<DropdownMenuItem
        key="settings"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          callbacks.onClickProjectSettings?.(project)
        }}>
        <SettingsIcon width={16} height={16} />&nbsp;&nbsp;Settings
      </DropdownMenuItem>)
      items.push(<DropdownMenuSeparator key="separator1" />)
    }

    if ((project.links.profile || []).find(x => x.id == profile.id)) {
      items.push(<DropdownMenuItem
        key="leave"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          callbacks.onClickLeaveProject?.(project);
        }}>
        <LogOutIcon width={16} height={16} className="stroke-destructive"/>&nbsp;&nbsp;<span className="text-destructive">Leave</span>
      </DropdownMenuItem>)
    }

    if (canDeleteProject) {
      items.push(<DropdownMenuItem
        key="delete"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          callbacks.onClickDeleteProject?.(project);
        }}>
        <Trash2Icon width={16} height={16} className="stroke-destructive"/>&nbsp;&nbsp;<span className="text-destructive">Delete</span>
      </DropdownMenuItem>)
    }
    return items;
  }

  const getProjectContextMenuContent = (
    project: Project,
  ) => {
    let items: React.ReactNode[] = [];

    if (!profile) return items;

    if (canEditWorkspace && group) {
      items.push(<ContextMenuItem
        key="ungroup"
        onSelect={() => callbacks.onClickUngroupProject?.(project, group)}>
        <LogOutIcon width={16} height={16} />&nbsp;&nbsp;Remove from Group
      </ContextMenuItem>)
    }

    if (canEditWorkspace && !group) {
      items.push(<ContextMenuItem
        key="create_group"
        onSelect={() => callbacks.onClickCreateProjectGroup?.(project)}>
        <PlusIcon />&nbsp;&nbsp;Create Group
      </ContextMenuItem>)
    }

    if (canEditProject) {
      items.push(<ContextMenuItem
        key="settings"
        onSelect={() => callbacks.onClickProjectSettings?.(project)}>
        <SettingsIcon width={16} height={16} />&nbsp;&nbsp;Settings
      </ContextMenuItem>)
      items.push(<ContextMenuSeparator key="separator1" />)
    }

    if ((project.links.profile || []).find(x => x.id == profile.id)) {
      items.push(<ContextMenuItem
        key="leave"
        onSelect={() => callbacks.onClickLeaveProject?.(project)}>
        <LogOutIcon width={16} height={16} className="stroke-destructive"/>&nbsp;&nbsp;<span className="text-destructive">Leave</span>
      </ContextMenuItem>)
    }

    if (canDeleteProject) {
      items.push(<ContextMenuItem
        key="delete"
        onSelect={() => callbacks.onClickDeleteProject?.(project)}>
        <Trash2Icon width={16} height={16} className="stroke-destructive"/>&nbsp;&nbsp;<span className="text-destructive">Delete</span>
      </ContextMenuItem>)
    }

    return items;
  }
  
  const contextMenuContent = getProjectContextMenuContent(project);
  const dropdownMenuContent = getProjectDropdownMenuContent(project);

  const card = <div
    ref={ref}
    {...props}
    onClick={() => callbacks.onClickProject?.(project)}
    className={cn(
      "flex flex-col rounded-xl p-[12px] border-transparent gap-y-[16px] cursor-pointer select-none",
      props.className,
      hoverable ? "hover:bg-muted group" : undefined,
    )}
  >
    <div className="relative">
      {dropdownMenuContent.length ? <DropdownMenu>
          <DropdownMenuTrigger
            className="absolute bottom-[8px] right-[8px]"
            onClick={(e) => {e.preventDefault(); e.stopPropagation()}}
          >
            <div
            className="absolute bottom-[8px] right-[8px] z-10 w-[30px] h-[30px] bg-accent/50 flex items-center place-content-center rounded-md hover:bg-primary opacity-0 group-hover:opacity-100">
              <MoreHorizontalIcon />
            </div>
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            {dropdownMenuContent}
          </DropdownMenuContent>
        </DropdownMenu> : undefined}
        <ImageFallback
          className="rounded-xl pointer-events-none"
          src={project.data.thumbnail_url}
          height="113"
          width="200"
          alt={`Thumbnail for ${project.data.name}`}
        />
    </div>
    <span className="text-foreground text-base">{project.data.name}</span>
  </div>;

  if (contextMenuContent.length) {
    return <ContextMenu key={`context_menu_${project.id}`}>
      <ContextMenuTrigger>
        {card}
      </ContextMenuTrigger>
      <ContextMenuContent>
        {contextMenuContent}
      </ContextMenuContent>
    </ContextMenu>
  }

  return card;

});


const ProjectGroupCard = React.forwardRef<HTMLDivElement, {
  workspace: Workspace,
  group: ProjectGroup,
  projects: Project[],
  callbacks: WorkspaceProjectCardListCallbacks,
  hoverable?: boolean,
} & React.ComponentPropsWithoutRef<"div">>(({
  workspace,
  group,
  projects,
  callbacks,
  hoverable = true,
  ...props
}, ref) => {
  const firstNineProjects = projects.slice(0, 9);
  
  const { canEditWorkspace } = usePermissions({ workspace });
  
  const getProjectGroupDropdownMenuContent = (
    group: ProjectGroup,
  ) => {
    if (!canEditWorkspace) return [];
    return [
      <DropdownMenuItem
        key="rename"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          callbacks.onClickRenameProjectGroup?.(group);
        }}>
        <SettingsIcon width={16} height={16} />&nbsp;&nbsp;Rename
      </DropdownMenuItem>,
      <DropdownMenuItem
        key="delete"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          callbacks.onClickDeleteProjectGroup?.(group);
        }}>
        <Trash2Icon width={16} height={16} className="stroke-destructive"/>&nbsp;&nbsp;
        <span className="text-destructive">Delete</span>
      </DropdownMenuItem>
    ];
  }

  const getProjectGroupContextMenuContent = (
    group: ProjectGroup,
  ) => {
    if (!canEditWorkspace) return [];
    return [
      <ContextMenuItem
        key="rename"
        onSelect={() => callbacks.onClickRenameProjectGroup?.(group)}>
        <SettingsIcon width={16} height={16} />&nbsp;&nbsp;Rename
      </ContextMenuItem>,
      <ContextMenuItem
        key="delete"
        onSelect={() => callbacks.onClickDeleteProjectGroup?.(group)}>
        <Trash2Icon width={16} height={16} className="stroke-destructive"/>&nbsp;&nbsp;
        <span className="text-destructive">Delete</span>
      </ContextMenuItem>
    ];
  }

  const contextMenuContent = getProjectGroupContextMenuContent(group);
  const dropdownMenuContent = getProjectGroupDropdownMenuContent(group);

  const card = (
    <div
      ref={ref}
      {...props}
      className={cn(
        "flex flex-col rounded-xl p-[12px] border-transparent gap-y-[16px] cursor-pointer select-none",
        props.className,
        hoverable ? "hover:bg-muted group" : undefined,
      )}
      onClick={() => callbacks.onClickProjectGroup?.(group)}
    >
      <div className="relative flex flex-row flex-wrap gap-[8px] w-[200px] h-[113px] bg-[#3D3D3D]/50 rounded-xl items-center place-content-center">
        {firstNineProjects.map((project, index) => (
          <ImageFallback
            key={project.id}
            className="rounded-md w-full h-full object-cover"
            src={project.data.thumbnail_url}
            width="50"
            height="28"
            alt={`Project thumbnail ${index + 1}`}
          />
        ))}
        {Array(9 - firstNineProjects.length).fill(0).map((_, index) => (
          <div key={`empty-${index}`} className="bg-black/20 rounded-md w-[50px] h-[28px]" />
        ))}
        {dropdownMenuContent.length ? <DropdownMenu>
          <DropdownMenuTrigger
            className="absolute bottom-[8px] right-[8px]"
            onClick={(e) => {e.preventDefault(); e.stopPropagation()}}
          >
            <div className="w-[30px] h-[30px] bg-accent/50 flex items-center place-content-center rounded-md hover:bg-primary opacity-0 group-hover:opacity-100">
              <MoreHorizontalIcon />
            </div>
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            {dropdownMenuContent}
          </DropdownMenuContent>
        </DropdownMenu> : undefined}
      </div>
      <span className="text-foreground text-base">{group.data.name}</span>
    </div>
  );

  return (
    contextMenuContent.length ? <ContextMenu>
      <ContextMenuTrigger>{card}</ContextMenuTrigger>
      <ContextMenuContent>{contextMenuContent}</ContextMenuContent>
    </ContextMenu> : card
  );
});


export const WorkspaceProjectCardList: React.FC<{
  model: WorkspaceProjectCardListModel,
  callbacks: WorkspaceProjectCardListCallbacks,
}> = ({
  model,
  callbacks,
}) => {

  const { items, projectById, groupById, projectsByGroupId } = useWorkspaceProjectCardList(model.workspace);
  const { canEditWorkspace } = usePermissions({ ...model });

  const { render } = useDogs({
    items,

    treatChildrenAsParentBody: true,

    insertItems(items, index, parentId) {
      const item = items[0];
      if (!item) return;
      const project = projectById[item.data];
      const parentGroup = parentId ? groupById[parentId] : null;

      if (!canEditWorkspace || items.length === 0) return;

      if (parentId) {
        if (parentGroup && project) {
          callbacks.onMoveProjectsInGroup?.(parentGroup, [project], "end");
        }
      }
      else {
        callbacks.onMoveProjectsAndGroupsInWorkspace?.(model.workspace, items.map(x => projectById[x.data] || groupById[x.data]), index);
      }

    },

    allowInsert({insertedItems, index, parentId}) {
      const item = insertedItems[0];
      if (!item) return false;
      const isGroup = '__children' in item && !!item.__children;
      return (canEditWorkspace && !(parentId && isGroup));
    },

    groupDeadZone({ group, insertedItems, depth, orientation }) {
      if (!insertedItems.some(x => '__children' in x && !!x.__children)) return "4px";
    },

    enableOrdering: canEditWorkspace,
    orientation: "row",
  });
   
  return render({

    root(x) {
      return <div ref={x.ref} className="flex flex-row flex-wrap">
        {x.children}
      </div>
    },

    group(x) {
      const group = groupById[x.group.data];
      const projects = projectsByGroupId[group.id];
      return <div key={x.id} ref={x.ref} className={cn(
        // Base
        "flex flex-row gap-x-[4px]",

        // Cursor
        x.isHovering && x.isDragging ? "cursor-grabbing" : undefined,

        // Last
        !x.isLast ? "pr-[4px]" : undefined,

      )}>
        <div className={cn(
          // Base
          "w-[1px] h-full",
          
          // Dropping
          x.isDroppingOnStart || x.isDroppingOnPreviousEnd ? "bg-primary" : undefined,
          
        )}/>
        <ProjectGroupCard
          workspace={model.workspace}
          group={group}
          projects={projects}
          callbacks={callbacks}
          hoverable={!x.isDragging}
          className={cn(
            x.isDroppingInside ? "border-primary border-[1px]" : "border-transparent border-[1px]",
          )}
        />
        {x.isLast ? <div className={cn(

          // Base
          "w-[1px] h-full",
          
          // Dropping
          x.isDroppingOnEnd ? "bg-primary" : undefined)

        }/> : undefined}
      </div>
    },

    groupRenderDeps(x) {
      const group = groupById[x.group.data];
      const projects = projectsByGroupId[group.id];
      const newestProjectUpdatedAt = projects.reduce((acc, project) => Math.max(acc, project.data.updated_at ?? 0), 0);
      return [
        x.id,
        group.data.updated_at,
        projects.length,
        newestProjectUpdatedAt,
        x.isDroppingInside,
        x.isDroppingOnStart,
        x.isDroppingOnPreviousSiblingEnd,
        x.isDroppingOnEnd,
        x.isFirst,
        x.isLast,
        x.isHovering,
      ];
    },

    child(x) {
      const project = projectById[x.item.data];
      return <div key={x.id} ref={x.ref} className={cn(
        // Base
        "flex flex-row gap-x-[4px]",

        // Cursor
        x.isHovering && x.isDragging ? "cursor-grabbing" : undefined,

        // Last
        !x.isLast ? "pr-[4px]" : undefined,
      )}>
        <div className={cn(
          // Base
          "w-[1px] h-full",
          
          // Dropping
          x.isDroppingOnStart || x.isDroppingOnPreviousSiblingEnd ? "bg-primary" : undefined,
          
        )}/>
        <ProjectCard
          workspace={model.workspace}
          project={project}
          hoverable={!x.isDragging}
          callbacks={callbacks}
        />
        {x.isLast ? <div className={cn(

          // Base
          "w-[1px] h-full",
          
          // Dropping
          x.isDroppingOnEnd ? "bg-primary" : undefined)

        }/> : undefined}
      </div>
    },

    childRenderDeps(x) {
      const project = projectById[x.item.data];
      return [
        x.id,
        project.data.updated_at,
        x.isDroppingOnStart,
        x.isDroppingOnPreviousSiblingEnd,
        x.isDroppingOnEnd,
        x.isFirst,
        x.isLast,
        x.isHovering,
      ];
    },

  })

}