"use client";

import React from "react";

import { cn, Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@palette.tools/react";

export const TabButton = <T extends string>({
  tabKey,
  selectedTabKey,
  tooltipText,
  onClickTab,
  children,
}: {
  tabKey: T,
  selectedTabKey?: T,
  tooltipText?: string,
  onClickTab?: ((tabId: T) => void) | ((tabId: T | null) => void) | ((tabId: T | null | undefined) => void),
  children: React.ReactNode,
}) => {

  const icon = <div
    data-selected={selectedTabKey === tabKey}
    onClick={() => onClickTab?.(tabKey)}
    className={cn(

      "w-[36px] h-[36px] flex flex-col items-center place-content-center",

      // Display
      "rounded-md bg-transparent cursor-pointer",

      // Hover
      "hover:bg-muted/75",

      // Selected
      "data-[selected=true]:bg-secondary data-[selected=true]:text-primary-foreground data-[selected=true]:border-primary",

    )}
  >
    {children}
  </div>

  if (tooltipText) {
    return <TooltipProvider delayDuration={0}>
      <Tooltip>
        <TooltipTrigger>
          {icon}
        </TooltipTrigger>
        <TooltipContent>
          {tooltipText}
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  }

  return icon;

}
