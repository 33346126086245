"use client";

import * as TooltipPrimitive from "@radix-ui/react-tooltip"
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip';
import React from 'react';
import { cn } from "@/lib/utils";

// Global state management
const tooltipRegistry = new Set<() => void>();
let isGlobalListenerAttached = false;
let globalMousePosition = { x: 0, y: 0 };
let scrollThrottleTimeout: number | undefined;
let moveThrottleTimeout: number | undefined;

// Store cleanup functions
let removeScrollListener: (() => void) | null = null;
let removeMoveListener: (() => void) | null = null;

const teardownGlobalListeners = (handler: () => void) => {
  tooltipRegistry.delete(handler);

  // If this was the last tooltip, clean up global listeners
  if (tooltipRegistry.size === 0) {
    if (scrollThrottleTimeout) {
      window.clearTimeout(scrollThrottleTimeout);
    }
    if (moveThrottleTimeout) {
      window.clearTimeout(moveThrottleTimeout);
    }
    
    removeScrollListener?.();
    removeMoveListener?.();
    
    removeScrollListener = null;
    removeMoveListener = null;
    isGlobalListenerAttached = false;
  }
};

const setupGlobalListeners = () => {
  if (isGlobalListenerAttached) return;

  // Only check position on scroll
  const throttledScrollHandler = () => {
    if (scrollThrottleTimeout) return;
    
    scrollThrottleTimeout = window.setTimeout(() => {
      tooltipRegistry.forEach(handler => handler());
      scrollThrottleTimeout = undefined;
    }, 100);
  };

  // Remove the throttle for mousemove and only trigger when actually leaving
  const mouseMoveHandler = (e: MouseEvent) => {
    globalMousePosition = { x: e.clientX, y: e.clientY };
  };

  window.addEventListener('scroll', throttledScrollHandler, true);
  window.addEventListener('mousemove', mouseMoveHandler);
  
  removeScrollListener = () => window.removeEventListener('scroll', throttledScrollHandler, true);
  removeMoveListener = () => window.removeEventListener('mousemove', mouseMoveHandler);
  
  isGlobalListenerAttached = true;
};

const FastTooltipContent = React.forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content>
>(({ className, sideOffset = 4, ...props }, ref) => (
  <TooltipPrimitive.Content
    ref={ref}
    sideOffset={sideOffset}
    className={cn(
      "z-50 overflow-hidden rounded-md border bg-popover px-3 py-1.5 text-sm text-popover-foreground shadow-md",
      className
    )}
    {...props}
  />
))

FastTooltipContent.displayName = TooltipPrimitive.Content.displayName

export const FastTooltip = ({
  children,
  tooltipText,
  side = "top",
  delay = 0,
  className,
}: {
  children: React.ReactNode,
  tooltipText: string,
  delay?: number,
  side?: "top" | "right" | "bottom" | "left",
  className?: string,
}) => {
  
  const [open, setOpen] = React.useState(false);
  const timeoutRef = React.useRef<number>();
  const triggerRef = React.useRef<HTMLElement>(null);

  const checkPosition = React.useCallback(() => {
    if (!triggerRef.current || !open) return;
    
    const rect = triggerRef.current.getBoundingClientRect();
    const { x, y } = globalMousePosition;
    
    // Add a small buffer zone to prevent flickering
    const buffer = 2;
    if (
      x < rect.left - buffer ||
      x > rect.right + buffer ||
      y < rect.top - buffer ||
      y > rect.bottom + buffer
    ) {
      setOpen(false);
    }
  }, [open]);

  React.useEffect(() => {
    setupGlobalListeners();
    tooltipRegistry.add(checkPosition);
    
    return () => {
      teardownGlobalListeners(checkPosition);
    };
  }, [checkPosition]);

  const handlePointerEnter = (e: React.PointerEvent) => {
    globalMousePosition = { x: e.clientX, y: e.clientY };
    
    timeoutRef.current = window.setTimeout(() => {
      setOpen(true);
    }, delay);
  };

  const handlePointerLeave = () => {
    if (timeoutRef.current) {
      window.clearTimeout(timeoutRef.current);
    }
    setOpen(false);
  };

  return <Tooltip
    open={open}
  >
    <TooltipTrigger
      asChild
      ref={triggerRef as React.LegacyRef<HTMLButtonElement>}
      onPointerEnter={handlePointerEnter}
      onPointerLeave={handlePointerLeave}
    >
      {children}
    </TooltipTrigger>
    <FastTooltipContent
      side={side}
      className={className}
    >
      <p className="break-all w-full h-full">
        {tooltipText}
      </p>
    </FastTooltipContent>
  </Tooltip>;
}