"use client";

import React, { ElementType, MouseEventHandler } from 'react';
import { Button, ButtonProps, buttonVariants } from '../shadcn/components/ui/button';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '../shadcn/components/ui/dropdown-menu';
import { overrideElement } from '../utils';
import { VariantProps } from 'class-variance-authority';
import { cn } from '../shadcn/lib/utils';
import { CheckIcon, ChevronDownIcon } from 'lucide-react';
import { CaretSortIcon } from '@radix-ui/react-icons';


export interface SwapButtonOption {
  id: string;
  label: string;
  icon?: React.ReactNode;
  className?: string;
  onClick?: () => void;
}

interface SwapButtonCustomProps extends VariantProps<typeof buttonVariants> {
  options?: SwapButtonOption[];
  currentId?: string;
  dropdownButtonClassName?: string;
  mainButtonClassName?: string;
  iconOnly?: boolean;
  onClickMainButton?: MouseEventHandler<HTMLButtonElement>;
}

export const SwapButton = overrideElement<'button', SwapButtonCustomProps>(({
  props,
  className,
  customProps: {
    options,
    currentId,
    variant = 'outline',
    iconOnly = false,
  },
}) => {

  return <DropdownMenu>
    <DropdownMenuTrigger asChild>
      <Button variant={variant} className={cn('', className)} {...props as ButtonProps}>
        {options?.find(x => x.id === currentId)?.icon}
        {!iconOnly && options?.find(x => x.id === currentId)?.label}
        <CaretSortIcon />
      </Button>
    </DropdownMenuTrigger>
    <DropdownMenuContent
      onCloseAutoFocus={(e) => e.preventDefault()}
    >
      {options?.map((option, i) => (
        <DropdownMenuItem key={`${option.label}_${i}`} onClick={option.onClick} className={cn('gap-x-[8px]', option.className)}>
          <CheckIcon className="data-[selected=true]:opacity-100 opacity-0 w-[16px] h-[16px]" data-selected={option.id === currentId} />
          {option.label}
        </DropdownMenuItem>
      ))}
    </DropdownMenuContent>
  </DropdownMenu>
}, [
  'options',
  'currentId',
  'onClickMainButton',
  'mainButtonClassName',
  'variant',
  'iconOnly',
]);