"use client";

import React, { useState } from "react";
import { Modal } from "./Modal";
import { LoadingIcon } from "@palette.tools/react.icons";
import { TypographyMedium } from "../typography";
import { Input } from "../shadcn/components/ui/input";
import { Label } from "../shadcn/components/ui/label";
import { Project, Workspace, transact, useAuth, usePermissions, id as uuid } from "@palette.tools/model.client";
import { ExternalLinkIcon } from "lucide-react";
import { useCustomerPortalRedirect } from "../triggers/CutomerPortalRedirectTrigger";
import { CreationProps, SchemaEntry } from "@palette.tools/model.core";


export const CreateProjectModal: React.FC<{
  workspace: Workspace | null,
  projects: Project[],
  open: boolean,
  onClose: () => void,
  callbacks?: {
    onCreateProject?: (props: Parameters<typeof Project.create>[0]) => Promise<void>,
  },
}> = ({
  workspace,
  projects,
  open,
  onClose,
  callbacks,
}) => {

  const { profile, isLoading: isLoadingProfile } = useAuth();

  // Permissions
  const tooManyFreeProjects = workspace?.data.tier === "free" && projects.length >= 1;
  const canCreateProject = usePermissions({ profile, workspace }).canCreateProject && !tooManyFreeProjects;

  // Regular hooks
  const [name, setName] = useState("");
  const [isCreating, setCreating] = useState(false);

  // Customer portal redirect state
  const { redirect, state: { isLoading: isLoadingCustomerPortal } } = useCustomerPortalRedirect(workspace);

  let content: React.ReactNode;
  if (isLoadingProfile) {
    content = <div className="w-[24px] h-[24px]"><LoadingIcon /></div>;
  }
  else if (profile) {
    if (tooManyFreeProjects) {
      content = <>
        <TypographyMedium className="italic">Sorry, only 1 project is allowed in a free workspace.</TypographyMedium>
      </>
    }
    else if (!canCreateProject) {
      content = <TypographyMedium className="italic">Sorry, you are not allowed to create projects under this workspace</TypographyMedium>
    }
    else {
      content = <><Label htmlFor="name">Name</Label>
        <Input
          autoFocus
          name="name"
          type="text"
          placeholder="Name of your project..."
          onChange={(e) => setName(e.target.value)}
        />
      </>
    }
  }
  else {
    content = <TypographyMedium className="text-destructive">An error occurred!</TypographyMedium>
  }

  const submitDisabled = (
    !name.trim()
    || isLoadingProfile
    || !canCreateProject
  ) && !tooManyFreeProjects;

  const onCreateProject = callbacks?.onCreateProject ? callbacks.onCreateProject : async ({ id, name }: Parameters<typeof Project.create>[0]) => {
    if (!canCreateProject || submitDisabled || !profile || !workspace) return;
    id = id || uuid();
    await transact(
      Project.create({ id, name }, { after: (key, id) => [
        ...workspace.link(key, id),
      ] }),
      profile.link("project", id),
      Project.assign_role(id, "admin", profile.id, { after: (key, id) => [
        ...workspace.link(key, id),
      ] }),
    );
  };

  return <Modal
    open={open}
    header={"Create Project"}
    description={"A place where teams collaborate"}
    primaryButtonContent={
      tooManyFreeProjects ? <>
        {isLoadingCustomerPortal ? <div className="h-[16px] w-[16px]"><LoadingIcon />{"  "}</div> : null}
        Upgrade
        <ExternalLinkIcon width={15} height={15} />
      </>
      : isCreating ? <><div className="w-[16px] h-[16px]"><LoadingIcon /></div>{" Creating"}</> : "Create"
    }
    secondaryButtonContent="Cancel"
    primaryButtonDisabled={submitDisabled}
    onClose={() => {
      setName("");
      onClose();
    }}
    onSubmit={async () => {
      if (tooManyFreeProjects) {
        redirect();
      }
      else if (canCreateProject && !submitDisabled && !!profile && !!workspace) {
        setCreating(true);

        onCreateProject({ id: uuid(), name: name.trim() }).finally(() => {
          setCreating(false);
          onClose();
        });

      }
    }}
  >
    {content}
    <div className="min-h-[10px]" />
  </Modal>

}
