"use client"

import { WorkspaceRoles } from "@palette.tools/model";
import { getWorkspaceRole, Project, ProjectGroup, useAuth, Workspace } from "@palette.tools/model.client";
import { getProjectAndGroupsListFromWorkspace, getProjectListFromGroup, ListInsertPosition } from "@palette.tools/model.core";
import { Item, Group } from "@palette.tools/react.dogs";
import React from "react";


export interface WorkspaceProjectCardListModel {
  workspace: Workspace,
  projects: Project[],
  groups: ProjectGroup[],
}


export interface WorkspaceProjectCardListCallbacks {
  onClickProject?: (project: Project) => void,
  onClickProjectSettings?: (project: Project) => void,
  onClickDeleteProject?: (project: Project) => void,
  onClickLeaveProject?: (project: Project) => void,
  onClickProjectGroup?: (group: ProjectGroup) => void,
  onClickCreateProjectGroup?: (project?: Project) => void,
  onClickUngroupProject?: (project: Project, group: ProjectGroup) => void,
  onClickDeleteProjectGroup?: (group: ProjectGroup) => void,
  onClickRenameProjectGroup?: (group: ProjectGroup) => void,
  onMoveProjectsAndGroupsInWorkspace?: (workspace: Workspace, items: (Project | ProjectGroup)[], position: ListInsertPosition<Project | ProjectGroup>) => void,
  onMoveProjectsInGroup?: (group: ProjectGroup, items: Project[], position: ListInsertPosition<Project>) => void,
}


const constructItems = (projectsAndGroupsList: (Project | ProjectGroup)[], projectsByGroupId: Record<string, Project[]>): (Item<string> | Group<string>)[] => {
  
  return [
    ...projectsAndGroupsList.map(projectOrGroup => ({
      __id: projectOrGroup.id,
      data: projectOrGroup.id,
      __children: projectOrGroup.key === "group__project" ? projectsByGroupId[projectOrGroup.id]?.map(project => ({
        __id: project.id,
        data: project.id,
      })) : undefined,
    })),
  ]
}


export const useWorkspaceProjectCardList = (workspace: Workspace) => {
  
  const { profile } = useAuth();

  const { items, projects, groups, projectById, groupById, projectsByGroupId } = React.useMemo(() => {
     
    const isWorkspaceAdmin = workspace && profile ? getWorkspaceRole(workspace, profile) === WorkspaceRoles.admin : false;
    const projects = (workspace.links.project || []).filter(x => isWorkspaceAdmin || (x.links.profile || []).some(y => y.id === profile?.id)).filter(Boolean);

    const { groups, projectsByGroupId } = (workspace.links.group__project || []).reduce((acc, projectGroup) => {
      const projectsInGroup = getProjectListFromGroup(projectGroup).filter(x => isWorkspaceAdmin || (x.links.profile || []).some(y => y.id === profile?.id)).filter(Boolean);
      if (projectsInGroup.length > 0) {
        acc.groups.push(projectGroup);
        acc.projectsByGroupId[projectGroup.id] = projectsInGroup;
      }
      return acc;
    }, { groups: [] as ProjectGroup[], projectsByGroupId: {} as Record<string, Project[]> }) || {};

    const projectAndGroupsList = getProjectAndGroupsListFromWorkspace(workspace).filter(x => projects.some(y => y.id === x.id) || groups.some(y => y.id === x.id));

    return {
      items: constructItems(projectAndGroupsList, projectsByGroupId),
      projects,
      groups,
      projectById: projects.reduce((acc, project) => { acc[project.id] = project; return acc; }, {} as Record<string, Project>),
      groupById: groups.reduce((acc, group) => { acc[group.id] = group; return acc; }, {} as Record<string, ProjectGroup>),
      projectsByGroupId,
    };

  }, [workspace, profile?.id]);

  return {
    items,
    projects,
    groups,
    projectById,
    groupById,
    projectsByGroupId,
  }

}