"use client";

import React from "react";
import { TimelineRoot, TimelineHeader, TimelineBody, TimelineTitle, TimelineLane, TimelineDateRange, TimelineSidebar, TimelineContent, useStickyCollapse, UserPopover, TimelineCorner, Button, TooltipProvider, TimelineZoom } from "@palette.tools/react";
import { IterateChild, IterateGroup, IterateRoot, RenderableChild, RenderableGroup, RenderableRoot, SelectionManager, useDogs } from "@palette.tools/react.dogs";
import { CategoryRow, CategoryTimelineCallbacks, CategoryTimelineModel, CategoryTimelineRow, TaskRow, TaskTypeRow, useCategoryTimeline } from "./model";
import { cn } from "@/lib/utils";
import { ChevronDownIcon } from "@radix-ui/react-icons";
import { TaskStatus } from "@palette.tools/model";
import { Avatar } from "../../image";
import { FolderIcon, UserIcon, UsersIcon } from "lucide-react";
import { UserProfile } from "@palette.tools/model.client";
import { UserPlusIcon } from "lucide-react";
import { Emoji } from "emoji-picker-react";
import { EmojiDefaultAssetCategory } from "@palette.tools/utils/src/constants";
import { SwapButton } from "../../buttons";
import { useCategoryTimelineSelection } from "./selection";
import { TextOverflowTooltip } from "../../utils";


const TASK_STYLES = {
  [TaskStatus.NotStarted]: "bg-gray-500",
  [TaskStatus.NeedsWork]: "bg-yellow-500",
  [TaskStatus.InProgress]: "bg-yellow-500",
  [TaskStatus.NeedsReview]: "bg-orange-500",
  [TaskStatus.Complete]: "bg-green-500",
}


const TitleRoot = ({ item }: { item: RenderableRoot<CategoryTimelineRow> }) => {
  return <TimelineSidebar ref={item.ref} className="min-w-[276px]">
    {item.children}
  </TimelineSidebar>;
}
TitleRoot.deps = (item: IterateRoot<CategoryTimelineRow>) => {
  return [item.id];
}


const LaneRoot = ({ item }: { item: RenderableRoot<CategoryTimelineRow> }) => {
  return <TimelineContent ref={item.ref}>
    {item.children}
  </TimelineContent>;
}
LaneRoot.deps = (item: IterateRoot<CategoryTimelineRow>) => {
  return [item.id];
}


// Category


const CategoryTitle = ({ item, isCollapsed, toggleCollapsed }: { item: RenderableGroup<CategoryRow>, isCollapsed: boolean, toggleCollapsed: () => void }) => {

  return <React.Fragment key={item.id}>
    <TimelineTitle
      ref={item.ref}
      rowId={item.id}
      dogs-dragging-enabled="false"
      dogs-selection-enabled="false"
      onClick={e => { e.stopPropagation(); toggleCollapsed() }}
      className={cn(

        // Base
      "h-[30px] flex flex-row gap-x-[4px] items-center group/title",

      // Typography
      "text-md font-semibold",

      // Cursors
      "cursor-pointer select-none",

    )}>
      <ChevronDownIcon
        dogs-selection-enabled="false"
        width={16}
        height={16}
        className={cn("transition-transform duration-100", isCollapsed ? "-rotate-90" : "")}
      />
      <Emoji unified={item.group.data.emoji || EmojiDefaultAssetCategory} size={16} />
      {item.group.data.title}
      <div className="flex-1" />
    </TimelineTitle>
    {isCollapsed ? null : item.children}
  </React.Fragment>;

};
CategoryTitle.deps = (item: IterateGroup<CategoryRow>) => {
  return [
    item.id,
    item.group.__children.length,
    item.group.data.updated_at,
  ];
}


const CategoryLane = ({ item, isCollapsed }: { item: RenderableGroup<CategoryRow>, isCollapsed: boolean }) => {
  const start: Date | undefined = item.group.data.end && item.group.data.duration ? new Date(item.group.data.end.getTime() - (item.group.data.duration ?? 0)) : undefined;
  const end: Date | undefined = item.group.data.end ?? new Date();

  return <React.Fragment key={item.id}>
    <TimelineLane
      ref={item.ref}
      rowId={item.id}
      dogs-dragging-enabled="false"
      dogs-selection-enabled="false"
      className={cn(

        // Base
        "h-[30px]",

    )}>
      {start && end && <TimelineDateRange start={start} end={end} />}
    </TimelineLane>
    {isCollapsed ? null : item.children}
  </React.Fragment>;

};
CategoryLane.deps = (item: IterateGroup<CategoryRow>) => {
  return [
    item.id,
    item.group.__children.length,
    item.group.data.updated_at,
  ];
}


// Task Type


const TaskTypeTitle = ({ item, isCollapsed, toggleCollapsed }: { item: RenderableGroup<TaskTypeRow>, isCollapsed: boolean, toggleCollapsed: () => void }) => {

  return <React.Fragment key={item.id}>
    <TimelineTitle
      ref={item.ref}
      rowId={item.id}
      dogs-dragging-enabled="false"
      dogs-selection-enabled="false"
      onClick={e => { e.stopPropagation(); toggleCollapsed() }}
      className={cn(

        // Base
        "h-[30px] flex flex-row gap-x-[4px] items-center pl-[24px] group/title",

        // Typography
        "text-sm font-semibold select-none",

        // Cursors
        "cursor-pointer select-none",

      )}
    >
      <ChevronDownIcon
        dogs-selection-enabled="false"
        width={16}
        height={16}
        className={cn("transition-transform duration-100", isCollapsed ? "-rotate-90" : "")}
      />
      {item.group.data.title}
    </TimelineTitle>
    {isCollapsed ? null : item.children}
  </React.Fragment>;

};
TaskTypeTitle.deps = (item: IterateGroup<TaskTypeRow>) => {
  return [
    item.id,
    item.group.__children.length,
    item.group.data.updated_at,
  ];
}


const TaskTypeLane = ({ item, isCollapsed }: { item: RenderableGroup<TaskTypeRow>, isCollapsed: boolean }) => {
  const start: Date | undefined = item.group.data.end && item.group.data.duration ? new Date(item.group.data.end.getTime() - (item.group.data.duration ?? 0)) : undefined;
  const end: Date | undefined = item.group.data.end ?? new Date();

  return <React.Fragment key={item.id}>
    <TimelineLane
      ref={item.ref}
      rowId={item.id}
      className={cn(

        // Base
        "h-[30px]",

      )}
    >
      {start && end && <TimelineDateRange
        start={start}
        end={end}
        className="bg-white/50"
      />}
    </TimelineLane>
    {isCollapsed ? null : item.children}
  </React.Fragment>;

};
TaskTypeLane.deps = (item: IterateGroup<TaskTypeRow>) => {
  return [
    item.id,
    item.group.__children.length,
    item.group.data.updated_at,
  ];
}


// Task


const TaskTitle = ({
  editable,
  item,
  profiles,
  onAssign,
  onClickTask,
  onClickAssignee,
}: {
  editable: boolean,
  item: RenderableChild<TaskRow>,
  profiles: UserProfile[],
  onAssign?: (user: UserProfile | null) => void,
  onClickTask?: (taskId: string) => void,
  onClickAssignee: () => void,
}) => {

  const avatar = item.item.data.assignee_id
    ? <Avatar
      size={20}
      user_id={item.item.data.assignee_id}
      user_name={item.item.data.assignee_name}
      user_image_url={item.item.data.assignee_image_url}
      dogs-selection-enabled="false"
      dogs-dragging-enabled="false"
    />
    : <div className="w-[20px] h-[20px] flex items-center place-content-center border-[1px] border-white/20 rounded-full">
      {editable
        ? <UserPlusIcon className="w-[12px] h-[12px]" />
        : <UserIcon className="w-[12px] h-[12px]" />
      }
    </div>

  return <TimelineTitle
    ref={item.ref}
    key={item.id}
    dogs-selection-enabled={editable ? "true" : "false"}
    rowId={item.id}
    className={cn(

      // Base
      "h-[30px] pl-[48px] flex items-center group/title",

      // Typography
      "text-xs font-semibold select-none",

      // Cursor
      editable ? "cursor-pointer" : undefined,

      // Selection
      item.isSelected ? "bg-[#202020]" : undefined,

    )}
  >
    <div className="flex flex-row min-w-[200px] max-w-[200px] overflow-hidden">
      <div className="flex-1 truncate flex flex-row items-center">
        <TextOverflowTooltip
          key={`tooltip-${item.id}-1`}
          tooltipText={item.item.data.asset_name || ""}
        >
          <span
            dogs-selection-enabled={!!onClickTask ? "false" : "true"}
            data-clickable={!!onClickTask}
            className="data-[clickable=true]:cursor-pointer"
            onClick={e => { e.stopPropagation(); onClickTask?.(item.item.data.id) }}
          >
            {item.item.data.asset_name}
          </span>
      </TextOverflowTooltip>
      </div>
      <div
        className="flex-1 truncate flex flex-row gap-x-[4px] items-center"
      >
        {editable ?
          <UserPopover
          modal
          className="flex flex-row gap-x-[4px] items-center"
          selectableUsers={profiles.filter(x => x.id !== item.item.data.assignee_id)}
          selectedUser={profiles.find(x => x.id === item.item.data.assignee_id)}
          onSelectUser={async (user) => {
            onAssign?.(user);
          }}
          onClearSelection={() => onAssign?.(null)}
          onClick={onClickAssignee}
        >
          <button
            className="bg-transparent border-none p-0 m-0"
            dogs-selection-enabled="false"
            dogs-dragging-enabled="false"
          >
            {avatar}
            <TextOverflowTooltip
              key={`tooltip-${item.id}-2`}
              tooltipText={item.item.data.assignee_name || "Unassigned"}
          >
            <span
              data-clickable={!!onAssign}
              className="data-[clickable=true]:cursor-pointer"
              dogs-selection-enabled="false"
              dogs-dragging-enabled="false"
            >
              {item.item.data.assignee_name}
            </span>
            </TextOverflowTooltip>
          </button>
        </UserPopover>
        : avatar
        }
      </div>
    </div>
  </TimelineTitle>

};
TaskTitle.deps = (item: IterateChild<TaskRow>) => {
  return [
    item.id,
    item.isSelected,
    item.item.data.updated_at,
    item.item.data.assignee_id,
    item.item.data.assignee_name,
    item.item.data.assignee_image_url,
  ];
}


const TaskLane = ({ editable, item, onPreviewUpdate, onUpdate, onRangeMouseDown, onClickGhostRange, ghostRangeDays }: { editable: boolean, item: RenderableChild<TaskRow>, onPreviewUpdate: (id: string, originalStart: Date, originalEnd: Date, start: Date, end: Date) => void, onUpdate: (id: string, originalStart: Date, originalEnd: Date, start: Date, end: Date) => void, onRangeMouseDown: () => void, onClickGhostRange: (id: string, start: Date, end: Date) => void, ghostRangeDays: number }) => {
  const start: Date | undefined = item.item.data.end && item.item.data.duration ? new Date(item.item.data.end.getTime() - (item.item.data.duration ?? 0)) : undefined;
  const end: Date | undefined = item.item.data.end ?? new Date();

  return <TimelineLane
    ref={item.ref}
    key={item.id}
    dogs-selection-enabled="false"
    rowId={item.id}
    showGhostRange
    ghostRangeDays={ghostRangeDays}
    onClickGhostRange={(start, end) => onClickGhostRange(item.id, start, end)}
    className={cn(

      // Base
      "h-[30px]",

      // Selection
      item.isSelected ? "bg-[#202020]" : undefined,

    )}
  >
    {start && end && <TimelineDateRange
      dogs-selection-enabled={editable ? "true" : "false"}
      disabled={!editable}
      start={start}
      end={end}
      onPreviewUpdate={(...x) => onPreviewUpdate(item.id, ...x)}
      onUpdate={(...x) => onUpdate(item.id, ...x)}
      className={cn(
        TASK_STYLES[item.item.data.status || TaskStatus.NotStarted],
        item.isSelected ? "ring-[0.5px] ring-white ring-offset-[1px] ring-offset-black/50" : "",
      )}
      onMouseDown={onRangeMouseDown}
    />}
  </TimelineLane>;

};
TaskLane.deps = (item: IterateChild<TaskRow>) => {
  return [
    item.id,
    item.isSelected,
    item.item.data.updated_at,
  ];
}

const OPTIONS_DEFAULT = {
  ghostRangeDays: 3,
}

export const CategoryTimeline = ({
  className,
  model,
  callbacks,
  options = OPTIONS_DEFAULT,
}: {
  className?: string;
  model: CategoryTimelineModel;
  callbacks: CategoryTimelineCallbacks;
  options?: {
    ghostRangeDays?: number;
  };
}) => {


  // Callbacks
  const callbacksRef = React.useRef(callbacks);
  callbacksRef.current = callbacks;
  

  // Model
  const { items, rowsById, prePreviewValues, setPreviewUpdates, setOptimisticUpdates } = useCategoryTimeline(model);
  const rowsByIdRef = React.useRef(rowsById);
  rowsByIdRef.current = rowsById;
  const prePreviewValuesRef = React.useRef(prePreviewValues);
  prePreviewValuesRef.current = prePreviewValues;


  // Selection
  const selectedTaskIdsRef = React.useRef<string[]>([]);
  const { ignoreNextDeselection } = useCategoryTimelineSelection();
  const selectionManagerRef: React.MutableRefObject<SelectionManager | null> = React.useRef(null);


  // Collapse
  const [isCollapsed, setCollapsed] = useStickyCollapse({ key: "category-timeline-collapsed" });
  const toggleCollapsed = React.useCallback((id: string, defaultValue?: boolean) => setCollapsed(id, !isCollapsed(id, defaultValue)), [
    isCollapsed,
    setCollapsed,
  ]);


  // Assign
  const assignSelectedTasks = React.useCallback((assignee: UserProfile | null) => {
    const selectedTaskRows = Array.from(selectedTaskIdsRef.current)
      .map(id => rowsByIdRef.current[id])
      .filter(x => x?.rowType === "task") as TaskRow[];

    callbacksRef.current.onAssignTasks?.(selectedTaskRows.map(x => x.id), assignee);
  }, []);


  // Schedule
  const getTaskScheduleUpdates = React.useCallback((originalStart: Date, originalEnd: Date, start: Date, end: Date) => {
    const selectedTaskRows = Array.from(selectedTaskIdsRef.current)
      .map(id => rowsByIdRef.current[id])
      .filter(x => x?.rowType === "task") as TaskRow[];

    const newEstimate = end.getTime() - start.getTime();
    const estimateChange = newEstimate - (originalEnd.getTime() - originalStart.getTime());
    const deadlineChange = end.getTime() - originalEnd.getTime();

    return selectedTaskRows
      .filter(row => row.end && row.duration != undefined)
      .map(row => {
        const original = prePreviewValuesRef.current[row.id];
        return {
          id: row.id,
          estimate: Math.max(original.estimate + estimateChange, 86400000),
          deadline: original.deadline + deadlineChange
        };
      });
  }, []);

  const previewScheduleSelectedTasks = React.useCallback((id: string, originalStart: Date, originalEnd: Date, start: Date, end: Date) => {
    ignoreNextDeselection(id);
    const updates = getTaskScheduleUpdates(originalStart, originalEnd, start, end);
    setPreviewUpdates(Object.fromEntries(updates.map(x => [x.id, x])));
  }, [getTaskScheduleUpdates, setPreviewUpdates, ignoreNextDeselection]);

  const updateScheduledTasks = React.useCallback((id: string, originalStart: Date, originalEnd: Date, start: Date, end: Date) => {
    const updates = getTaskScheduleUpdates(originalStart, originalEnd, start, end);
    setOptimisticUpdates(Object.fromEntries(updates.map(x => [x.id, x])));
    callbacksRef.current.onScheduleTasks?.(updates);
  }, [getTaskScheduleUpdates, setOptimisticUpdates]);

  const scheduleTaskClick = React.useCallback(async (id: string, start: Date, end: Date) => {
    const update: { id: string, estimate: number, deadline: number } = {
      id,
      estimate: end.getTime() - start.getTime(),
      deadline: end.getTime(),
    };
    setOptimisticUpdates({});
    if (callbacksRef.current.onScheduleTasks) {
      await callbacksRef.current.onScheduleTasks?.([update]);
    }
    selectionManagerRef.current?.setSelectedIds(new Set([id]), null);
  }, [setOptimisticUpdates]);
  

  // Unschedule
  React.useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      // Check if any input elements are focused
      const activeElement = document.activeElement;
      if (activeElement && (
        activeElement.tagName === 'INPUT' ||
        activeElement.tagName === 'TEXTAREA' ||
        (activeElement as HTMLElement).isContentEditable
      )) {
        return;
      }

      // Handle delete/backspace keys
      if ((e.key === 'Delete' || e.key === 'Backspace') && selectedTaskIdsRef.current.length > 0) {
        e.preventDefault();
        setOptimisticUpdates({});
        const selectedTaskRows = Array.from(selectedTaskIdsRef.current)
          .map(id => rowsByIdRef.current[id])
          .filter(x => x?.rowType === "task") as TaskRow[];
        callbacksRef.current.onUnscheduleTasks?.(selectedTaskRows.map(x => x.id));
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, []);


  // DOGS configuration
  const { render } = useDogs({
    items,
    selectionMode: "multi",
    selectionManagerRef,
    onSelectionChanged(selectedIds) {
      selectedTaskIdsRef.current = Array.from(selectedIds);
    }
  });


  return <TooltipProvider>
  <TimelineRoot className={className} persistence={{ key: "category-timeline", storage: "local" }}>
    <TimelineHeader>
      <TimelineCorner className="h-full flex flex-row items-center justify-end p-2">
        <SwapButton
          variant="outline"
          className="h-[32px] px-[8px]"
          options={[
            { id: "phase", label: "Phase", icon: <UsersIcon className="w-[16px] h-[16px]" />, onClick: () => callbacks.onSwapTimeline?.("phase") },
            { id: "category", label: "Category", icon: <FolderIcon className="w-[16px] h-[16px]" />, onClick: () => callbacks.onSwapTimeline?.("category") },
          ]}
          currentId="category"
          iconOnly
        />
        <div className="flex-1" />
      </TimelineCorner>
    </TimelineHeader>
    <TimelineBody>

      {render({

        root: x => <TitleRoot item={x as RenderableRoot<CategoryTimelineRow>} />,

        rootRenderDeps: TitleRoot.deps,

        group: x => x.group.data.rowType === "category"
          ? <CategoryTitle
            item={x as RenderableGroup<CategoryRow>}
            isCollapsed={isCollapsed(x.group.data.id)}
            toggleCollapsed={() => toggleCollapsed(x.group.data.id)}
          />
          : <TaskTypeTitle
            item={x as RenderableGroup<TaskTypeRow>}
            isCollapsed={isCollapsed(x.group.data.id, true)}
            toggleCollapsed={() => toggleCollapsed(x.group.data.id, true)}
          />,

        groupRenderDeps: x => x.group.data.rowType === "category"
          ? [
            ...CategoryTitle.deps(x as IterateGroup<CategoryRow>),
            toggleCollapsed,
            isCollapsed(x.group.data.id)
          ]
          : [
            ...TaskTypeTitle.deps(x as IterateGroup<TaskTypeRow>),
            toggleCollapsed,
            isCollapsed(x.group.data.id, true)
          ]
        ,

        child: x =>
          <TaskTitle
            editable={!!callbacks.getCanEditTask?.(x.item.data.id)}
            item={x as RenderableChild<TaskRow>}
            profiles={model.profiles}
            onAssign={assignSelectedTasks}
            onClickTask={callbacks.onClickTask}
            onClickAssignee={() => {
              if (selectionManagerRef.current) {
                const selectedIds = selectionManagerRef.current.getSelectedIds();
                if (!selectedIds.has(x.id)) {
                  selectionManagerRef.current.setSelectedIds(new Set([x.id]), null);
                }
              }
            }}
          />,

        childRenderDeps: x =>
          [
            ...TaskTitle.deps(x as IterateChild<TaskRow>),
            callbacks.getCanEditTask?.(x.item.data.id),
            assignSelectedTasks,
          ],

      })}

      {render({

        root: x => <LaneRoot item={x as RenderableRoot<CategoryTimelineRow>} />,

        rootRenderDeps: LaneRoot.deps,

        group: x => x.group.data.rowType === "category"
          ? <CategoryLane
            item={x as RenderableGroup<CategoryRow>}
            isCollapsed={isCollapsed(x.group.data.id)}
          />
          : <TaskTypeLane
            item={x as RenderableGroup<TaskTypeRow>}
            isCollapsed={isCollapsed(x.group.data.id, true)}
          />,

        groupRenderDeps: x => x.group.data.rowType === "category"
          ? [
            ...CategoryLane.deps(x as IterateGroup<CategoryRow>),
            isCollapsed(x.group.data.id),
          ]
          : [
            ...TaskTypeLane.deps(x as IterateGroup<TaskTypeRow>),
            isCollapsed(x.group.data.id, true),
          ],

        child: x =>
          <TaskLane
            editable={!!callbacks.getCanEditTask?.(x.item.data.id)}
            item={x as RenderableChild<TaskRow>}
            onRangeMouseDown={() => ignoreNextDeselection(null)}
            onPreviewUpdate={previewScheduleSelectedTasks}
            onUpdate={updateScheduledTasks}
            onClickGhostRange={scheduleTaskClick}
            ghostRangeDays={options.ghostRangeDays ?? OPTIONS_DEFAULT.ghostRangeDays}
          />,

        childRenderDeps: x =>
          [
            ...TaskLane.deps(x as IterateChild<TaskRow>),
            callbacks.getCanEditTask?.(x.item.data.id),
            previewScheduleSelectedTasks,
            updateScheduledTasks,
            scheduleTaskClick,
            options.ghostRangeDays,
          ],

      })}

      </TimelineBody>
      <TimelineZoom />
    </TimelineRoot>
  </TooltipProvider>;

};
