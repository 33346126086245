"use client";

import React, { useState } from "react";
import { Modal } from "./Modal";
import { LoadingIcon } from "@palette.tools/react.icons";
import { TypographyMedium } from "../typography";
import { Asset, Category, Project, ProjectGroup, Task, Workspace, transact, useAuth, usePermissions } from "@palette.tools/model.client";
import { delete_entity } from "@palette.tools/api.client";
import { getProjectListFromGroup, insertProjectsAndGroupsIntoWorkspace } from "@palette.tools/model.core";


export const DeleteProjectGroupModal: React.FC<{
  open: boolean,
  onClose: () => void,
  workspace: Workspace | null,
  projectGroup: ProjectGroup | null,
}> = ({
  open,
  onClose,
  workspace,
  projectGroup,
}) => {

  const { profile, isLoading: isLoadingProfile } = useAuth();

  // Permissions hooks.
  const { canEditWorkspace } = usePermissions({ workspace });

  // Regular hooks
  const [isDeleting, setDeleting] = useState(false);

  let content: React.ReactNode;
  if (isLoadingProfile) {
    content = <div className="w-[24px] h-[24px]"><LoadingIcon /></div>;
  }
  else if (profile) {
    if (!canEditWorkspace) {
      content = <TypographyMedium className="italic">You are not allowed to delete this project group.</TypographyMedium>
    }
    else {
      content = <TypographyMedium>Projects in this group will NOT be deleted</TypographyMedium>
    }
  }
  else {
    content = <TypographyMedium className="text-destructive">An error occurred!</TypographyMedium>
  }

  const submitDisabled = (
    !canEditWorkspace
    || !projectGroup
    || isLoadingProfile
  );

  return <Modal
    open={open}
    header={"Delete Project Group"}
    description={""}
    primaryButtonClass="bg-destructive hover:bg-destructive/50 text-destructive-foreground"
    primaryButtonContent={isDeleting ? <><div className="w-[16px] h-[16px]"><LoadingIcon /></div>{" Deleting"}</> : "Delete"}
    secondaryButtonContent="Cancel"
    primaryButtonDisabled={submitDisabled}
    onClose={() => {
      onClose();
    }}
    onSubmit={async () => {
      if (!submitDisabled && !!projectGroup && !!workspace) {
        setDeleting(true)

        await delete_entity('group__project', projectGroup.id)
          .then(() => {
            transact([
              ...insertProjectsAndGroupsIntoWorkspace(workspace, [...getProjectListFromGroup(projectGroup)], { after: projectGroup }),
            ]);
          })
          .finally(() => {
            setDeleting(false);
            onClose && onClose();
          });
      }
    }}
  >
    {content}
    <div className="min-h-[10px]" />
  </Modal>

}
