"use client";

import React from "react";

import { Category, Project, Task } from "@palette.tools/model.client";
import { Group, Item } from "@palette.tools/react.dogs";



export type ProjectRow = {
  rowType: 'project',
  id: string;
  title: string;
  duration: number | undefined;
  end: Date | undefined;
  updated_at: number | undefined;
}


export type CategoryRow = {
  rowType: 'category',
  id: string;
  title: string;
  duration: number | undefined;
  end: Date | undefined;
  updated_at: number | undefined;
  emoji: string | undefined;
}


export type ProjectTimelineRow = ProjectRow | CategoryRow;


function expandDateRange(durationA: number | undefined, endA: Date | undefined, durationB: number | undefined, endB: Date | undefined): [number | undefined, Date | undefined] {

  if (durationA === undefined || endA === undefined)
    if (durationB !== undefined && endB !== undefined)
      return [durationB, endB];
    else return [undefined, undefined];

  if (durationB === undefined || endB === undefined)
    if (durationA !== undefined && endA !== undefined)
      return [durationA, endA];
    else return [undefined, undefined];

  const startA = endA.getTime() - durationA;
  const startB = endB.getTime() - durationB;
  const start = Math.min(startA, startB);
  const end = Math.max(endA.getTime(), endB.getTime());
  return [end - start, new Date(end)];
}


export interface ProjectTimelineModel {
  projects: Project[],
  projectsById: Record<string, Project>,
  categoriesById: Record<string, Category>,
  categoriesByProjectId: Record<string, Category[]>,
  tasksByCategoryId: Record<string, Task[]>,
}


export interface ProjectTimelineCallbacks {
  onClickProject?: (project: Project) => void,
  onClickCategory?: (category: Category) => void,
}


export const constructTimelineTree = ({
  projects,
  categoriesByProjectId,
  tasksByCategoryId,
}: ProjectTimelineModel) => {

  let lastModified = 0;

  return projects.reduce((acc, project) => {
    const [items, rowsById, ancestorsById] = acc;
    ancestorsById[project.id] = [];
    lastModified = Math.max(lastModified, project.data.updated_at || 0);

    const [projectItems, projectDuration, projectEnd] = (categoriesByProjectId[project.id] || []).reduce((acc, category) => {
      const [projectItems, projectDuration, projectEnd] = acc;
      lastModified = Math.max(lastModified, category.data.updated_at || 0);

      const [categoryDuration, categoryEnd] = (tasksByCategoryId[category.id] || []).reduce((acc, task) => {
        const [categoryDuration, categoryEnd] = acc;

        const duration = task.data.estimate == null || task.data.estimate == undefined ? undefined : task.data.estimate;
        const end = task.data.deadline == null || task.data.deadline == undefined ? undefined : new Date(task.data.deadline);
        const updated_at = task.data.updated_at == null || task.data.updated_at == undefined ? undefined : task.data.updated_at;

        const [expandedDuration, expandedEnd] = expandDateRange(categoryDuration, categoryEnd, duration, end);
        lastModified = Math.max(lastModified, updated_at || 0);

        return [expandedDuration, expandedEnd] as [number | undefined, Date | undefined];
      }, [undefined, undefined] as [number | undefined, Date | undefined]);

      const categoryRow: Group<ProjectTimelineRow> = {
        __id: category.id,
        data: {
          rowType: 'category' as const,
          id: category.id,
          title: category.data.name || "",
          duration: categoryDuration,
          end: categoryEnd,
          updated_at: category.data.updated_at,
          emoji: category.data.emoji,
        },
        __children: [],
      };

      projectItems.push(categoryRow);
      rowsById[category.id] = categoryRow.data;

      const [expandedDuration, expandedEnd] = expandDateRange(projectDuration, projectEnd, categoryDuration, categoryEnd);
       
      return [projectItems, expandedDuration, expandedEnd] as [Group<ProjectTimelineRow>[], number | undefined, Date | undefined];
    }, [[] as Group<ProjectTimelineRow>[], undefined, undefined] as [Group<ProjectTimelineRow>[], number | undefined, Date | undefined]);

    const projectRow: Group<ProjectTimelineRow> = {
      __id: project.id,
      data: {
        rowType: 'project' as const,
        id: project.id,
        title: project.data.name || "",
        duration: projectDuration,
        end: projectEnd,
        updated_at: project.data.updated_at,
      },
      __children: projectItems,
    };

    items.push(projectRow);
    rowsById[project.id] = projectRow.data;

    return [items, rowsById, ancestorsById, lastModified] as [Group<ProjectTimelineRow>[], Record<string, ProjectTimelineRow>, Record<string, string[]>, number];
  }, [[], {}, {}, 0] as [Group<ProjectTimelineRow>[], Record<string, ProjectTimelineRow>, Record<string, string[]>, number]);

}



export const useProjectTimeline = (model: ProjectTimelineModel) => {

  const [items, rowsById, ancestorsById, lastModified] = React.useMemo(() => {
    return constructTimelineTree(model);
  }, [model.projects, model.categoriesByProjectId, model.tasksByCategoryId]);

  return {
    items,
    rowsById,
    ancestorsById,
    lastModified,
  };

}