"use client";

import { getProfileName, TaskStatus } from "@palette.tools/model";
import { Button, CallbackWithDeps, DatetimePopover, DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuSub, DropdownMenuSubContent, DropdownMenuSubTrigger, DropdownMenuTrigger, Select, SelectContent, SelectGroup, SelectItem, SelectLabel, SelectTrigger, SelectValue, Tooltip, TooltipContent, TooltipProvider, TooltipTrigger, UserPopover } from "@palette.tools/react";
import {
    ColumnDef,
    ColumnFiltersState,
    Row,
    SortingState,
    VisibilityState,
    getCoreRowModel,
    getFilteredRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table";
import React, { forwardRef, useEffect, useImperativeHandle, useMemo } from "react";
import ImageFallback from "../image/ImageFallback";
import { StatusDropdownMenu } from "../menus";
import EditableTable, { AllowInsertRowsFn } from "./EditableTable";
import { TableHeaderCell } from "./shared";
import { Asset, Category, Phase, Project, Task, UserProfile, Workspace, getPermissions, transact, useAuth } from "@palette.tools/model.client";
import { Avatar } from "../image";
import { GripVerticalIcon, MoreHorizontalIcon, TrashIcon, UserIcon } from "lucide-react";
import { cn } from "@/lib/utils";
import { Emoji } from "emoji-picker-react";
import { AllowInsertExternalFn, Group, Item, SelectionManager } from "@palette.tools/react.dogs";

interface TaskRow {
  id: string,
  name: string,
  category: Category | null,
  assignee: string | undefined,
  deadline: number | null | undefined,
  status: TaskStatus | undefined,
  phase: Phase | null,
  editable: boolean,
}

function convertTaskToRow(task: Task, editable: boolean, phase: Phase | null, category: Category | null): TaskRow {

  return {
    id: task.id,
    name: task.data.name || "",
    category,
    assignee: task.roles.assignee[0],
    deadline: task.data.deadline,
    status: task.data.status,
    phase,
    editable,
  }
}

async function convertTasksToRows(
  tasks?: Task[],
  profile?: UserProfile,
  phaseByTask?: Record<string, Phase | null>,
  categoryByTask?: Record<string, Category | null>,
): Promise<TaskRow[]> {
  if (!tasks) return [];
  return tasks.map(task => {
    const phase = phaseByTask?.[task.id];
    const permissionsProps = {
      profile,
      phase,
      task: task,
    };
    return convertTaskToRow(task, getPermissions(permissionsProps).canEditTask, phase || null, categoryByTask?.[task.id] || null);
  });
}


function convertRowToTask(tasks: Task[] | undefined, row: Row<TaskRow>) {
  return (tasks || []).find(x => x.id === row.original.id);
}


const CategoryCell: React.FC<{category: Category | null, onClick?: () => void}> = ({ category, onClick }) => {
  return <div className="min-w-[150px] max-w-[300px] flex flex-row gap-x-2">
    <Emoji unified={category?.data.emoji || ""} size={16} />
    <p className="max-w-full line-clamp-2 break-all text-xs">
      <span
        dogs-selection-enabled={onClick ? "false" : "true"}
        dogs-dragging-enabled={onClick ? "false" : "true"}
        data-clickable={onClick ? "true" : "false"}
        onClick={e => { if (!onClick) return; e.stopPropagation(); e.preventDefault(); onClick?.() }}
        className="data-[clickable=true]:cursor-pointer"
      >
        {category?.data.name}
      </span>
    </p>
  </div>
}


const AssetCell: React.FC<{asset?: Asset, onClick?: () => void}> = ({ asset, onClick }) => {
  return <div className="min-w-[150px] max-w-[300px] flex flex-row gap-x-2">
    <ImageFallback
      fallback="none"
      src={asset?.data.thumbnail_url}
      width={30}
      height={20}
      className="rounded-md border-gray-300 bg-gray-500"
      alt={`Thumbnail for ${asset?.data.name || "unknown item"}`}
    />
    <p className="max-w-full line-clamp-2 break-all text-xs">
      <span
        dogs-selection-enabled={onClick ? "false" : "true"}
        dogs-dragging-enabled={onClick ? "false" : "true"}
        data-clickable={onClick ? "true" : "false"}
        onClick={e => { if (!onClick) return; e.stopPropagation(); e.preventDefault(); onClick?.() }}
        className="data-[clickable=true]:cursor-pointer"
      >
        {asset?.data.name}
      </span>
    </p>
  </div>
}


const NameCell: React.FC<{name?: string, onClick?: () => void}> = ({name, onClick}) => {
  return <div className="min-w-[150px] max-w-[300px]">
    <p className="max-w-full line-clamp-2 break-all text-xs">
      <span
        dogs-selection-enabled={onClick ? "false" : "true"}
        dogs-dragging-enabled={onClick ? "false" : "true"}
        data-clickable={onClick ? "true" : "false"}
        onClick={e => { if (!onClick) return; e.stopPropagation(); e.preventDefault(); onClick?.() }}
        className="data-[clickable=true]:cursor-pointer"
      >
        {name}
      </span>
    </p>
  </div>
}

function AssigneeCell(props: {
  workspace: Workspace | null,
  project: Project | null,
  profiles: UserProfile[],
  task?: Task,
  editable?: boolean,
}) {

  const { profiles, task, editable } = props;
  const assignee = profiles?.find(x => task?.is_role("assignee", x.id));

  return <div className="flex flex-col content-center items-center float-left ml-4">
    {assignee
      ? <Avatar user={assignee} size={30} />
      : <UserIcon className="w-[15px] h-[15px]" />
    }
  </div>

}


const StatusCell: React.FC<{
  status?: number,
  editable?: boolean,
  onSelectStatus?: (status: number) => void,
}> = ({
  status,
  editable,
  onSelectStatus,
}) => {

  return <div className="flex flex-col content-center items-center float-left">
    <div onClick={(e) => {
      if (!editable) return;
      e.preventDefault(); e.stopPropagation();
    }}>
      <StatusDropdownMenu
        className="h-[30px] text-xs"
        editable={editable}
        selectedStatus={status}
        onSelectStatus={onSelectStatus}
      />
    </div>
  </div>
}


export const PhaseTasksTable = React.forwardRef<HTMLDivElement, {
  editable?: boolean,
  profiles: UserProfile[],
  workspace: Workspace | null,
  project: Project | null,
  tasks: Task[];
  phases: Phase[];
  categoryByTask: Record<string, Category | null>;
  assetByTask: Record<string, Asset>;
  phaseByTask: Record<string, Phase | null>;
  profilesByPhase: Record<string, UserProfile[]>;
  className?: string;
  selectionManagerRef?: React.Ref<SelectionManager>;
  externalDataKey?: string;
  selectionMode?: "single" | "multi" | "none",
  allowInsert?: CallbackWithDeps<AllowInsertRowsFn<TaskRow>>,
  allowInsertExternal?: (CallbackWithDeps<AllowInsertExternalFn>) | string,
  insertItems?: (insertedRows: Row<TaskRow>[], index: number, parentRow: Row<TaskRow> | null) => void,
  insertExternalItems?: (insertedRows: (Item<any> | Group<any>)[], index: number, parentRow: Row<TaskRow> | null, key: string) => void,
  onSelectTasks?: (selectedTasks: Task[]) => void;
  onSelectPhase?: (task: Task, phase: Phase | null) => void;
  getCanEditPhase?: (phase?: Phase | null) => boolean,
  onMoveTasksToUser?: (tasks: Task[], phase: Phase, user: UserProfile | null) => Promise<void>,
  onClickCategory?: (category: Category) => Promise<void>,
  onClickAsset?: (asset: Asset, task?: Task) => Promise<void>,
  onClickTask?: (task: Task) => Promise<void>,
}>(({
  editable = false,
  profiles,
  workspace,
  project,
  tasks,
  phases,
  categoryByTask,
  profilesByPhase,
  assetByTask,
  phaseByTask,
  className,
  selectionManagerRef,
  externalDataKey,
  selectionMode = "single",
  allowInsert,
  allowInsertExternal,
  insertItems,
  insertExternalItems,
  onSelectTasks,
  onSelectPhase,
  getCanEditPhase,
  onMoveTasksToUser,
  onClickCategory,
  onClickAsset,
  onClickTask,
}, ref) => {

  const { profile } = useAuth();

  const tasksById = useMemo(() => {
    return tasks.reduce((acc, task) => {
      acc[task.id] = task;
      return acc;
    }, {} as Record<string, Task>);
  }, [tasks]);

  // Convert user profiles to row model.
  const [isConverting, setConverting] = React.useState(true);
  const [localData, setLocalData] = React.useState<TaskRow[]>([]);

  // Table states
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([]);
  const [columnVisibility, setColumnVisibility] = React.useState<VisibilityState>({});

  const openMoreOptionsRef = React.useRef<string | null>(null);
  const selectedRowIdsRef = React.useRef<string[]>([]);

  const onClickCategoryRef = React.useRef<typeof onClickCategory>(onClickCategory);
  onClickCategoryRef.current = onClickCategory;
  const onClickAssetRef = React.useRef<typeof onClickAsset>(onClickAsset);
  onClickAssetRef.current = onClickAsset;
  const onClickTaskRef = React.useRef<typeof onClickTask>(onClickTask);
  onClickTaskRef.current = onClickTask;

  const columns: ColumnDef<TaskRow>[] = [

    {
      accessorKey: "id",
      header: () => { return <></> },
      cell: () => editable ? <div className="opacity-0 group-hover:opacity-100 w-[16px] h-[16px]">
        <GripVerticalIcon className="w-[16px] h-[16px]" />
      </div> : undefined,
    },

    {
      accessorKey: "category",
      header: ({ column }) => <TableHeaderCell column={column} text="Category" className="text-xs h-[30px]" />,
      cell: ({ row }) => <CategoryCell
        category={categoryByTask?.[row.original.id] || null}
        onClick={onClickCategoryRef.current ? () => !!categoryByTask?.[row.original.id] && onClickCategoryRef.current?.(categoryByTask?.[row.original.id]!) : undefined}
      />,
      sortingFn: (a, b) => {
        const categoryA = categoryByTask?.[a.original.id];
        const categoryB = categoryByTask?.[b.original.id];
        return (categoryA?.data.name || "").localeCompare(categoryB?.data.name || "");
      }
    },

    {
      accessorKey: "item",
      header: ({ column }) => <TableHeaderCell column={column} text="Item" className="text-xs h-[30px]" />,
      cell: ({ row }) => <AssetCell
        asset={assetByTask[row.original.id]}
        onClick={onClickAssetRef.current ? () => onClickAssetRef.current?.(assetByTask[row.original.id] || null, tasksById[row.original.id]) : undefined}
      />,
      sortingFn: (a, b) => {
        const assetA = assetByTask[a.original.id];
        const assetB = assetByTask[b.original.id];
        return (assetA?.data.name || "").localeCompare(assetB?.data.name || "");
      }
    },

    {
      accessorKey: "name",
      header: ({ column }) => <TableHeaderCell column={column} text="Task" className="text-xs h-[30px]"/>,
      cell: ({ row }) => <NameCell
        name={row.original.name}
        onClick={onClickTaskRef.current ? () => onClickTaskRef.current?.(tasksById[row.original.id]) : undefined}
      />,
    },
    
    {
      accessorKey: "assignee",
      header: ({ column }) => <TableHeaderCell column={column} text="Assignee" className="text-xs h-[30px]"/>,
      cell: ({ row }) => <AssigneeCell
        workspace={workspace}
        project={project}
        profiles={profiles}
        task={convertRowToTask(tasks, row)}
        editable={row.original.editable}
      />,
    },

    {
      accessorKey: "status",
      header: ({ column }) => <TableHeaderCell column={column} text="Status" className="text-xs h-[30px]"/>,
      cell: ({ row }) => <StatusCell
        status={row.original.status}
        editable={row.original.editable}
        onSelectStatus={(status) => {
          const task = convertRowToTask(tasks, row);
          if (!task || task.data.status === status) return;
          transact(task.update({ status }));
        }}
      />
    },

    {
      accessorKey: "more",
      header: () => <></>,
      cell: ({ row }) => {

        const task = convertRowToTask(tasks, row)
        const [_, dispatch] = React.useReducer((x: number) => x + 1, 0);

        if (!editable) return null;

        const moreOptions =
          <DropdownMenuContent
            onCloseAutoFocus={e => e.preventDefault()}
            onClick={e => e.stopPropagation()}
          >
            <DropdownMenuSub>
              <DropdownMenuSubTrigger>
                <span className="text-xs font-medium">Move To</span>
              </DropdownMenuSubTrigger>
              <DropdownMenuSubContent>
                {phases?.map(phase => (
                  <DropdownMenuSub key={phase.id}>
                    <DropdownMenuSubTrigger className="text-xs font-medium">{phase.data.name}</DropdownMenuSubTrigger>
                    <DropdownMenuSubContent>
                      {profilesByPhase?.[phase.id]?.map(profile => (
                        <DropdownMenuItem
                          key={profile.id}
                          className="flex flex-row gap-x-2 text-xs font-medium"
                          onClick={e => {
                            if (!getCanEditPhase?.(phase)) return;
                            if (!selectedRowIdsRef.current.some(x => x === task?.id)) {
                              if (task) {
                                onMoveTasksToUser?.([task], phase, profile);
                              }
                            } else {
                              onMoveTasksToUser?.(selectedRowIdsRef.current.map(id => tasksById[id]).filter(Boolean) as Task[], phase, profile);
                            }
                          }}
                        >
                          <Avatar 
                            src={profile.data.image_url}
                            size={15}
                            className="rounded-full"
                          />
                          <span dogs-selection-enabled="false">{getProfileName(profile)}</span>
                        </DropdownMenuItem>
                      ))}
                      <DropdownMenuItem
                        key="unassigned"
                        className="flex flex-row gap-x-2 text-xs font-medium"
                        onClick={e => {
                          if (!getCanEditPhase?.(phase)) return;
                          if (!selectedRowIdsRef.current.some(x => x === task?.id)) {
                            if (task) {
                              onMoveTasksToUser?.([task], phase, null);
                            }
                          } else {
                            onMoveTasksToUser?.(selectedRowIdsRef.current.map(id => tasksById[id]).filter(Boolean) as Task[], phase, null);
                          }
                        }}
                      >
                        <Avatar 
                          src={""}
                          size={15}
                          className="rounded-full"
                        />
                        <span>Unassigned</span>
                      </DropdownMenuItem>
                    </DropdownMenuSubContent>
                  </DropdownMenuSub>
                ))}
              </DropdownMenuSubContent>
            </DropdownMenuSub>
          </DropdownMenuContent>;

        const content = 
          <DropdownMenu
            open={openMoreOptionsRef.current === row.id}
            onOpenChange={open => {
              openMoreOptionsRef.current = open ? row.id : null;
              dispatch();
            }}
          >
            <DropdownMenuTrigger
              dogs-selection-enabled="false"
              dogs-dragging-enabled="false"
              onClick={(e) => {e.stopPropagation(); e.preventDefault()}}
              className="opacity-0 group-hover:opacity-100 w-[16px] h-[16px]">
              <MoreHorizontalIcon className="w-[16px] h-[16px]" />
            </DropdownMenuTrigger>
            {moreOptions}
          </DropdownMenu>

        return content;

      },
    },
    
  ];

  useEffect(() => {
    if (!tasks) setLocalData([]);
    setConverting(true);
    async function _convertTasksToRows() {
      const rows = await convertTasksToRows(tasks, profile, phaseByTask, categoryByTask);
      setLocalData(rows);
    }
    _convertTasksToRows().finally(() => setConverting(false));
  }, [profile, tasks, phaseByTask, categoryByTask]);

  const table = useReactTable({
    data: localData,
    columns,
    enableSortingRemoval: true,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
    },
  })

  return <EditableTable
    containerClassName={cn("border-t-0", className)}
    rowClassName="group h-min"
    cellClassName="px-2 py-[2px] align-middle"
    enableOrdering={editable}
    selectionMode={selectionMode}
    table={table}
    getUuid={(row) => row.original.id}
    externalDataKey={externalDataKey}
    selectionManagerRef={selectionManagerRef}
    onSelectRowIds={(rowIds) => {
      const selectedTasks = rowIds.map(id => tasksById[id]).filter(Boolean) as Task[];
      selectedRowIdsRef.current = [...rowIds];
      onSelectTasks && onSelectTasks(selectedTasks);
    }}
    allowInsert={[() => false, []]}
    allowInsertWhileSorting={true}
    allowInsertExternal={allowInsertExternal}
    insertItems={insertItems}
    insertExternalItems={insertExternalItems}
    rowClassNameOverride={() => cn("border-transparent", editable ? "cursor-grab" : "")}
    containerClassNameOverride={(props) => cn(
      props.isDroppingWithinDescendant ? "border-[1px] border-primary" : "border-[1px] border-transparent",
    )}
    tableHeaderClassNameOverride={(props) => cn(
      "after:content-[''] after:absolute after:left-0 after:right-0 after:bottom-0 after:h-[1px] after:bg-border",
    )}
    rowDependencies={(row) => [
      openMoreOptionsRef.current === row.original.id,
    ]}
  />

});
