"use client";

import { formatStorage } from "@palette.tools/model";
import { Project, ProjectGroup, Workspace, getPermissions, useAuth } from "@palette.tools/model.client";
import { LogOutIcon, MoreHorizontalIcon, PlusIcon, SettingsIcon, Trash2Icon, UsersIcon } from "lucide-react";
import React from "react";
import ImageFallback from "../image/ImageFallback";
import { Button } from "../shadcn/components/ui/button";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuSeparator, DropdownMenuTrigger } from "../shadcn/components/ui/dropdown-menu";
import { Progress } from "../shadcn/components/ui/progress";
import { Separator } from "../shadcn/components/ui/separator";
import { TypographyH3, TypographyXSmall } from "../typography";
import { WorkspaceProjectCardList, WorkspaceProjectCardListCallbacks } from "./WorkspaceProjectCardList";
import { Override } from "@palette.tools/utils";
import { SplitButtonAction, SplitButton } from "../buttons";
import { ListInsertPosition } from "@palette.tools/model.core";


const WorkspaceHeader: React.FC<{
  workspace: Workspace,
  callbacks: {
    onClickWorkspace?: (workspace: Workspace) => void,
    onClickWorkspaceHomePage?: (workspace: Workspace) => void,
    onClickWorkspaceTeamPage?: (workspace: Workspace) => void,
    onClickCreateProject?: (workspace: Workspace) => void,
    onClickWorkspaceSettings?: (workspace: Workspace) => void,
    onClickDeleteWorkspace?: (workspace: Workspace) => void,
    onClickLeaveWorkspace?: (workspace: Workspace) => void,
    onClickCreateProjectGroup?: (workspace: Workspace) => void,
  },
}> = ({
  workspace,
  callbacks,
}) => {
  const { profile } = useAuth();
  const storageSize = workspace.data.storage_size || 0;
  const storageLimit = workspace.data.storage_limit || 0;
  const storagePercentage = storageLimit ? Math.round(storageSize / storageLimit * 100) : 0;
  const { canCreateProject, canEditWorkspace } = getPermissions({ profile, workspace });

  const getCreationButton = (() => {

    let actions: SplitButtonAction[] = [];

    if (canCreateProject) {
      actions.push({
        isDefault: true,
        label: "New Project",
        onClick: () => callbacks.onClickCreateProject?.(workspace),
      });
    }

    if (canEditWorkspace) {
      actions.push({
        label: "New Group",
        onClick: () => callbacks.onClickCreateProjectGroup?.(workspace),
      });
    }

    if (canCreateProject || canEditWorkspace) {
     return <SplitButton
        actions={actions}
        onClickMainButton={() => callbacks.onClickCreateProject?.(workspace)}
        variant="cta"
      >New</SplitButton>;
    }

    return <></>;

  });

  const getWorkspaceDropdownMenuContent = (workspace: Workspace) => {
    let items: React.ReactNode[] = [];
    if (!profile) return items;

    const { canEditWorkspace, canDeleteWorkspace } = getPermissions({ profile, workspace });

    items.push(<DropdownMenuItem key="team" onSelect={() => callbacks.onClickWorkspaceTeamPage?.(workspace)}>
      <UsersIcon width={16} height={16} />&nbsp;&nbsp;Team
    </DropdownMenuItem>)

    if (canEditWorkspace) {
      items.push(<DropdownMenuItem
        key="settings"
        onSelect={() => callbacks.onClickWorkspaceSettings?.(workspace)}>
        <SettingsIcon width={16} height={16} />&nbsp;&nbsp;Settings
      </DropdownMenuItem>)
      items.push(<DropdownMenuSeparator key="separator1" />)
    }

    if ((workspace.links.profile || []).find(x => x.id == profile.id)) {
      items.push(<DropdownMenuItem
        key="leave"
        onClick={() => callbacks.onClickLeaveWorkspace?.(workspace)}>
        <LogOutIcon width={16} height={16} className="stroke-destructive"/>&nbsp;&nbsp;<span className="text-destructive">Leave</span>
      </DropdownMenuItem>)
    }

    if (canDeleteWorkspace) {
      items.push(<DropdownMenuItem
        key="delete"
        onSelect={() => callbacks.onClickDeleteWorkspace?.(workspace)}>
        <Trash2Icon width={16} height={16} className="stroke-destructive"/>&nbsp;&nbsp;<span className="text-destructive">Delete</span>
      </DropdownMenuItem>)
    }

    return items;
  }

  const workspaceDropdownMenuContent = getWorkspaceDropdownMenuContent(workspace);

  return (
    <div className="flex flex-row items-center">
      <ImageFallback
        src={workspace.data.thumbnail_url}
        className="rounded-full cursor-pointer"
        height="30"
        width="30"
        alt={`Thumbnail for ${workspace.data.name}`}
        onClick={() => callbacks.onClickWorkspaceHomePage?.(workspace)}
      />
      <div className="min-w-[18px]" />
      <TypographyH3
        onClick={() => callbacks.onClickWorkspaceHomePage?.(workspace)}
        className="text-muted-foreground cursor-pointer hover:text-foreground"
      >{workspace.data.name}</TypographyH3>
      <div className="min-w-[18px]" />
      <Progress value={storagePercentage} className="max-w-[100px]" />
      <div className="min-w-[8px]" />
      <TypographyXSmall>{`${formatStorage(storageSize)} / ${formatStorage(storageLimit)} used`}</TypographyXSmall>
      <div className="flex-1" />
      {getCreationButton()}

      {workspaceDropdownMenuContent.length > 0 && (
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="ghost" size="icon"><MoreHorizontalIcon /></Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            {workspaceDropdownMenuContent}
          </DropdownMenuContent>
        </DropdownMenu>
      )}
    </div>
  );
};


export const AllWorkspaceProjectCardLists: React.FC<{
  workspaces: Workspace[],
  callbacks: Override<WorkspaceProjectCardListCallbacks, {
    onClickProject?: (workspace: Workspace, project: Project) => void,
    onClickProjectSettings?: (workspace: Workspace, project: Project) => void,
    onClickDeleteProject?: (workspace: Workspace, project: Project) => void,
    onClickWorkspace?: (workspace: Workspace) => void,
    onClickWorkspaceHomePage?: (workspace: Workspace) => void,
    onClickWorkspaceTeamPage?: (workspace: Workspace) => void,
    onClickCreateProject?: (workspace: Workspace) => void,
    onClickWorkspaceSettings?: (workspace: Workspace) => void,
    onClickDeleteWorkspace?: (workspace: Workspace) => void,
    onClickLeaveWorkspace?: (workspace: Workspace) => void,
    onClickLeaveProject?: (workspace: Workspace, project: Project) => void,
    onClickProjectGroup?: (workspace: Workspace, group: ProjectGroup) => void,
    onClickCreateProjectGroup?: (workspace: Workspace, project?: Project) => void,
    onClickUngroupProject?: (workspace: Workspace, project: Project, group: ProjectGroup) => void,
    onClickDeleteProjectGroup?: (workspace: Workspace, group: ProjectGroup) => void,
    onClickRenameProjectGroup?: (workspace: Workspace, group: ProjectGroup) => void,
    onMoveProjectsAndGroupsInWorkspace?: (workspace: Workspace, items: (Project | ProjectGroup)[], position: ListInsertPosition<Project | ProjectGroup>) => void,
    onMoveProjectsInGroup?: (workspace: Workspace, group: ProjectGroup, items: Project[], position: ListInsertPosition<Project>) => void,
  }>,
}> = ({
  workspaces,
  callbacks,
}) => {
  return (
    <div className="flex flex-col w-full gap-y-[20px]">
      {workspaces.sort((a, b) => (a.data.name || "").localeCompare(b.data.name || "")).map(workspace => (
        <div key={workspace.id} className="flex flex-col">
          <WorkspaceHeader
            workspace={workspace}
            callbacks={callbacks}
          />
          <div className="min-h-[8px]" />
          <Separator />
          <div className="min-h-[20px]" />
          <WorkspaceProjectCardList
            model={{
              workspace,
              projects: workspace.links.project || [],
              groups: workspace.links.group__project || [],
            }}
            callbacks={{
              ...callbacks,
              onClickProject: (project) => callbacks.onClickProject?.(workspace, project),
              onClickProjectSettings: (project) => callbacks.onClickProjectSettings?.(workspace, project),
              onClickDeleteProject: (project) => callbacks.onClickDeleteProject?.(workspace, project),
              onClickLeaveProject: (project) => callbacks.onClickLeaveProject?.(workspace, project),
              onClickProjectGroup: (group) => callbacks.onClickProjectGroup?.(workspace, group),
              onClickCreateProjectGroup: (project) => callbacks.onClickCreateProjectGroup?.(workspace, project),
              onClickUngroupProject: (project, group) => callbacks.onClickUngroupProject?.(workspace, project, group),
              onClickDeleteProjectGroup: (group) => callbacks.onClickDeleteProjectGroup?.(workspace, group),
              onClickRenameProjectGroup: (group) => callbacks.onClickRenameProjectGroup?.(workspace, group),
              onMoveProjectsAndGroupsInWorkspace: (workspace, items, position) => callbacks.onMoveProjectsAndGroupsInWorkspace?.(workspace, items, position),
              onMoveProjectsInGroup: (group, items, position) => callbacks.onMoveProjectsInGroup?.(workspace, group, items, position),
            }}
          />
        </div>
      ))}
    </div>
  );
};