"use client";

import React, { useEffect } from "react";

import {
  ColumnDef,
  ColumnFiltersState, SortingState,
  VisibilityState,
  getCoreRowModel,
  getFilteredRowModel, getSortedRowModel,
  useReactTable
} from "@tanstack/react-table";

import { ArrowUpDown, LogOutIcon, MoreHorizontalIcon, Trash2Icon } from "lucide-react";

import {
  Button, DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger, Tooltip, TooltipContent, TooltipProvider, TooltipTrigger
} from "@palette.tools/react";

import { remove_workspace_user } from "@palette.tools/api.client";
import { WorkspaceRoles, getProfileName } from "@palette.tools/model";
import { UserProfile, Workspace, getWorkspaceRole, transact, useAuth, usePermissions } from "@palette.tools/model.client";
import { Avatar } from "../image";
import { WorkspaceRolesDropdownMenu } from "../menus";
import EditableTable from "./EditableTable";


interface UserProfileRow {
  id: string,
  name: string,
  email: string,
  role?: number,
}

function convertUserProfileToRow(workspace: Workspace, profile: UserProfile): UserProfileRow {
  return {
    id: profile.id,
    name: getProfileName(profile),
    email: profile.data.email || "",
    role: getWorkspaceRole(workspace, profile),
  }
}

function convertUserProfilesToRows(workspace?: Workspace, profiles?: UserProfile[]): UserProfileRow[] {
  let rows: UserProfileRow[] = [];
  if (!workspace || !profiles) {
    return rows;
  }
  for (let profile of profiles) {
    rows.push(convertUserProfileToRow(workspace, profile));
  }
  return rows;
}



export const WorkspaceUsersTable: React.FC<{
  profiles: UserProfile[],
  workspace: Workspace,
}> = ({
  profiles,
  workspace,
}) => {

  // Convert user profiles to row model.
  const [localData, setLocalData] = React.useState<UserProfileRow[]>(convertUserProfilesToRows(workspace, profiles));
  useEffect(() => {
    if (!profiles) return;
    setLocalData(convertUserProfilesToRows(workspace, profiles));
  }, [workspace, profiles]);

  const { profile } = useAuth();
  const { canEditWorkspace } = usePermissions({ workspace });

  const [sorting, setSorting] = React.useState<SortingState>([])
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  )
  const [columnVisibility, setColumnVisibility] =
    React.useState<VisibilityState>({})

  // Get project dropdown menu
  const getRowDropdownMenu = (
    profileToEdit?: UserProfile | null,
  ) => {

    let items: React.ReactNode[] = [];

    if (!profile || !profileToEdit || !workspace) return items;

    if (profile.id === profileToEdit.id) {
      if (workspace.is_role("admin", profile.id) && (workspace.roles.admin.length < 2)) {
        items.push(
          <TooltipProvider>
            <Tooltip delayDuration={0}>
              <TooltipTrigger>
                <DropdownMenuItem
                  disabled
                  key="leave"
                  onClick={() => {}}>
                  <LogOutIcon width={16} height={16} className="stroke-muted-foreground"/>&nbsp;&nbsp;<span className="text-muted-foreground">Leave</span>
                </DropdownMenuItem>
              </TooltipTrigger>
              <TooltipContent className="text-destructive">
                WARNING: You are the only admin! Choose another admin before leaving, or delete the workspace if you're done with it.
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        )
      }
      else {
        items.push(<DropdownMenuItem
          key="leave"
          onClick={() => remove_workspace_user(workspace.id, profileToEdit.id).then(x => {
            if (x) {
              window.location.replace("/home");
            }
          })}>
          <LogOutIcon width={16} height={16} className="stroke-destructive"/>&nbsp;&nbsp;<span className="text-destructive">Leave</span>
        </DropdownMenuItem>)
      }
    }
    else if (canEditWorkspace) {
      items.push(<DropdownMenuItem
        key="remove"
        onClick={() => remove_workspace_user(workspace.id, profileToEdit.id)}
      >
        <Trash2Icon width={16} height={16} className="stroke-destructive"/>&nbsp;&nbsp;<span className="text-destructive">Remove</span>
      </DropdownMenuItem>)
    }

    return items;
  }

  const columns: ColumnDef<UserProfileRow>[] = [

    {
      accessorKey: "name",
      header: ({ column }) => {
        return (
          <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
            User&nbsp;<ArrowUpDown className="ml-2 h-4 w-4" />
          </Button>
        )
      },
      cell: ({ row }) => (
        <div className="flex flex-row gap-x-2 items-center"><Avatar size={30} user={profiles.find(x => x.id === row.original.id)}/>{row.getValue("name")}</div>
      ),
    },

    {
      accessorKey: "email",
      header: ({ column }) => {
        return (
          <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
            Email&nbsp;<ArrowUpDown className="ml-2 h-4 w-4" />
          </Button>
        )
      },
      cell: ({ row }) => <div>{row.getValue("email")}</div>,
    },

    {
      accessorKey: "role",
      header: ({ column }) => {
        return (
          <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
            Role&nbsp;<ArrowUpDown className="ml-2 h-4 w-4" />
          </Button>
        )
      },
      cell: ({ row }) => {
        const profileToEdit = profiles?.find(x => x.id === row.original.id);
        if (!profileToEdit) return;

        const currentRole = getWorkspaceRole(workspace, profileToEdit);

        return <WorkspaceRolesDropdownMenu
          editable={canEditWorkspace}
          selectedRole={currentRole}
          onSelectRole={newRole => {
            if (workspace && profileToEdit && currentRole !== newRole) {
              if (newRole === WorkspaceRoles.admin) {
                transact(
                  workspace.assign_role("admin", profileToEdit.id),
                )
              }
              else {
                transact(
                  workspace.unassign_role("admin", profileToEdit.id),
                )
              }
            }
          }}

        />
      },
    },

    {
      accessorKey: "more",
      header: ({ column }) => <></>,
      cell: ({ row }) => {
        const profileToEdit = profiles?.find(x => x.id === row.original.id);

        const actionButton = <Button
          onClick={(e) => {e.stopPropagation(); e.preventDefault()}}
          variant="outline"
          size="icon"
          className={"relative inset-0 opacity-50 group-hover:opacity-100 w-[20px] h-[20px]"}>
          <MoreHorizontalIcon />
        </Button>

        const dropdownMenuContent = (canEditWorkspace || (profile && profile.id === profileToEdit?.id)) ? getRowDropdownMenu(profileToEdit) : undefined;

        if (dropdownMenuContent && dropdownMenuContent.length > 0) {
          return <DropdownMenu>
            <DropdownMenuTrigger asChild>{actionButton}</DropdownMenuTrigger>
            <DropdownMenuContent>
              {dropdownMenuContent}
            </DropdownMenuContent>
          </DropdownMenu>
        }

        return undefined;

      },
    },

  ]

  const table = useReactTable({
    data: localData,
    columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
    },
  })


  return <EditableTable
    containerClassName="max-w-[920px] min-w-[770px] flex-1 min-h-0 h-full"
    cellClassName="py-4 px-2 align-middle"
    table={table}
    getUuid={(row) => row.original.id}
  />

}
