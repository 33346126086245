import React, { useEffect, useRef, useState } from "react";
import { Popover, PopoverContent, PopoverTrigger } from "../shadcn/components/ui/popover";
import { UserSelector } from "../editors";
import { UserProfile } from "@palette.tools/model.client";


export const UserPopover: React.FC<{
  className?: string,
  modal?: boolean,
  notAsChild?: boolean,
  disable?: boolean,
  children: React.ReactNode,
  selectableUsers?: UserProfile[],
  selectedUser?: UserProfile,
  onSelectUser?: (user: UserProfile) => void,
  onClearSelection?: () => void,
  onClick?: (e: React.MouseEvent) => void,
} & React.ComponentProps<typeof PopoverTrigger>> = ({
  className,
  modal = false,
  notAsChild = false,
  disable = false,
  children,
  selectableUsers,
  onSelectUser,
  onClearSelection,
  onClick,
  ...triggerProps
}) => {

  const [open, setOpen] = useState(false);
  const props: Parameters<typeof Popover>[0] = modal ? { modal, open, onOpenChange: () => {} } : {};

  const inputRef = useRef<HTMLInputElement>(null);
  const triggerRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  if (disable) {
    return <>{children}</>;
  }

  return (
    <Popover {...props}>
      <PopoverTrigger
        className={className}
        asChild={!notAsChild}
        ref={triggerRef}
        onClick={e => { if (!modal) return; e.preventDefault(); e.stopPropagation(); onClick?.(e); setOpen(prev => !prev) }}
        {...triggerProps}
      >{children}</PopoverTrigger>
      <PopoverContent
        onPointerDownOutside={e => { if (modal && e.target instanceof Node && !triggerRef.current?.contains(e.target)) setOpen(false) }}
      >
        <UserSelector
          selectableUsers={selectableUsers || []}
          onSelectUser={user => {
            onSelectUser?.(user);
            if (modal) setOpen(false);
          }}
          onClearSelection={onClearSelection ? () => {
            onClearSelection();
            if (modal) setOpen(false);
          } : undefined}
        />
      </PopoverContent>
    </Popover>
  );
};
