"use client";

import React from "react";
import { Button } from "../shadcn/components/ui/button";
import {
  Column,
  SortDirection,
} from "@tanstack/react-table"
import { ArrowDownIcon, ArrowUpIcon } from "lucide-react";
import { cn } from "@/lib/utils";


export const TableHeaderCell: React.FC<{
  className?: string,
  column: Column<any, any>,
  text: string,
}> = ({
  className,
  column,
  text,
}) => {
  const sortDirection = column.getIsSorted();
  return <Button className={cn("p-0 hover:bg-transparent", className)} variant="ghost" onClick={column.getToggleSortingHandler()} disabled={!column.getCanSort()}>
  {text}&nbsp;{
    sortDirection ? 
      sortDirection === "asc"
        ? <ArrowUpIcon className="ml-1 h-4 w-4 stroke-primary" />
        : <ArrowDownIcon className="ml-1 h-4 w-4 stroke-primary" />
      : column.getCanSort() ? <div className="ml-1 h-4 w-4" /> : <></>
  }
  </Button>
}