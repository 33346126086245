import React, { useRef, useEffect, useState, useCallback, useLayoutEffect, forwardRef, useMemo } from 'react';
import { format, addDays, startOfWeek, startOfMonth, differenceInDays, addMonths, isSameMonth, isToday, subMonths, isSameDay, subDays, isValid, startOfDay } from 'date-fns';
import { cn } from '../shadcn/lib/utils';
import { AnchoredTooltipProvider, mergeRefs, useTooltipAnchor } from '../utils';
import { ZoomIn, ZoomOut } from 'lucide-react';
import * as ScrollArea from '@radix-ui/react-scroll-area';

type ZoomLevelConfig = {
  key: string;
  dayWidth: number;
  showEveryDay: boolean;
  showDayName: boolean;
};

const TimelineContext = React.createContext<{
  visibleStartDate: Date;
  visibleEndDate: Date;
  getLeftPosition: (date: Date) => number;
  dayWidth: number;
  disabled: boolean;
  today: Date;
  headerRef: React.RefObject<HTMLDivElement>;
  sidebarRef: React.RefObject<HTMLDivElement>;
  contentRef: (node: HTMLDivElement | null) => void;
  contentElement: HTMLDivElement | null;
  updateScroll: (newScrollLeft?: number, newScrollTop?: number) => void;
  scrollToDate: (date?: Date) => void;
  sidebarWidth: number;
  setSidebarWidth: React.Dispatch<React.SetStateAction<number>>;
  setDayWidth: (width: number) => void;
  zoomLevels: ZoomLevelConfig[];
  currentZoomLevel: ZoomLevelConfig;
  canZoomIn: boolean;
  canZoomOut: boolean;
  zoomIn: () => void;
  zoomOut: () => void;
} | null>(null);

export const TimelineRoot = forwardRef<HTMLDivElement, {
  className?: string;
  disabled?: boolean;
  today?: Date;
  children: React.ReactNode;
  initialDate?: Date;
  zoomLevels?: ZoomLevelConfig[];
  persistence?: {
    key: string;
    storage: 'local' | 'session';
  };
}>(({ 
  className, 
  disabled = false, 
  today = new Date(), 
  children, 
  initialDate,
  persistence,
  zoomLevels = [
    { key: 'xs', dayWidth: 6, showEveryDay: false, showDayName: false },
    { key: 'sm', dayWidth: 12, showEveryDay: false, showDayName: false },
    { key: 'md', dayWidth: 24, showEveryDay: false, showDayName: true },
    { key: 'lg', dayWidth: 96, showEveryDay: true, showDayName: true },
  ],
  ...props 
}, ref) => {
  const [visibleStartDate, setVisibleStartDate] = useState(() => subMonths(today, 24));
  const [visibleEndDate, setVisibleEndDate] = useState(() => addMonths(today, 24));
  const [dayWidth, setDayWidth] = useState(() => {
    if (persistence) {
      const storage = persistence.storage === 'local' ? localStorage : sessionStorage;
      const savedKey = storage.getItem(persistence.key);
      if (savedKey) {
        const savedLevel = zoomLevels.find(level => level.key === savedKey);
        if (savedLevel) {
          return savedLevel.dayWidth;
        }
      }
    }
    return zoomLevels[0].dayWidth;
  });

  const headerRef = useRef<HTMLDivElement>(null);
  const sidebarRef = useRef<HTMLDivElement>(null);

  const [contentElement, setContentElement] = useState<HTMLDivElement | null>(null);
  const contentRef = useCallback((node: HTMLDivElement | null) => {
    setContentElement(node);
  }, []);

  const isInitialScrollCompleteRef = useRef(false);
  const lastExpandTimeRef = useRef(0);
  const expandingRef = useRef(false);
  const scrolledFirstTimeRef = useRef(false);

  const updateScroll = useCallback((newScrollLeft?: number, newScrollTop?: number) => {
    if (newScrollLeft !== undefined) {
      if (headerRef.current) headerRef.current.scrollLeft = newScrollLeft;
      if (contentElement) contentElement.scrollLeft = newScrollLeft;
    }
    if (newScrollTop !== undefined) {
      if (sidebarRef.current) sidebarRef.current.scrollTop = newScrollTop;
      if (contentElement) contentElement.scrollTop = newScrollTop;
    }
  }, [contentElement]);

  const getLeftPosition = useCallback((date: Date): number => {
    const startOfDayDate = startOfDay(date);
    const startOfDayVisible = startOfDay(visibleStartDate);
    return differenceInDays(startOfDayDate, startOfDayVisible) * dayWidth;
  }, [visibleStartDate, dayWidth]);

  const scrollToDate = useCallback((date: Date = today) => {
    const leftPosition = getLeftPosition(date);
    const oneQuarterOffset = contentElement ? contentElement.clientWidth / 4 : 0;
    const scrollPosition = leftPosition - oneQuarterOffset;
    updateScroll(scrollPosition, undefined);
  }, [getLeftPosition, today, updateScroll, contentElement]);

  useLayoutEffect(() => {
    if (
      !scrolledFirstTimeRef.current &&
      headerRef.current &&
      sidebarRef.current &&
      contentElement
    ) {
      scrollToDate(initialDate || today);
      scrolledFirstTimeRef.current = true;
    }
  }, [scrollToDate, initialDate, today, contentElement]);

  const updateDateRange = useCallback(() => {
    const weeksList: Date[] = [];
    const monthsList: Date[] = [];
    let currentWeek = startOfWeek(visibleStartDate);
    let currentMonth: Date | null = null;

    while (currentWeek <= visibleEndDate) {
      weeksList.push(currentWeek);
      
      if (!currentMonth || !isSameMonth(currentWeek, currentMonth)) {
        currentMonth = startOfMonth(currentWeek);
        monthsList.push(currentMonth);
      }
      
      currentWeek = addDays(currentWeek, 7);
    }

  }, [visibleStartDate, visibleEndDate]);

  useEffect(() => {
    updateDateRange();
    //setRows(generateSampleRows(visibleStartDate, visibleEndDate));
  }, [visibleStartDate, visibleEndDate, updateDateRange]);

  useEffect(() => {
    let retryCount = 0;
    const maxRetries = 10;

    const performInitialScroll = () => {
      if (contentElement && !isInitialScrollCompleteRef.current) {
        const todayPosition = getLeftPosition(today);
        const { clientWidth } = contentElement;
        const scrollToPosition = todayPosition - clientWidth / 4;
        
        contentElement.scrollLeft = scrollToPosition;
        if (headerRef.current) {
          headerRef.current.scrollLeft = scrollToPosition;
        }
        
        // Check if the scroll was successful
        if (contentElement.scrollLeft === scrollToPosition) {
          isInitialScrollCompleteRef.current = true;
        } else if (retryCount < maxRetries) {
          retryCount++;
          requestAnimationFrame(performInitialScroll);
        } else {
          console.error('Failed to set initial scroll position after max retries');
          isInitialScrollCompleteRef.current = true;
        }
      }
    };

    requestAnimationFrame(performInitialScroll);

  }, [getLeftPosition, today.getDate(), today.getMonth(), today.getFullYear(), contentElement]);

  const [sidebarWidth, setSidebarWidth] = useState(0);

  const currentZoomLevel = useMemo(() => 
    zoomLevels.find(level => level.dayWidth === dayWidth) || zoomLevels[0]
  , [zoomLevels, dayWidth]);

  const currentZoomIndex = zoomLevels.findIndex(level => level.dayWidth === dayWidth);
  const canZoomIn = currentZoomIndex < zoomLevels.length - 1;
  const canZoomOut = currentZoomIndex > 0;

  const zoomIn = useCallback(() => {
    if (canZoomIn && contentElement) {
      const nextLevel = zoomLevels[currentZoomIndex + 1];
      const viewportCenter = contentElement.scrollLeft + (contentElement.clientWidth / 2);
      const centerDate = addDays(visibleStartDate, viewportCenter / dayWidth);
      setDayWidth(nextLevel.dayWidth);
      const newScrollLeft = (differenceInDays(centerDate, visibleStartDate) * nextLevel.dayWidth) - (contentElement.clientWidth / 2);
      requestAnimationFrame(() => {
        updateScroll(newScrollLeft);
      });
    }
  }, [canZoomIn, currentZoomIndex, zoomLevels, contentElement, visibleStartDate, dayWidth, updateScroll]);

  const zoomOut = useCallback(() => {
    if (canZoomOut && contentElement) {
      const nextLevel = zoomLevels[currentZoomIndex - 1];
      const viewportCenter = contentElement.scrollLeft + (contentElement.clientWidth / 2);
      const centerDate = addDays(visibleStartDate, viewportCenter / dayWidth);
      setDayWidth(nextLevel.dayWidth);
      const newScrollLeft = (differenceInDays(centerDate, visibleStartDate) * nextLevel.dayWidth) - (contentElement.clientWidth / 2);
      requestAnimationFrame(() => {
        updateScroll(newScrollLeft);
      });
    }
  }, [canZoomOut, currentZoomIndex, zoomLevels, contentElement, visibleStartDate, dayWidth, updateScroll]);

  useEffect(() => {
    if (persistence) {
      const currentLevel = zoomLevels.find(level => level.dayWidth === dayWidth);
      if (currentLevel) {
        const storage = persistence.storage === 'local' ? localStorage : sessionStorage;
        storage.setItem(persistence.key, currentLevel.key);
      }
    }
  }, [dayWidth, persistence, zoomLevels]);

  const contextValue = {
    visibleStartDate,
    visibleEndDate,
    getLeftPosition,
    dayWidth,
    disabled,
    today,
    headerRef,
    sidebarRef,
    contentRef,
    contentElement,
    updateScroll,
    scrollToDate,
    sidebarWidth,
    setSidebarWidth,
    setDayWidth,
    zoomLevels,
    currentZoomLevel,
    canZoomIn,
    canZoomOut,
    zoomIn,
    zoomOut,
  };

  return (
    <TimelineContext.Provider value={contextValue}>
      <AnchoredTooltipProvider>
        <div ref={ref} className={cn("flex flex-col h-full", className)} style={{ position: 'relative' }}>
          {children}
        </div>
      </AnchoredTooltipProvider>
    </TimelineContext.Provider>
  );
});
TimelineRoot.displayName = 'TimelineRoot';

// Export these components to be used in layouts
export const TimelineHeader = forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, children, ...props }, ref) => {
    const childrenArray = React.Children.toArray(children);
    const cornerChild = childrenArray.find(child => React.isValidElement(child) && child.type === TimelineCorner);
    const otherChildren = childrenArray.filter(child => child !== cornerChild);

    return (
      <div ref={ref} className={cn("flex flex-none", className)} {...props}>
        {cornerChild || <TimelineCorner />}
        <TimelineHeaderContent />
        {otherChildren}
      </div>
    );
  }
);
TimelineHeader.displayName = 'TimelineHeader';

export const TimelineBody = forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, children, ...props }, ref) => (
    <div ref={ref} className={cn("flex flex-1 overflow-hidden", className)} {...props}>
      {children}
    </div>
  )
);
TimelineBody.displayName = 'TimelineBody';

// Rename the existing TimelineHeader to TimelineHeaderContent
const TimelineHeaderContent = forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, forwardedRef) => {
    const context = React.useContext(TimelineContext);
    if (!context) throw new Error("TimelineHeader must be used within a TimelineRoot");
    const { visibleStartDate, visibleEndDate, getLeftPosition, dayWidth, currentZoomLevel, today, headerRef, updateScroll } = context;

    const mergedRef = mergeRefs([forwardedRef, headerRef]);

    const [months, setMonths] = useState<Date[]>([]);
    const [weeks, setWeeks] = useState<Date[]>([]);
    const [totalWidth, setTotalWidth] = useState(0);

    const updateDateRange = useCallback(() => {
      const weeksList: Date[] = [];
      const monthsList: Date[] = [];
      let currentWeek = startOfWeek(visibleStartDate);
      let currentMonth: Date | null = null;

      while (currentWeek <= visibleEndDate) {
        weeksList.push(currentWeek);
        
        if (!currentMonth || !isSameMonth(currentWeek, currentMonth)) {
          currentMonth = startOfMonth(currentWeek);
          monthsList.push(currentMonth);
        }
        
        currentWeek = addDays(currentWeek, 7);
      }

      const totalDays = differenceInDays(visibleEndDate, visibleStartDate);
      const calculatedWidth = totalDays * dayWidth;

      setWeeks(weeksList);
      setMonths(monthsList);
      setTotalWidth(calculatedWidth);
    }, [visibleStartDate, visibleEndDate, dayWidth]);

    useEffect(() => {
      updateDateRange();
    }, [visibleStartDate, visibleEndDate, updateDateRange]);

    const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
      updateScroll(e.currentTarget.scrollLeft, undefined);
    };

    const todayPosition = getLeftPosition(today);

    return (
      <div
        ref={mergedRef}
        className={cn("flex-1 overflow-x-scroll relative border-b", className)}
        style={{ scrollbarWidth: 'none', msOverflowStyle: 'none' }}
        onScroll={handleScroll}
        {...props}
      >
        <div style={{ width: `${totalWidth}px`, height: '40px' }}>
          {months.map((month, index) => (
            <div 
              key={index} 
              className="absolute top-0 h-full flex items-left text-sm font-bold uppercase"
              style={{ left: `${getLeftPosition(month)}px` }}
            >
              {format(month, 'MMMM')}
            </div>
          ))}
          {weeks.map((week, index) => (
            currentZoomLevel.showEveryDay ? (
              // Render every day
              Array.from({ length: 7 }).map((_, dayOffset) => {
                const date = addDays(week, dayOffset);
                return (
                  <div 
                    key={`${index}-${dayOffset}`}
                    className="absolute flex items-center justify-center text-xs w-[20px] h-[20px] group select-none"
                    style={{ 
                      left: `${getLeftPosition(date) - 10}px`,
                      top: '20px',
                    }}
                  >
                    <span className={cn(
                      currentZoomLevel.showDayName && "group-hover:invisible"
                    )}>
                      {format(date, 'd')}
                    </span>
                    {currentZoomLevel.showDayName && (
                      <span className={cn(
                        "absolute invisible group-hover:visible select-none",
                        isSameDay(date, today) 
                          ? "z-20 bg-background px-1 -top-5" 
                          : "absolute"
                      )}>
                        {format(date, 'EEEE')}
                      </span>
                    )}
                  </div>
                );
              })
            ) : (
              // Only render the week start
              <div 
                key={index}
                className="absolute flex items-center justify-center text-xs w-[20px] h-[20px] group select-none"
                style={{ 
                  left: `${getLeftPosition(week) - 10}px`,
                  top: '20px',
                }}
              >
                <span className={cn(
                  currentZoomLevel.showDayName && "group-hover:invisible"
                )}>
                  {format(week, 'd')}
                </span>
                {currentZoomLevel.showDayName && (
                  <span className="absolute invisible group-hover:visible select-none">
                    {format(week, 'EEEE')}
                  </span>
                )}
              </div>
            )
          ))}
        </div>
        <div 
          className="absolute flex items-center justify-center text-xs w-[20px] h-[20px] bg-red-500 text-white rounded-full z-10 pointer-events-none"
          style={{ 
            left: `${todayPosition - 10}px`,
            top: '18px',
          }}
        >
          {format(today, 'd')}
        </div>
      </div>
    );
  }
);
TimelineHeaderContent.displayName = 'TimelineHeaderContent';

export const TimelineCorner = forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => {
    const context = React.useContext(TimelineContext);
    if (!context) throw new Error("TimelineCorner must be used within a TimelineRoot");
    const { sidebarWidth } = context;

    return (
      <div 
        ref={ref} 
        className={cn("flex-shrink-0 border-r border-b", className)} 
        style={{ width: sidebarWidth, height: '40px' }}
        {...props}
      />
    );
  }
);
TimelineCorner.displayName = 'TimelineCorner';

export const TimelineSidebar = forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, children, style, ...props }, forwardedRef) => {
    const context = React.useContext(TimelineContext);
    if (!context) throw new Error("TimelineSidebar must be used within a TimelineRoot");
    const { sidebarRef, updateScroll, contentElement, setSidebarWidth } = context;

    const [scrollbarHeight, setScrollbarHeight] = useState(0);
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      const resizeObserver = new ResizeObserver(() => {
        if (!contentElement) return;
        const { offsetHeight, clientHeight } = contentElement;
        const calculatedScrollbarHeight = offsetHeight - clientHeight;
        setScrollbarHeight(calculatedScrollbarHeight);
      });
      if (contentElement) {
        resizeObserver.observe(contentElement);
      }
      return () => {
        if (contentElement) {
          resizeObserver.disconnect();
        }
      };
    }, [contentElement]);

    useEffect(() => {
      const updateWidth = () => {
        if (containerRef.current) {
          setSidebarWidth(containerRef.current.offsetWidth);
        }
      };

      const resizeObserver = new ResizeObserver(updateWidth);
      if (containerRef.current) {
        resizeObserver.observe(containerRef.current);
      }

      // Initial width update
      updateWidth();

      return () => resizeObserver.disconnect();
    }, [setSidebarWidth]);

    const mergedRef = mergeRefs([forwardedRef, sidebarRef, containerRef]);

    const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
      updateScroll(undefined, e.currentTarget.scrollTop);
    };

    return (
      <div
        ref={mergedRef}
        className={cn("overflow-y-scroll border-r flex-shrink-0", className)}
        style={{ 
          ...style,
          scrollbarWidth: 'none', 
          msOverflowStyle: 'none',
        }}
        onScroll={handleScroll}
        {...props}
      >
        {children}
        {/* Add additional space to match the horizontal scrollbar */}
        <div style={{ height: `${scrollbarHeight}px` }} />
      </div>
    );
  }
);
TimelineSidebar.displayName = 'TimelineSidebar';

export const TimelineContent = forwardRef<HTMLDivElement | null, Omit<React.HTMLAttributes<HTMLDivElement>, 'dir'>>(
  ({ className, children, ...props }, forwardedRef) => {
    const context = React.useContext(TimelineContext);
    if (!context) throw new Error("TimelineContent must be used within a TimelineRoot");
    const { visibleStartDate, visibleEndDate, getLeftPosition, dayWidth, today, contentRef, updateScroll } = context;

    const mergedRef = mergeRefs([forwardedRef, contentRef]);

    const [totalWidth, setTotalWidth] = useState(0);

    const updateTotalWidth = useCallback(() => {
      const totalDays = differenceInDays(visibleEndDate, visibleStartDate);
      const calculatedWidth = totalDays * dayWidth;
      setTotalWidth(calculatedWidth);
    }, [visibleStartDate, visibleEndDate, dayWidth]);

    useEffect(() => {
      updateTotalWidth();
    }, [visibleStartDate, visibleEndDate, updateTotalWidth]);

    const handleScroll = (e: { x: number; y: number }) => {
      updateScroll(e.x, e.y);
    };

    const todayPosition = getLeftPosition(today);

    return (
      <ScrollArea.Root
        type="always"
        dir="ltr"
        scrollHideDelay={100}
        className={cn("flex-1 relative overflow-hidden", className)}
        {...props}
      >
        <ScrollArea.Viewport
          ref={mergedRef}
          className="w-full h-full"
          onScroll={(e) => handleScroll({ 
            x: (e.target as HTMLDivElement).scrollLeft, 
            y: (e.target as HTMLDivElement).scrollTop 
          })}
        >
          <div style={{ width: `${totalWidth}px`, minHeight: '100%', position: 'relative' }}>
            {children}
            <div 
              className="absolute top-0 w-px bg-red-500 pointer-events-none"
              style={{
                left: `${todayPosition}px`,
                height: '100%',
                zIndex: 2,
              }}
            />
          </div>
        </ScrollArea.Viewport>
        <ScrollArea.Scrollbar
          orientation="horizontal"
          className="flex flex-col h-[12px] bg-background select-none touch-none p-[2px] z-10 rounded-none transition-opacity duration-100"
        >
          <ScrollArea.Thumb 
            className="relative flex-1 h-[12px] rounded-full min-w-[100px] rounded-sm bg-border hover:bg-muted-foreground/50" 
          />
        </ScrollArea.Scrollbar>
        <ScrollArea.Scrollbar
          orientation="vertical"
          className="flex w-[12px] h-full bg-background select-none touch-none p-[2px] z-10 transition-opacity duration-100"
        >
          <ScrollArea.Thumb 
            className="relative w-[12px] flex-1 rounded-full bg-border hover:bg-muted-foreground/50"
          />
        </ScrollArea.Scrollbar>
        <ScrollArea.Corner className="bg-transparent" />
      </ScrollArea.Root>
    );
  }
);
TimelineContent.displayName = 'TimelineContent';

export const TimelineTitle = forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement> & {
  rowId?: string,
}>(
  ({ className, children, rowId, ...props }, ref) => {
    const context = React.useContext(TimelineContext);
    if (!context) throw new Error("TimelineLane must be used within a TimelineRoot");
    
    const handleMouseEnter = useCallback(() => {
      if (!rowId) return;
      document.querySelectorAll('[data-row-hover="true"]')
        .forEach(el => el.setAttribute('data-row-hover', 'false'));
      document.querySelectorAll(`[data-row-id="${rowId}"]`)
        .forEach(el => el.setAttribute('data-row-hover', 'true'));
    }, [rowId]);

    const handleMouseLeave = useCallback(() => {
      if (!rowId) return;
      document.querySelectorAll(`[data-row-id="${rowId}"]`)
        .forEach(el => el.setAttribute('data-row-hover', 'false'));
    }, [rowId]);

    return (
      <div 
        ref={ref}
        className={cn(
          "h-8 flex items-center px-2 text-sm truncate",
          "data-[row-hover=true]:bg-[#303030]",
          className
        )}
        data-row-id={rowId}
        data-row-hover="false"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        {...props}
      >
        {children}
      </div>
    );
  }
);

export const TimelineLane = forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement> & {
  rowId: string,
  disabled?: boolean,
  className?: string,
  style?: React.CSSProperties,
  children?: React.ReactNode,
  showGhostRange?: boolean,
  ghostRangeDays?: number,
  onHoverDate?: (date: Date | null) => void,
  onClickGhostRange?: (start: Date, end: Date) => void,
}>(
  ({ 
    rowId, 
    className, 
    disabled, 
    children, 
    showGhostRange = false, 
    ghostRangeDays = 1,
    onHoverDate, 
    onClickGhostRange,
    ...props 
  }, ref) => {
    const context = React.useContext(TimelineContext);
    if (!context) throw new Error("TimelineLane must be used within a TimelineRoot");
    const { getLeftPosition, dayWidth, disabled: timelineDisabled, visibleStartDate } = context;

    const [isHovered, setIsHovered] = useState(false);
    const [ghostDate, setGhostDate] = useState<Date | null>(null);
    const hasExistingRange = !!children;

    const getDateFromMouseEvent = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
      const rect = e.currentTarget.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const days = Math.floor(x / dayWidth);
      return addDays(visibleStartDate, days);
    }, [dayWidth, visibleStartDate]);

    const handleMouseMove = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
      if (isHovered && !hasExistingRange && showGhostRange) {
        const date = getDateFromMouseEvent(e);
        setGhostDate(date);
        onHoverDate?.(date);
      }
    }, [isHovered, hasExistingRange, showGhostRange, getDateFromMouseEvent, onHoverDate]);

    const handleClick = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
      if (!hasExistingRange && showGhostRange && onClickGhostRange) {
        const startDate = getDateFromMouseEvent(e);
        const endDate = addDays(startDate, ghostRangeDays);
        onClickGhostRange?.(startDate, endDate);
      }
      props.onClick?.(e);
    }, [hasExistingRange, showGhostRange, ghostRangeDays, onClickGhostRange, getDateFromMouseEvent]);

    const handleMouseEnter = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
      setIsHovered(true);
      document.querySelectorAll('[data-row-hover="true"]')
        .forEach(el => el.setAttribute('data-row-hover', 'false'));
      document.querySelectorAll(`[data-row-id="${rowId}"]`)
        .forEach(el => el.setAttribute('data-row-hover', 'true'));
      props.onMouseEnter?.(e);
    }, [rowId]);

    const handleMouseLeave = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
      setIsHovered(false);
      setGhostDate(null);
      onHoverDate?.(null);
      document.querySelectorAll(`[data-row-id="${rowId}"]`)
        .forEach(el => el.setAttribute('data-row-hover', 'false'));
      props.onMouseLeave?.(e);
    }, [rowId, onHoverDate]);

    return (
      <div 
        ref={ref}
        className={cn(
          "h-[32px] flex items-center relative group",
          "data-[row-hover=true]:bg-[#303030]",
          !hasExistingRange && showGhostRange && "cursor-crosshair",
          className,
        )}
        data-row-id={rowId}
        data-row-hover="false"
        {...props}
        onMouseEnter={handleMouseEnter}
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
        onClick={handleClick}
      >
        {children}
        {/* Ghost range */}
        {showGhostRange && !hasExistingRange && ghostDate && (
          <TimelineDateRange
            start={ghostDate}
            end={addDays(ghostDate, ghostRangeDays)}
            className="opacity-30 pointer-events-none"
            disabled
          />
        )}
      </div>
    );
  }
);

export const TimelineDateRange = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement> & {
  start: Date,
  end: Date,
  previewStart?: Date,
  previewEnd?: Date,
  onUpdate?: (initialStart: Date, initialEnd: Date, start: Date, end: Date) => void | Promise<void>,
  onPreviewUpdate?: (initialStart: Date, initialEnd: Date, start: Date, end: Date) => void | Promise<void>,
  onCustomDragStart?: (e: MouseEvent, cancel: () => void) => void | Promise<void>,
  onCustomDragEnd?: (e: MouseEvent | null) => void | Promise<void>,
  disabled?: boolean,
}>(({
  start,
  end,
  previewStart,
  previewEnd,
  className,
  style,
  onUpdate,
  onPreviewUpdate,
  onCustomDragStart,
  onCustomDragEnd,
  disabled = onUpdate === undefined,
  ...props
}, ref) => {

  const context = React.useContext(TimelineContext);
  if (!context) throw new Error("TimelineDateRange must be used within a TimelineRoot");
  const { getLeftPosition, dayWidth, disabled: timelineDisabled } = context;

  const [ isMiddleHovered, setIsMiddleHovered ] = useState(false);
  const [ isStartHandleHovered, setIsStartHandleHovered ] = useState(false);
  const [ isEndHandleHovered, setIsEndHandleHovered ] = useState(false);
  const [ isStartHovered, setIsStartHovered ] = useState(false);
  const [ isEndHovered, setIsEndHovered ] = useState(false);

  const [isMouseDown, setIsMouseDown] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [dragType, setDragType] = useState<'start' | 'end' | 'middle' | null>(null);
  const [dragStartX, setDragStartX] = useState(0);
  const [initialStart, setInitialStart] = useState<Date | null>(null);
  const [initialEnd, setInitialEnd] = useState<Date | null>(null);
  const [draggedStart, setDraggedStart] = useState<Date | null>(null);
  const [draggedEnd, setDraggedEnd] = useState<Date | null>(null);
  const [draggedDuration, setDraggedDuration] = useState<number | null>(null);
  const [isDraggingStart, setIsDraggingStart] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const isCancelling = useRef(false);
  if (isCancelling.current) {
    isCancelling.current = false;
  }

  const { anchorRef, tooltipRef } = useTooltipAnchor<HTMLDivElement, HTMLDivElement>({
    gap: 10,
    allowedOrientations: ['top'],
    overrideShow:
      isStartHandleHovered
      || isEndHandleHovered
      || isStartHovered
      || isEndHovered
      || isMiddleHovered
      || isDragging
  });

  const cancelDrag = useCallback(() => {
    console.log("cancelDrag");
    isCancelling.current = true;
    setIsMouseDown(false);
    setIsDragging(false);
    setDragType(null);
    setInitialStart(null);
    setInitialEnd(null);
    setDraggedStart(null);
    setDraggedEnd(null);
    setDraggedDuration(null);
    setIsDraggingStart(false);
    onCustomDragEnd?.(null);
  }, []);

  const endDrag = useCallback(() => {
    setIsMouseDown(false);
    setIsDragging(false);
    setDragType(null);
    setInitialStart(null);
    setInitialEnd(null);
    setDraggedStart(null);
    setDraggedEnd(null);
    setDraggedDuration(null);
    setIsDraggingStart(false);
  }, []);

  // Use previewStart/previewEnd if provided, otherwise use start/end

  const handleMouseDown = useCallback((event: React.MouseEvent, type: 'start' | 'end' | 'middle') => {
    if (disabled || timelineDisabled) return;
    setIsMouseDown(true);
    setDragType(type);
    setDragStartX(event.clientX);
    setInitialStart(start);
    setInitialEnd(end);
    setDraggedStart(start);
    setDraggedEnd(end);
    setDraggedDuration(differenceInDays(end, start));
    setIsDraggingStart(type === 'start' || type === 'middle');

    onCustomDragStart?.(event.nativeEvent, cancelDrag);
  }, [disabled, timelineDisabled, start, end, onCustomDragStart, cancelDrag]);

  const handleMouseMove = useCallback((event: MouseEvent) => {
    if (!isMouseDown || !initialStart || !initialEnd || isCancelling.current) return;

    const deltaX = event.clientX - dragStartX;
    const daysDelta = Math.round(deltaX / dayWidth);

    if (!isDragging) {
      if (daysDelta !== 0) {
        setIsDragging(true);
      }
      return;
    }

    let newStart = initialStart;
    let newEnd = initialEnd;
    let newDuration = differenceInDays(initialEnd, initialStart);

    switch (dragType) {
      case 'start':
        newStart = addDays(initialStart, daysDelta);
        newDuration = differenceInDays(newEnd, newStart);
        break;
      case 'end':
        newEnd = addDays(initialEnd, daysDelta);
        newDuration = differenceInDays(newEnd, newStart);
        break;
      case 'middle':
        newStart = addDays(initialStart, daysDelta);
        newEnd = addDays(initialEnd, daysDelta);
        newDuration = differenceInDays(newEnd, newStart);
        break;
    }

    if (newDuration > 0 && (
      !draggedStart || 
      !draggedEnd || 
      newStart.getTime() !== draggedStart.getTime() || 
      newEnd.getTime() !== draggedEnd.getTime()
    )) {
      setDraggedStart(newStart);
      setDraggedEnd(newEnd);
      setDraggedDuration(newDuration);
      
      // Call onPreviewUpdate with the new start and end dates
      onPreviewUpdate?.(initialStart, initialEnd, newStart, newEnd);
    }
  }, [isMouseDown, isDragging, dragStartX, dragType, initialStart, initialEnd, dayWidth, onPreviewUpdate, draggedStart, draggedEnd]);

  const handleMouseUp = useCallback(async (e: MouseEvent) => {
    if (isDragging && isMouseDown && draggedStart && draggedEnd && initialStart && initialEnd) {
      onCustomDragEnd?.(e);
      if (draggedStart.getTime() !== initialStart.getTime() || draggedEnd.getTime() !== initialEnd.getTime()) {
        await onUpdate?.(initialStart, initialEnd, draggedStart, draggedEnd);
      } else {
        // Call onPreviewUpdate with the original effective start and end dates
        // to reset the preview if no changes were made
        onPreviewUpdate?.(initialStart, initialEnd, initialStart, initialEnd);
      }
    }
    endDrag();
  }, [isMouseDown, draggedStart, draggedEnd, initialStart, initialEnd, onUpdate, onCustomDragEnd, endDrag, onPreviewUpdate]);

  useEffect(() => {
    if (isMouseDown) {
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);
    }
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isMouseDown, handleMouseMove, handleMouseUp]);

  const displayStart = draggedStart || previewStart || start;
  const displayEnd = draggedEnd || previewEnd || end;
  const displayDuration = differenceInDays(displayEnd, displayStart);

  return <>

    <div ref={tooltipRef} className="fixed bg-black/70 text-white text-xs font-light rounded-sm p-[2px] shadow-md -left-[9999px] -top-[9999px] z-50 pointer-events-none select-none">
      {`${displayDuration} ${displayDuration === 1 ? 'day' : 'days'}`}
    </div>

    <div
      ref={mergeRefs([ref, anchorRef])}
      // ref={ref}
      className={cn(
        "absolute h-[20px] bg-white rounded-sm",
        className,
        "data-[dragging-start=true]:cursor-col-resize",
        "data-[dragging-middle=true]:cursor-grabbing",
        "data-[dragging-end=true]:cursor-col-resize"
      )}
      style={{
        ...style,
        left: `${getLeftPosition(displayStart)}px`,
        width: `${displayDuration * dayWidth}px`,
      }}
      data-dragging-start={dragType === 'start'}
      data-dragging-middle={dragType === 'middle'}
      data-dragging-end={dragType === 'end'}
      {...props}
      onMouseEnter={e => { setIsHovered(true); props.onMouseEnter?.(e); }}
      onMouseLeave={e => { setIsHovered(false); props.onMouseLeave?.(e); }}
    >

      <div
        className="absolute h-full pr-[16px] -left-[60px] w-[60px] min-w-[60px] flex items-center justify-end text-xs select-none cursor-default bg-purple-500/0"
        style={{
          opacity: isStartHandleHovered || isDraggingStart || isHovered ? 1 : 0,
          transition: 'opacity 0.2s',
        }}
      >
        {isValid(displayStart) ? format(displayStart, 'MMM d') : ''}
      </div>
      <div
        className="absolute h-full pl-[16px] -right-[60px] w-[60px] min-w-[60px] flex items-center justify-start text-xs select-none cursor-default bg-yellow-500/0"
        style={{
          opacity: isEndHandleHovered || isDragging || isHovered ? 1 : 0,
          transition: 'opacity 0.2s',
        }}
      >
        {format(displayEnd, 'MMM d')}
      </div>
      <div 
        className={cn(
          "absolute inset-0",
          "data-[dragging=idle]:cursor-grab",
          "data-[dragging=start]:cursor-col-resize",
          "data-[dragging=middle]:cursor-grabbing",
          "data-[dragging=end]:cursor-col-resize"
        )}
        data-dragging={disabled || timelineDisabled ? 'disabled' : isDragging ? dragType : 'idle'}
        onMouseDown={(e) => handleMouseDown(e, 'middle')}
        onMouseEnter={() => setIsMiddleHovered(true)}
        onMouseLeave={() => setIsMiddleHovered(false)}
      />
      <div
        className={cn(
          "absolute h-full -left-[8px] w-[11px] min-w-[11px] bg-red-500/0",
          "data-[oneday=true]:-left-[11px]",
          "data-[twodays=true]:-left-[9px]",
          "data-[dragging=idle]:cursor-col-resize",
          "data-[dragging=start]:cursor-col-resize",
          "data-[dragging=middle]:cursor-grabbing",
          "data-[dragging=end]:cursor-col-resize"
        )}
        data-dragging={disabled || timelineDisabled ? 'disabled' : isDragging ? dragType : 'idle'}
        data-oneday={displayDuration < 2}
        data-twodays={displayDuration >= 2 && displayDuration < 3}
        onMouseEnter={() => setIsStartHandleHovered(true)}
        onMouseLeave={() => setIsStartHandleHovered(false)}
        onMouseDown={(e) => handleMouseDown(e, 'start')}
      />
      <div
        className={cn(
          "absolute h-full -right-[8px] w-[11px] min-w-[11px] bg-green-500/0",
          "data-[oneday=true]:-right-[11px]",
          "data-[twodays=true]:-right-[9px]",
          "data-[dragging=idle]:cursor-col-resize",
          "data-[dragging=start]:cursor-col-resize",
          "data-[dragging=middle]:cursor-grabbing",
          "data-[dragging=end]:cursor-col-resize"
        )}
        data-dragging={disabled || timelineDisabled ? 'disabled' : isDragging ? dragType : 'idle'}
        data-oneday={displayDuration < 2}
        data-twodays={displayDuration >= 2 && displayDuration < 3}
        onMouseEnter={() => setIsEndHandleHovered(true)}
        onMouseLeave={() => setIsEndHandleHovered(false)}
        onMouseDown={(e) => handleMouseDown(e, 'end')}
      />
    </div>
  </>
});

TimelineDateRange.displayName = 'TimelineDateRange';

export const TimelineZoom = forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => {
    const context = React.useContext(TimelineContext);
    if (!context) throw new Error("TimelineZoom must be used within a TimelineRoot");
    const { canZoomIn, canZoomOut, zoomIn, zoomOut } = context;

    return (
      <div 
        ref={ref}
        className={cn(
          "absolute top-0 right-0 flex gap-1 bg-muted backdrop-blur-sm rounded-md p-1 shadow-md p-2",
          className
        )}
        {...props}
      >
        <button
          type="button"
          className={cn(
            "p-1 rounded hover:bg-accent",
            !canZoomOut && "opacity-50 cursor-not-allowed"
          )}
          onClick={zoomOut}
          disabled={!canZoomOut}
          title="Zoom out"
        >
          <ZoomOut className="w-4 h-4" />
        </button>
        <button
          type="button"
          className={cn(
            "p-1 rounded hover:bg-accent",
            !canZoomIn && "opacity-50 cursor-not-allowed"
          )}
          onClick={zoomIn}
          disabled={!canZoomIn}
          title="Zoom in"
        >
          <ZoomIn className="w-4 h-4" />
        </button>
      </div>
    );
  }
);
TimelineZoom.displayName = 'TimelineZoom';

export default TimelineRoot;