"use client";

// utils.tsx
import React, { ReactNode, ElementType } from 'react';

export * from "./callbacks";
export * from "./conditionals";
export * from "./elements";
export * from "./fast-tooltip";
export * from "./historyChange";
export * from "./isolatedHistory";
export * from "./next/nextjs";
export * from "./shortcuts";
export * from "./sticky";
export * from "./text-overflow-tooltip";
export * from "./tooltip-anchor";
export * from "./video";

// Type guard function to check if a variable is a ReactNode
export function isReactNode(value: any): value is ReactNode {
  // You can add more complex logic here depending on your needs
  return (
    value === null ||
    typeof value === 'string' ||
    typeof value === 'number' ||
    React.isValidElement(value) || // checks if it's a valid JSX element
    (Array.isArray(value) && value.every(isReactNode)) // checks if it's an array of ReactNodes
  );
}

export type RequiredImageProps = {
  className?: string;
  src: string;
  width: number | string;
  height: number | string;
  alt: string;
  unoptimized?: boolean;
  onClick?: (event: React.MouseEvent) => void;
};

export type ImageComponentType<T = {}> = (React.ElementType<RequiredImageProps> & object) | "img";

// Added a type constraint here to accept any type that has the required properties.
export function withImageType<T extends RequiredImageProps>(
  ImageType: ElementType<T>
) {
  return (props: T & { children?: ReactNode }) => {
    return React.createElement(ImageType, props, props.children);
  };
}


export type RequiredAnchorProps = {
  href: string;
  className?: string;
};

export type AnchorComponentType<T = {}> = (React.ElementType<RequiredAnchorProps> & object) | "a";

// Added a type constraint here to accept any type that has the required properties.
export function withAnchorType<T extends RequiredAnchorProps>(
  AnchorType: ElementType<T>
) {
  return (props: T & { children?: ReactNode }) => {
    return React.createElement(AnchorType, props, props.children);
  };
}
