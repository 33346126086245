import { Group } from "./model";



export interface DogsSelectionChangeEventDetail {
  newSelection: Set<string>;
  oldSelection: Set<string>;
  originalEvent: Event | null;
}

export type DogsSelectionChangeEvent = CustomEvent<DogsSelectionChangeEventDetail>;

declare global {
  interface WindowEventMap {
    'ondogsselectionchange': DogsSelectionChangeEvent;
  }
}

export function createCustomEvent<T>(eventName: string, detail: T): CustomEvent<T> {
  return new CustomEvent(eventName, { 
    bubbles: true, 
    cancelable: true, 
    detail 
  });
}

export {};