import React, { useRef, useState, useEffect, useCallback, createContext, useContext } from 'react';

interface TooltipPosition {
  top: number;
  left: number;
  orientation: Orientation;
}

type Orientation = 'top' | 'bottom' | 'left' | 'right';

interface TooltipAnchorOptions {
  gap: number;
  allowedOrientations?: Orientation[];
  overrideShow?: boolean;
  ignoreObstruction?: boolean;
}

interface AnchorData {
  updatePosition: (event: MouseEvent) => void;
  isHovered: boolean;
  lastUpdate: number;
}

interface AnchoredTooltipContextType {
  registerAnchor: (anchor: HTMLElement, updatePosition: (event: MouseEvent) => void) => void;
  unregisterAnchor: (anchor: HTMLElement) => void;
  setAnchorHovered: (anchor: HTMLElement, isHovered: boolean) => void;
}

const AnchoredTooltipContext = createContext<AnchoredTooltipContextType | null>(null);

// Throttle mouse move events
const THROTTLE_MS = 32; // ~30fps
const PADDING = 100; // Padding around elements for tooltip calculations

// Move these to module level functions outside components
const isMouseWithinElement = (event: MouseEvent, rect: DOMRect) => {
  return (
    event.clientX >= rect.left &&
    event.clientX <= rect.right &&
    event.clientY >= rect.top &&
    event.clientY <= rect.bottom
  );
};

const calculateTooltipPosition = (
  event: MouseEvent,
  anchorRect: DOMRect,
  tooltipRect: DOMRect,
  gap: number,
  allowedOrientations: Orientation[]
): TooltipPosition => {
  const distToTop = event.clientY - anchorRect.top;
  const distToBottom = anchorRect.bottom - event.clientY;
  const distToLeft = event.clientX - anchorRect.left;
  const distToRight = anchorRect.right - event.clientX;

  const allowedDistances = allowedOrientations.map(orientation => {
    switch (orientation) {
      case 'top': return distToTop;
      case 'bottom': return distToBottom;
      case 'left': return distToLeft;
      case 'right': return distToRight;
    }
  });

  const minDist = Math.min(...allowedDistances);
  let orientation = allowedOrientations[allowedDistances.indexOf(minDist)];
  let left, top;

  switch (orientation) {
    case 'top':
      left = Math.max(0, Math.min(
        event.clientX - tooltipRect.width / 2,
        window.innerWidth - tooltipRect.width
      ));
      top = anchorRect.top - tooltipRect.height - gap;
      break;
    case 'bottom':
      left = Math.max(0, Math.min(
        event.clientX - tooltipRect.width / 2,
        window.innerWidth - tooltipRect.width
      ));
      top = anchorRect.bottom + gap;
      break;
    case 'left':
      left = anchorRect.left - tooltipRect.width - gap;
      top = Math.max(0, Math.min(
        event.clientY - tooltipRect.height / 2,
        window.innerHeight - tooltipRect.height
      ));
      break;
    case 'right':
      left = anchorRect.right + gap;
      top = Math.max(0, Math.min(
        event.clientY - tooltipRect.height / 2,
        window.innerHeight - tooltipRect.height
      ));
      break;
    default:
      left = 0;
      top = 0;
      orientation = 'top';
  }

  return { left, top, orientation };
};

const checkObstruction = (
  event: MouseEvent, 
  anchorEl: HTMLElement | null,
  tooltipEl: HTMLElement | null,
  cachedElements?: Element[]
) => {
  if (!anchorEl) return false;
  const elements = cachedElements || document.elementsFromPoint(event.clientX, event.clientY);
  const anchorIndex = elements.findIndex(el => el === anchorEl || anchorEl?.contains(el));
  
  // Check if any element between the mouse and the anchor is not the tooltip
  if (anchorIndex === -1) return true; // Anchor not found in stack, consider it obstructed
  return elements.slice(0, anchorIndex).some(el => 
    el !== tooltipEl && 
    !tooltipEl?.contains(el) && 
    !anchorEl.contains(el)
  );
};

export function AnchoredTooltipProvider({ children }: { children: React.ReactNode }) {
  const anchors = useRef<Map<HTMLElement, AnchorData>>(new Map());
  const rafId = useRef<number>();
  const lastUpdateTime = useRef<number>(0);
  const lastMousePosition = useRef({ x: 0, y: 0 });
  const elementsCache = useRef<Element[]>([]);

  const hideAllTooltips = useCallback(() => {
    anchors.current.forEach((data, anchor) => {
      const tooltip = anchor.querySelector('[role="tooltip"]');
      if (tooltip && tooltip instanceof HTMLElement) {
        tooltip.style.position = 'fixed';
        tooltip.style.left = '-9999px';
        tooltip.style.top = '-9999px';
      }
      data.isHovered = false;
    });
  }, []);

  const handleMouseMove = useCallback((event: MouseEvent) => {
    if (rafId.current) {
      cancelAnimationFrame(rafId.current);
    }

    rafId.current = requestAnimationFrame(() => {
      const now = performance.now();
      if (now - lastUpdateTime.current < THROTTLE_MS) {
        return;
      }
      
      const mouseX = event.clientX;
      const mouseY = event.clientY;

      // Check if mouse is within any anchor's bounds
      let mouseOverAnyAnchor = false;
      anchors.current.forEach((_, anchor) => {
        const rect = anchor.getBoundingClientRect();
        if (
          mouseX >= rect.left &&
          mouseX <= rect.right &&
          mouseY >= rect.top &&
          mouseY <= rect.bottom
        ) {
          mouseOverAnyAnchor = true;
        }
      });

      // If mouse isn't over any anchor, hide all tooltips
      if (!mouseOverAnyAnchor) {
        hideAllTooltips();
        return;
      }

      // Only update positions for relevant anchors
      anchors.current.forEach((data, anchor) => {
        const rect = anchor.getBoundingClientRect();
        if (
          mouseX >= rect.left - PADDING &&
          mouseX <= rect.right + PADDING &&
          mouseY >= rect.top - PADDING &&
          mouseY <= rect.bottom + PADDING
        ) {
          const eventWithCache = new MouseEvent('mousemove', {
            clientX: mouseX,
            clientY: mouseY,
            bubbles: true
          }) as MouseEvent & { cachedElements?: Element[] };
          (eventWithCache as any).cachedElements = elementsCache.current;
          
          data.updatePosition(eventWithCache);
        }
      });

      lastUpdateTime.current = now;
    });
  }, [hideAllTooltips]);

  const registerAnchor = useCallback((anchor: HTMLElement, updatePosition: (event: MouseEvent) => void) => {
    anchors.current.set(anchor, { 
      updatePosition, 
      isHovered: false,
      lastUpdate: 0
    });
  }, []);

  const unregisterAnchor = useCallback((anchor: HTMLElement) => {
    anchors.current.delete(anchor);
  }, []);

  const setAnchorHovered = useCallback((anchor: HTMLElement, isHovered: boolean) => {
    const data = anchors.current.get(anchor);
    if (data) {
      data.isHovered = isHovered;
    }
  }, []);

  useEffect(() => {
    document.addEventListener('mousemove', handleMouseMove);
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      if (rafId.current) {
        cancelAnimationFrame(rafId.current);
      }
    };
  }, [handleMouseMove]);

  // Add blur and scroll listeners to hide tooltips
  useEffect(() => {
    const handleBlur = () => hideAllTooltips();
    const handleScroll = () => hideAllTooltips();

    window.addEventListener('blur', handleBlur);
    window.addEventListener('scroll', handleScroll, true);
    
    return () => {
      window.removeEventListener('blur', handleBlur);
      window.removeEventListener('scroll', handleScroll, true);
    };
  }, [hideAllTooltips]);

  // Add cleanup on unmount
  useEffect(() => {
    return () => {
      // Force hide all tooltips when provider unmounts
      anchors.current.forEach((data, anchor) => {
        const tooltip = anchor.querySelector('[role="tooltip"]');
        if (tooltip && tooltip instanceof HTMLElement) {
          tooltip.style.position = 'fixed';
          tooltip.style.left = '-9999px';
          tooltip.style.top = '-9999px';
        }
      });
    };
  }, []);

  return (
    <AnchoredTooltipContext.Provider value={{ registerAnchor, unregisterAnchor, setAnchorHovered }}>
      {children}
    </AnchoredTooltipContext.Provider>
  );
}

// In useTooltipAnchor, replace state management with direct DOM manipulation
export function useTooltipAnchor<T extends HTMLElement, U extends HTMLElement>(
  options: TooltipAnchorOptions
) {
  const context = useContext(AnchoredTooltipContext);
  const { 
    gap = 10, 
    allowedOrientations = ['top', 'bottom', 'left', 'right'],
    overrideShow = false,
    ignoreObstruction = false
  } = options;
  
  const anchorRef = useRef<T | null>(null);
  const tooltipRef = useRef<U | null>(null);
  const isHoveredRef = useRef(false);

  const updateTooltipPosition = useCallback(
    (event: MouseEvent & { cachedElements?: Element[] }) => {
      const anchor = anchorRef.current;
      const tooltip = tooltipRef.current;
      if (!anchor || !tooltip) return;

      const anchorRect = anchor.getBoundingClientRect();
      const isWithin = isMouseWithinElement(event, anchorRect);
      
      if (!isWithin && !overrideShow) {
        tooltip.style.position = 'fixed';
        tooltip.style.left = '-9999px';
        tooltip.style.top = '-9999px';
        isHoveredRef.current = false;
        return;
      }

      const obstructed = !ignoreObstruction && checkObstruction(event, anchor, tooltip, event.cachedElements);
      if (obstructed && !overrideShow) {
        tooltip.style.position = 'fixed';
        tooltip.style.left = '-9999px';
        tooltip.style.top = '-9999px';
        isHoveredRef.current = false;
        return;
      }

      isHoveredRef.current = true;
      const tooltipRect = tooltip.getBoundingClientRect();
      const position = calculateTooltipPosition(event, anchorRect, tooltipRect, gap, allowedOrientations);
      
      tooltip.style.position = 'fixed';
      tooltip.style.left = `${position.left}px`;
      tooltip.style.top = `${position.top}px`;
    },
    [gap, allowedOrientations, overrideShow, ignoreObstruction]
  );

  useEffect(() => {
    const anchor = anchorRef.current;
    const tooltip = tooltipRef.current;
    if (!anchor || !tooltip) return;

    const handleMouseLeave = () => {
      if (context) {
        context.setAnchorHovered(anchor, false);
      }
      tooltip.style.position = 'fixed';
      tooltip.style.left = '-9999px';
      tooltip.style.top = '-9999px';
      isHoveredRef.current = false;
    };

    if (context) {
      context.registerAnchor(anchor, updateTooltipPosition);
      anchor.addEventListener('mouseleave', handleMouseLeave);
      return () => {
        context.unregisterAnchor(anchor);
        anchor.removeEventListener('mouseleave', handleMouseLeave);
      };
    } else {
      anchor.addEventListener('mousemove', updateTooltipPosition);
      anchor.addEventListener('mouseleave', handleMouseLeave);
      return () => {
        anchor.removeEventListener('mousemove', updateTooltipPosition);
        anchor.removeEventListener('mouseleave', handleMouseLeave);
      };
    }
  }, [updateTooltipPosition, context]);

  // Add cleanup effect
  useEffect(() => {
    return () => {
      // Force hide tooltip when hook unmounts
      if (tooltipRef.current) {
        tooltipRef.current.style.position = 'fixed';
        tooltipRef.current.style.left = '-9999px';
        tooltipRef.current.style.top = '-9999px';
      }
    };
  }, []);

  // Add global mousemove listener to catch all mouse movements
  useEffect(() => {
    const tooltip = tooltipRef.current;
    if (!tooltip) return;

    const handleGlobalMouseMove = (e: MouseEvent) => {
      const anchor = anchorRef.current;
      if (!anchor) return;

      const rect = anchor.getBoundingClientRect();
      const isWithin = e.clientX >= rect.left && 
                      e.clientX <= rect.right && 
                      e.clientY >= rect.top && 
                      e.clientY <= rect.bottom;

      if (!isWithin && !overrideShow) {
        tooltip.style.position = 'fixed';
        tooltip.style.left = '-9999px';
        tooltip.style.top = '-9999px';
        isHoveredRef.current = false;
      }
    };

    document.addEventListener('mousemove', handleGlobalMouseMove);
    return () => {
      document.removeEventListener('mousemove', handleGlobalMouseMove);
    };
  }, [overrideShow]);

  return { anchorRef, tooltipRef };
}

export default useTooltipAnchor;
