"use client";
import React, { useRef, useCallback, useMemo } from "react";
import { DogsHookResult, IterateChild, IterateDragImage, IterateGroup, IterateItem, IterateRoot } from "./hook";
//import { arraysEqual } from "./utils";

const arraysEqual = (a: readonly string[] | null, b: readonly string[] | null, debugId?: string) => {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;
  for (let i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) {
      // if (debugId === "editable-table") {
      //   console.log("array not equal at index", i, a[i], b[i], debugId);
      // }
      return false;
    }
  }
  return true;
};



export type RenderFn<T> = (props: {
  root: (props: IterateRoot<T> & { children: React.ReactNode }) => React.ReactNode,
  group?: (props: IterateGroup<T> & { children: React.ReactNode }) => React.ReactNode,
  child: (props: IterateChild<T>) => React.ReactNode,
  dragImage?: (props: IterateDragImage<T>) => React.ReactNode;
  rootRenderDeps?: (item: IterateRoot<T>) => any[] | null;
  groupRenderDeps?: (item: IterateGroup<T>) => any[] | null;
  childRenderDeps?: (item: IterateChild<T>) => any[] | null;
}, renderKey?: any[]) => React.ReactNode;


export function useDogsRender<T>(props: {iterate: () => IterateItem<T>[], debugId?: string}) {
  const debugId = React.useRef(props.debugId);
  debugId.current = props.debugId;
  const propsRef = React.useRef(props);
  propsRef.current = props;

  const render: RenderFn<T> = React.useCallback(
    (
      {
        root: rootRenderFn,
        group: groupRenderFn,
        child: childRenderFn,
        dragImage: dragImageRenderFn,
        rootRenderDeps,
        groupRenderDeps,
        childRenderDeps,
      },
      renderKey = []
    ) => {
      const prevDepsMap = React.useRef<Map<string, any[] | null>>(new Map());
      const prevElementsMap = React.useRef<Map<string, React.ReactNode>>(new Map());
      const dragImageContainerRef = React.useRef<HTMLDivElement | null>(null);
      const shouldUpdateMap = React.useRef<Map<string, boolean>>(new Map());

      function determineUpdates(item: IterateItem<T>): boolean {
        const itemId = item.id;
        let currentDeps: any[] | null = null;

        switch (item.itemType) {
          case 'root':
            currentDeps = rootRenderDeps ? rootRenderDeps(item) : null;
            break;
          case 'group':
            currentDeps = groupRenderDeps ? groupRenderDeps(item) : null;
            break;
          case 'child':
            currentDeps = childRenderDeps ? childRenderDeps(item) : null;
            break;
          default:
            currentDeps = null;
            break;
        }

        const prevDeps = prevDepsMap.current.get(itemId) || null;
        const hasChanged = !currentDeps || !prevDeps || !arraysEqual(currentDeps, prevDeps, propsRef.current.debugId);
        prevDepsMap.current.set(itemId, currentDeps);

        let shouldUpdate = hasChanged;

        if (item.itemType === 'root' || item.itemType === 'group') {
          const childItems = item.iterate();
          for (const childItem of childItems) {
            const childNeedsUpdate = determineUpdates(childItem);
            if (childNeedsUpdate) {
              shouldUpdate = true;
            }
          }
        }

        shouldUpdateMap.current.set(itemId, shouldUpdate);
        return shouldUpdate;
      }

      function renderItem(item: IterateItem<T>): React.ReactNode {
        const itemId = item.id;
        const shouldUpdate = shouldUpdateMap.current.get(itemId) || false;

        if (!shouldUpdate) {
          // Return cached element if available
          const prevElement = prevElementsMap.current.get(itemId);
          if (prevElement !== undefined) {
            return prevElement;
          }
          // If no cached element, we need to render it
        }

        let renderedElement: React.ReactNode;

        switch (item.itemType) {
          case 'root':
            const renderFn = rootRenderFn;
            const childItems = item.iterate();
            const children = childItems.map(renderItem);
            renderedElement = renderFn ? renderFn({ ...item, children }) : null;
            break;
          case 'group': {
            const renderFn = groupRenderFn;
            const childItems = item.iterate();
            const children = childItems.map(renderItem);
            renderedElement = renderFn ? renderFn({ ...item, children }) : null;
            break;
          }
          case 'child':
            renderedElement = childRenderFn(item);
            break;
          case 'dragImage':
            renderedElement = dragImageRenderFn ? dragImageRenderFn(item) : null;
            break;
          default:
            renderedElement = null;
            break;
        }

        // Cache the rendered element
        prevElementsMap.current.set(itemId, renderedElement);

        return renderedElement;
      }

      React.useEffect(() => {
        const onMove = (e: MouseEvent) => {
          dragImageContainerRef.current?.style.setProperty(
            'transform',
            `translate(${e.clientX}px, ${e.clientY}px)`
          );
        };
        document.addEventListener('mousemove', onMove);
        return () => {
          dragImageContainerRef.current?.style.setProperty(
            'transform',
            'translate(-9999px, -9999px)'
          );
          document.removeEventListener('mousemove', onMove);
        };
      }, []);

      const items = propsRef.current.iterate();
      shouldUpdateMap.current.clear();
      items.forEach((item) => {
        if (item.itemType === 'root') {
          determineUpdates(item);
        }
      });
      const rootItem = items.find((item) => item.itemType === 'root');
      const renderedTree = rootItem ? renderItem(rootItem) : null;

      return (
        <>
          <div
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: 0,
              height: 0,
              zIndex: 9999,
            }}
          >
            <div
              ref={dragImageContainerRef}
              style={{
                pointerEvents: 'none',
                position: 'absolute',
                transform: 'translate(-9999px, -9999px)',
                zIndex: 9999,
              }}
            >
              {/* Render drag image if present */}
              {dragImageRenderFn && items.some(item => item.itemType === 'dragImage')
                ? dragImageRenderFn(items.find(item => item.itemType === 'dragImage') as IterateDragImage<T>)
                : null}
            </div>
          </div>
          {renderedTree}
        </>
      );
    },
    [propsRef]
  );

  return render;
}