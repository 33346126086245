"use client";

import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip';
import React from 'react';


export const TextOverflowTooltip = ({
  children,
  tooltipText,
  side = "top",
}: {
  children: React.ReactNode,
  tooltipText: string,
  side?: "top" | "right" | "bottom" | "left",
}) => {
  
  const [open, setOpen] = React.useState(false);
  const timeoutRef = React.useRef<number>();
  const delay = Math.max(600 / (tooltipText.length * 0.1), 200);

  const handlePointerEnter = () => {
    timeoutRef.current = window.setTimeout(() => {
      setOpen(true);
    }, delay);
  };

  const handlePointerLeave = () => {
    if (timeoutRef.current) {
      window.clearTimeout(timeoutRef.current);
    }
    setOpen(false);
  };

  return <Tooltip
    open={open}
  >
    <TooltipTrigger
      asChild
      onPointerEnter={handlePointerEnter}
      onPointerLeave={handlePointerLeave}
    >
      {children}
    </TooltipTrigger>
    <TooltipContent
      side={side}
      className="transition-none"
    >
      <p className="break-all w-full h-full">
        {tooltipText}
      </p>
    </TooltipContent>
  </Tooltip>;
}