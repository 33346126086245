import * as React from "react"
import { Input, InputProps } from "@/components/ui/input"

interface PassiveInputSubmitProps
  extends InputProps {
  onPassiveSubmit: (value: string) => void
  autoFocus?: boolean
  defaultValue?: string
}

export function PassiveInputSubmit({
  onPassiveSubmit,
  defaultValue = "",
  autoFocus = false,
  ...props
}: PassiveInputSubmitProps) {
  const [value, setValue] = React.useState(defaultValue);
   
  React.useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const handleSubmit = React.useCallback(() => {
    onPassiveSubmit(value);
  }, [onPassiveSubmit, value])

  return (
    <Input
      {...props}
      autoFocus={autoFocus}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      onBlur={e => { e.preventDefault(); e.stopPropagation(); handleSubmit(); props.onBlur?.(e) }}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          handleSubmit()
        }
      }}
    />
  )
}
