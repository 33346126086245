"use client";

import React, { ReactNode } from "react";
import { ArrowRightIcon, CheckIcon, ChevronDownIcon, FolderIcon, MoreHorizontalIcon, PencilIcon, TrashIcon, UserIcon, UserPlusIcon, UsersIcon } from "lucide-react";
import { 
  TimelineRoot, 
  TimelineHeader,
  TimelineBody,
  TimelineSidebar,
  TimelineContent,
  TimelineTitle, 
  TimelineLane,
  cn,
  TimelineDateRange,
  TimelineCorner,
  Button,
  CreatePhaseModal,
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSub,
  DropdownMenuSubTrigger,
  DropdownMenuSubContent,
  UserPopover,
  RenamePhaseModal,
  DeletePhaseModal,
  useStickyCollapse,
  TooltipProvider,
  TimelineZoom,
} from "@palette.tools/react";
import { Group, Item, useDogs, IterateChild, IterateGroup, DogsSelectionChangeEvent, SelectionManager, AllowInsertExternalFn, AllowInsertFn, GroupDeadZoneFn } from "@palette.tools/react.dogs";
import { Asset, Phase, Project, Task, UserProfile, Workspace } from "@palette.tools/model.client";
import { getProfileName, TaskStatus } from "@palette.tools/model";
import { Avatar } from "../../image";
import { PhaseTimelineRow, usePhaseTimeline } from "./model";
import { SwapButton } from "../../buttons";
import { usePhaseTimelineSelection } from "./selection";
import { TextOverflowTooltip } from "../../utils/text-overflow-tooltip";


const TASK_STYLES = {
  [TaskStatus.NotStarted]: "bg-gray-500",
  [TaskStatus.NeedsWork]: "bg-yellow-500",
  [TaskStatus.InProgress]: "bg-yellow-500",
  [TaskStatus.NeedsReview]: "bg-orange-500",
  [TaskStatus.Complete]: "bg-green-500",
}

function AddUserButton({
  users,
  disabled,
  onAddUser,
}: {
  users?: UserProfile[],
  disabled?: boolean,
  onAddUser?: (user: UserProfile) => Promise<void>,
}) {

  return <div
    className={cn(
      "flex flex-col content-center items-center float-left",
      disabled ? "cursor-not-allowed" : "cursor-pointer",
    )}
    onClick={(e) => {
      if (disabled) return;
      e.preventDefault(); e.stopPropagation();
    }}
    dogs-selection-enabled="false"
    dogs-dragging-enabled="false"
  >
    <UserPopover
      modal
      disable={disabled}
      selectableUsers={users}
      onSelectUser={async (user) => {
        await onAddUser?.(user);
      }}
    >
      <div className="w-[30px] h-[30px] flex flex-row items-center place-content-center">
        <UserPlusIcon className="w-[15px] h-[15px]" />
      </div>
    </UserPopover>
  </div>

}

export const PhaseTimeline: React.FC<{
  className?: string,
  editable?: boolean,
  externalDataKey?: string,
  profiles: UserProfile[],
  workspace: Workspace | null,
  project: Project | null,
  tasks: Task[],
  phases: Phase[],
  profilesByPhase: Record<string, UserProfile[]>,
  profilesByTask: Record<string, UserProfile[]>,
  tasksByPhase: Record<string, Task[]>,
  tasksByProfile: Record<string, Task[]>,
  assetByTask: Record<string, Asset>,
  canCreatePhase?: boolean,
  allowInsertExternal?: AllowInsertExternalFn | string,
  showCompletedTasks?: boolean,
  getCanEditPhase?: (phase?: Phase | null) => boolean,
  insertRows?: (props: {insertedRows: PhaseTimelineRow[], index: number, parentRow: PhaseTimelineRow | null, ancestorRows: PhaseTimelineRow[]}) => void,
  insertExternalItems?: (props: {insertedRows: (Item<any> | Group<any>)[], index: number, parentRow: PhaseTimelineRow | null, ancestorRows: PhaseTimelineRow[], key: string}) => void,
  getCanDeletePhase?: (phase?: Phase | null) => boolean,
  onAddUserToPhase?: (phase: Phase, user: UserProfile) => Promise<void>,
  onCreatePhase?: (props: Parameters<typeof Phase.create>[0]) => Promise<void>,
  onDeletePhase?: (props: {id: string}) => Promise<void>,
  onDropTasks?: (props: {id: string, phaseId: string, profileId?: string, beforeTaskId?: string, afterTaskId?: string}[]) => Promise<void>,
  onMoveTasksToUser?: (tasks: Task[], phase: Phase, user: UserProfile | null) => Promise<void>,
  onRemoveTasksFromPhase?: (removals: {tasks: Task[], phase: Phase}[]) => Promise<void>,
  onRemoveUserFromPhase?: (user: UserProfile, phase: Phase) => Promise<void>,
  onRenamePhase?: (updates: {id: string, name: string}) => Promise<void>,
  onSchedulePhasesAndTasks?: (updates: {taskUpdates: {id: string, estimate: number, deadline: number}[], phaseUpdates: {id: string, estimate: number, deadline: number}[]}) => Promise<void>,
  onClickProfile?: (profile: UserProfile) => Promise<void>,
  onClickAsset?: (asset: Asset, task?: Task) => Promise<void>,
  onClickTask?: (task: Task) => Promise<void>,
  onSwapTimeline?: (timelineKey: string) => Promise<void>,
  selectionManagerRef?: React.Ref<SelectionManager>,
}> = ({
  className,
  editable,
  profiles,
  workspace,
  project,
  tasks,
  phases,
  profilesByPhase,
  profilesByTask,
  tasksByPhase,
  tasksByProfile,
  assetByTask,
  externalDataKey,
  canCreatePhase = true,
  allowInsertExternal,
  showCompletedTasks = false,
  insertRows,
  insertExternalItems,
  getCanEditPhase = () => true,
  getCanDeletePhase = () => true,
  onAddUserToPhase,
  onCreatePhase,
  onDeletePhase,
  onDropTasks,
  onMoveTasksToUser,
  onRemoveTasksFromPhase,
  onRemoveUserFromPhase,
  onRenamePhase,
  onSchedulePhasesAndTasks,
  onSwapTimeline,
  selectionManagerRef,
  onClickProfile,
  onClickAsset,
  onClickTask,
}) => {

  const cancelDateRangeDrag = React.useRef<(() => void) | null>(null);
  const hoveredLaneId = React.useRef<string | null>(null);
  const hoveredRangeId = React.useRef<string | null>(null);
  const lastMouseDownId = React.useRef<string | null>(null);

  const { setLastDraggedId } = usePhaseTimelineSelection();
  const setLastDraggedIdRef = React.useRef(setLastDraggedId);
  setLastDraggedIdRef.current = setLastDraggedId;

  const phasesById = React.useMemo(() => {
    return phases?.reduce((acc, phase) => {
      acc[phase.id] = phase;
      return acc;
    }, {} as Record<string, Phase>);
  }, [phases]);

  const profilesById = React.useMemo(() => {
    return profiles?.reduce((acc, profile) => {
      acc[profile.id] = profile;
      return acc;
    }, {} as Record<string, UserProfile>);
  }, [profiles]);

  const tasksById = React.useMemo(() => {
    return tasks?.reduce((acc, task) => {
      acc[task.id] = task;
      return acc;
    }, {} as Record<string, Task>);
  }, [tasks]);

  const { items, rowsById, ancestorsById, setOptimisticUpdates, setPreviewUpdates } = usePhaseTimeline(
    phases,
    profilesByPhase,
    tasksByPhase,
    tasksByProfile,
    profilesByTask,
    showCompletedTasks,
  );
  

  //const scrollRef = useStickyScroll({ key: "phase-timeline-scroll" });
  const [isCollapsed, setCollapsed] = useStickyCollapse({ key: "phase-timeline-collapsed" });
  const [openMoreOptions, setOpenMoreOptions] = React.useState<string | null>(null);

  const [selectedIds, setSelectedIds] = React.useState<Set<string>>(new Set());

  function setSelected(ids: string[]) {
    setSelectedIds(new Set(ids));
  }

  const getPhaseUpdates = React.useCallback((originalStart: Date, originalEnd: Date, start: Date, end: Date) => {
    const selectedPhases = Array.from(selectedIds).map(id => phasesById[id]).filter(Boolean) as Phase[];
    const newEstimate = end.getTime() - start.getTime();
    const estimateChange = newEstimate - (originalEnd.getTime() - originalStart.getTime());
    const deadlineChange = end.getTime() - (originalEnd.getTime() || end.getTime());

    const updatedPhases = selectedPhases.map(phase => {
      const oldEstimate = phase.data.estimate || 0;
      const estimate = Math.max(oldEstimate + estimateChange, 86400000);
      const oldDeadline = new Date(phase.data.deadline || new Date()).getTime();
      const deadline = oldDeadline + deadlineChange;
      return {
        id: phase.id,
        estimate,
        deadline,
      };
    });
    return updatedPhases;

  }, [phasesById, selectedIds]);

  const getTaskUpdates = React.useCallback((originalStart: Date, originalEnd: Date, start: Date, end: Date) => {
    const selectedTasks = Array.from(selectedIds).map(id => tasksById[id]).filter(Boolean) as Task[];
    const newEstimate = end.getTime() - start.getTime();
    const estimateChange = newEstimate - (originalEnd.getTime() - originalStart.getTime());
    const deadlineChange = end.getTime() - (originalEnd.getTime() || end.getTime());

    const updatedTasks = selectedTasks.map(task => {
      const oldEstimate = task.data.estimate || 0;
      const estimate = Math.max(oldEstimate + estimateChange, 86400000);
      const oldDeadline = new Date(task.data.deadline || new Date()).getTime();
      const deadline = oldDeadline + deadlineChange;
      return {
        id: task.id,
        estimate,
        deadline,
      };
    });
    return updatedTasks;
  }, [tasksById, selectedIds]);

  const onPreviewUpdateRef = React.useRef<((initialStart: Date, initialEnd: Date, start: Date, end: Date) => void) | null>(null);
  onPreviewUpdateRef.current = React.useCallback((initialStart: Date, initialEnd: Date, start: Date, end: Date) => {
    const taskUpdates = getTaskUpdates(initialStart, initialEnd, start, end);
    const phaseUpdates = getPhaseUpdates(initialStart, initialEnd, start, end);
    setPreviewUpdates({
      ...Object.fromEntries(taskUpdates.map(update => [update.id, update])),
      ...Object.fromEntries(phaseUpdates.map(update => [update.id, update])),
    });
  }, [getTaskUpdates, getPhaseUpdates, setPreviewUpdates]);

  const onUpdateRef = React.useRef<((id: string, initialStart: Date, initialEnd: Date, start: Date, end: Date) => void) | null>(null);
  onUpdateRef.current = React.useCallback((id: string, initialStart: Date, initialEnd: Date, start: Date, end: Date) => {
    setLastDraggedId(id);
    const taskUpdates = getTaskUpdates(initialStart, initialEnd, start, end);
    const phaseUpdates = getPhaseUpdates(initialStart, initialEnd, start, end);
    setOptimisticUpdates({
      ...Object.fromEntries(taskUpdates.map(update => [update.id, update])),
      ...Object.fromEntries(phaseUpdates.map(update => [update.id, update])),
    });
    onSchedulePhasesAndTasks?.({
      taskUpdates: taskUpdates,
      phaseUpdates: phaseUpdates,
    });
  }, [getTaskUpdates, getPhaseUpdates, setOptimisticUpdates, onSchedulePhasesAndTasks, setLastDraggedId]);

  const onClickProfileRef = React.useRef<typeof onClickProfile | null>(onClickProfile || null);
  onClickProfileRef.current = onClickProfile;
  const onClickAssetRef = React.useRef<typeof onClickAsset | null>(onClickAsset || null);
  onClickAssetRef.current = onClickAsset;
  const onClickTaskRef = React.useRef<typeof onClickTask | null>(onClickTask || null);
  onClickTaskRef.current = onClickTask;

  const { render } = useDogs<PhaseTimelineRow>({
    items,
    externalDataKey,
    allowInsertExternal: React.useCallback<AllowInsertExternalFn>(({ insertedItems, index, parentId, externalDataKey }) => {
      if (lastMouseDownId.current && lastMouseDownId.current === hoveredRangeId.current && hoveredRangeId.current === hoveredLaneId.current) {
        //console.log("allowInsertExternal", { insertedItems, index, parentId, externalDataKey });
        return false;
      }
      else {
        //console.log("allowInsertExternal", { insertedItems, index, parentId, externalDataKey });
      }
      return typeof allowInsertExternal === 'function' ? allowInsertExternal({ insertedItems, index, parentId, externalDataKey }) : allowInsertExternal === externalDataKey;
    }, [allowInsertExternal, hoveredRangeId, hoveredLaneId]),
    allowInsert: React.useCallback<AllowInsertFn<PhaseTimelineRow>>(({ insertedItems, index, parentId }) => {
      if (lastMouseDownId.current && lastMouseDownId.current === hoveredRangeId.current && hoveredRangeId.current === hoveredLaneId.current) {
        //console.log("allowInsert", { insertedItems, index, parentId });
        return false;
      }
      else {
        //console.log("allowInsert", { insertedItems, index, parentId });
      }
      return true;
    }, [hoveredRangeId, hoveredLaneId]),
    enableOrdering: editable,
    selectionMode: 'multi',
    debugId: "phase-timeline",
    selectionManagerRef,

    insertItems(insertedItems, index, parentId) {
      const rows = insertedItems.map(x => rowsById[x.__id]).filter(Boolean) as PhaseTimelineRow[];
      const parentRow = parentId ? rowsById[parentId] ?? null : null;
      const ancestorRows = parentRow ? [parentRow, ...(parentId ? ancestorsById[parentId]?.map(x => rowsById[x]).filter(Boolean) as PhaseTimelineRow[] : [])] : [];
      if (insertRows) {
        insertRows({ insertedRows: rows, index, parentRow, ancestorRows });
      }
    },

    insertExternalItems(insertedItems, index, parentId, key) {
      const parentRow = parentId ? rowsById[parentId] ?? null : null;
      const ancestorRows = parentRow ? [parentRow, ...(parentId ? ancestorsById[parentId]?.map(x => rowsById[x]).filter(Boolean) as PhaseTimelineRow[] : [])] : [];
      if (insertExternalItems) {
        insertExternalItems({ insertedRows: insertedItems, index, parentRow, ancestorRows, key });
      }
    },

    onSelectionChanged: (ids) => {
      setSelected(Array.from(ids));
    },

    groupDeadZone: React.useCallback<GroupDeadZoneFn<PhaseTimelineRow>>(({ group }) => {
      if (group.data.rowType === 'phase' || group.data.rowType === 'profile' || group.data.rowType === 'unassigned') {
        return "1px";
      }
    }, []),

  });

  const toggleCollapse = React.useCallback((row: IterateGroup<PhaseTimelineRow>) => {
    setCollapsed(row.id, !isCollapsed(row.id, row.itemType === 'group' && (row.group.data.rowType === 'profile' || row.group.data.rowType === 'unassigned') ? true : undefined));
  }, [setCollapsed, isCollapsed]);

  const [isMultiSelecting, setIsMultiSelecting] = React.useState(false);
  React.useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      if (e.ctrlKey || e.metaKey || e.shiftKey) {
        setIsMultiSelecting(true);
      }
      else {
        setIsMultiSelecting(false);
      }
    };
    const onKeyUp = (e: KeyboardEvent) => {
      setIsMultiSelecting(false);
    };
    document.addEventListener("keydown", onKeyDown);
    document.addEventListener("keyup", onKeyUp);
    return () => {
      document.removeEventListener("keydown", onKeyDown);
      document.removeEventListener("keyup", onKeyUp);
    }
  }, []);

  const renderTimelineRow = (row: IterateChild<PhaseTimelineRow> | IterateGroup<PhaseTimelineRow>, inSidebar: boolean, children?: React.ReactNode, parentRows?: IterateGroup<PhaseTimelineRow>[]) => {
    
    // @ts-ignore
    const isGroupCollapsed = isCollapsed(row.id, row.itemType === 'group' && (row.group.data.rowType === 'profile' || row.group.data.rowType === 'unassigned') ? true : undefined);

    // Phase
    if (row.itemType === 'group' && row.group.data.rowType === 'phase') {

      const phase = phasesById[row.group.data.id];
      const canEditPhase = getCanEditPhase?.(phase);
      const canDeletePhase = getCanDeletePhase?.(phase);

      const optionMenuItems = [] as React.ReactNode[];

      if (canEditPhase) {
        optionMenuItems.push(
          <DropdownMenuItem className="items-center flex flex-row gap-x-2" onClick={() => setPhaseToRename(phase || null)}>
            <PencilIcon width={16} height={16} />
            <span className="text-xs font-medium">Rename</span>
          </DropdownMenuItem>
        );
      }

      if (canDeletePhase) {
        optionMenuItems.push(
          <DropdownMenuItem className="items-center flex flex-row gap-x-2" onClick={() => setPhaseToDelete(phase || null)}>
            <TrashIcon width={16} height={16} className="text-destructive" />
            <span className="text-destructive text-xs font-medium">Delete</span>
          </DropdownMenuItem>
        );
      }

      const moreOptions = optionMenuItems.length > 0 ? (
        <DropdownMenu open={openMoreOptions === row.id} onOpenChange={open => setOpenMoreOptions(open ? row.id : null)}>
          <DropdownMenuTrigger onClick={e => e.stopPropagation()}>
            <MoreHorizontalIcon width={16} height={16} className="group-hover/title:opacity-100 opacity-0" />
          </DropdownMenuTrigger>
          <DropdownMenuContent onCloseAutoFocus={e => e.preventDefault()} onClick={e => e.stopPropagation()}>
            {optionMenuItems}
          </DropdownMenuContent>
        </DropdownMenu>
      ) : null;

      return (
        <React.Fragment key={row.id}>
          {inSidebar ? (

            <TimelineTitle
              rowId={row.id}
              ref={row.ref}
              onClick={() => toggleCollapse(row)}
              className={cn(
                "h-[32px] flex flex-row gap-x-[2px] items-center group/title",
                "text-base font-semibold",

                // Cursors
                "cursor-pointer select-none",

                // Turn off row hover while dragging
                row.isDragging ? "data-[row-hover=true]:bg-transparent" : "",

                row.isSelected ? "bg-[#202020]" : "",
              )}
            >
              <ChevronDownIcon
                dogs-selection-enabled="false"
                width={16}
                height={16}
                onClick={e => { e.stopPropagation(); toggleCollapse(row) }}
                className={cn("transition-transform duration-100", isGroupCollapsed ? "-rotate-90" : "")}
              />
              {row.group.data.title}
              <AddUserButton
                disabled={!canEditPhase}
                users={profiles?.filter(x => !profilesByPhase?.[row.group.data.id]?.includes(x))}
                onAddUser={async (user) => {
                  if (!canEditPhase || !phase) return;
                  await onAddUserToPhase?.(phase, user);
                }}
              />
              <div className="flex-1" />
              {moreOptions}
            </TimelineTitle>

          ) : (

            <TimelineLane
              rowId={row.id}
              className={cn(
                "h-[32px]",

                // Turn off row hover while dragging
                row.isDragging ? "data-[row-hover=true]:bg-transparent" : "",

                row.isSelected ? "bg-[#202020]" : "",
              )}
            >
              <TimelineDateRange
                ref={row.ref}
                start={new Date(row.group.data.end.getTime() - row.group.data.duration)}
                end={row.group.data.end}
                style={{ opacity: 1 - (0.33 * (row.depth))}}
                className={cn(
                  "h-[22px]",
                  row.isSelected ? "ring-[0.5px] ring-white ring-offset-[2px] ring-offset-black/50" : ""
                )}
               
                onMouseDown={e => setLastDraggedIdRef.current(null)}

                onPreviewUpdate={onPreviewUpdateRef.current || undefined}
                onUpdate={(...args) => onUpdateRef.current?.(row.id, ...args)}

              />
            </TimelineLane>

          )}
          {!isGroupCollapsed
            ? children
            : null
          }
        </React.Fragment>
      );

    // Profile
    } else if (row.itemType === 'group' && row.group.data.rowType === 'profile') {

      const parentPhase = phasesById?.[row.group.data.parent_phase_id] || null;
      const profile = profilesById?.[row.group.data.id] || null;
      const canEditParentPhase = getCanEditPhase?.(parentPhase);

      const moreOptions = canEditParentPhase ?
        <DropdownMenu open={openMoreOptions === row.id} onOpenChange={open => setOpenMoreOptions(open ? row.id : null)}>
          <DropdownMenuTrigger onClick={e => e.stopPropagation()}>
            <MoreHorizontalIcon width={16} height={16} className="group-hover/title:opacity-100 opacity-0" />
          </DropdownMenuTrigger>
          <DropdownMenuContent onCloseAutoFocus={e => e.preventDefault()} onClick={e => e.stopPropagation()}>
            <DropdownMenuItem className="items-center flex flex-row gap-x-2" onClick={() => {
              if (!profile || !parentPhase) return;
              onRemoveUserFromPhase?.(profile, parentPhase);
            }}>
              <TrashIcon width={16} height={16} className="text-destructive" />
              <span className="text-destructive text-xs font-medium">Remove</span>
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu> : null;

      return (
        <React.Fragment key={row.id}>
          {inSidebar ? (

            <TimelineTitle
              rowId={row.id}
              ref={row.ref}
              dogs-dragging-enabled="false"
              dogs-selection-enabled="false"
              className={cn(
                "h-[32px] flex flex-row gap-x-[4px] items-center pl-[24px] group/title",

                // Cursors
                "cursor-pointer select-none",

                // Typography
                "text-medium font-semibold select-none",
                
                // Border
                "border-t-[1px] border-l-[1px] border-b-[1px]",

                // Turn off row hover while dragging
                row.isDragging ? "data-[row-hover=true]:bg-transparent" : "",

                // Is dropping within
                !(row.isEmpty || isGroupCollapsed) && (row.isDroppingOnThis || row.isDroppingWithin)
                  ? "border-t-primary border-l-primary border-b-transparent rounded-tl-[4px]"
                  : (row.isEmpty || isGroupCollapsed) && row.isDroppingOnThis
                    ? "border-t-primary border-l-primary border-b-primary rounded-l-[4px]"
                    : "border-t-transparent border-l-transparent border-b-transparent",

              )}
              onClick={() => toggleCollapse(row)}
            >
              <ChevronDownIcon
                width={16}
                height={16}
                className={cn("transition-transform duration-100", isGroupCollapsed ? "-rotate-90" : "")}
              />
              <Avatar 
                src={row.group.data.image_url}
                size={20}
                className="rounded-full"
              />
              {row.group.data.title}
              {onClickProfileRef.current ? (
                <Button
                  data-clickable={!!onClickProfileRef.current}
                variant="ghost"
                size="icon"
                className="w-[24px] h-[24px] p-0 opacity-0 group-hover/title:opacity-100 data-[clickable=false]:opacity-0"
                onClick={e => {
                  e.stopPropagation();
                  onClickProfileRef.current?.(profile);
                }}
              >
                  <ArrowRightIcon width={16} height={16} />
                </Button>
              ) : <div className="w-[24px] h-[24px]" />}
              <div className="flex-1" />
              {moreOptions}
            </TimelineTitle>
        
          ) : (

            <TimelineLane
              rowId={row.id}
              dogs-dragging-enabled="false"
              dogs-selection-enabled="false"
              ref={row.ref}
              className={cn(
                "h-[32px]",

                // Border
                "border-t-[1px] border-l-[1px] border-b-[1px]",

                // Turn off row hover while dragging
                row.isDragging ? "data-[row-hover=true]:bg-transparent" : "",

                // Is dropping within
                !(row.isEmpty || isGroupCollapsed) && (row.isDroppingOnThis || row.isDroppingWithin)
                  ? "border-t-primary border-l-primary border-b-transparent"
                  : (row.isEmpty || isGroupCollapsed) && row.isDroppingOnThis
                    ? "border-t-primary border-l-primary border-b-primary"
                    : "border-t-transparent border-l-transparent border-b-transparent",

              )}
            >
              <TimelineDateRange
                start={new Date(row.group.data.end.getTime() - row.group.data.duration)}
                end={row.group.data.end}
                className="h-[18px] bg-[#868688]"
              />
            </TimelineLane>

          )}
          {!isGroupCollapsed
            ? children
            : null
          }
        </React.Fragment>
      );

    // Task
    } else if (row.itemType === 'child' && row.item.data.rowType === 'task') {

      const task = tasksById?.[row.item.data.id] || null;
      const parentPhase = phasesById?.[row.item.data.parent_phase_id] || null;
      const canEditParentPhase = getCanEditPhase?.(parentPhase);

      const moreOptions = canEditParentPhase ?
        <DropdownMenu
          key={`dropdown-${row.id}`}
          open={openMoreOptions === row.id}
          onOpenChange={open => {
            setOpenMoreOptions(open ? row.id : null);
          }}
        >
          <DropdownMenuTrigger
            dogs-selection-enabled="false"
            onClick={e => e.stopPropagation()}
          >
            <MoreHorizontalIcon width={16} height={16} className="group-hover/title:opacity-100 opacity-0" />
          </DropdownMenuTrigger>
          <DropdownMenuContent
            onCloseAutoFocus={e => e.preventDefault()}
            onClick={e => e.stopPropagation()}
          >
            <DropdownMenuSub>
              <DropdownMenuSubTrigger>
                <span className="text-xs font-medium">Move To</span>
              </DropdownMenuSubTrigger>
              <DropdownMenuSubContent>
                {phases?.map(phase => (
                  <DropdownMenuSub key={phase.id}>
                    <DropdownMenuSubTrigger className="text-xs font-medium">{phase.data.name}</DropdownMenuSubTrigger>
                    <DropdownMenuSubContent>
                      {profilesByPhase?.[phase.id]?.map(profile => (
                        <DropdownMenuItem
                          key={profile.id}
                          className="flex flex-row gap-x-2 text-xs font-medium"
                          onClick={e => {
                            if (!getCanEditPhase?.(phase)) return;
                            const selectedTasks = Array.from(selectedIds).map(id => tasksById[id]).filter(Boolean) as Task[];
                            if (!selectedIds.has(row.id)) {
                              if (task) {
                                onMoveTasksToUser?.([task], phase, profile);
                              }
                            } else {
                              onMoveTasksToUser?.(selectedTasks, phase, profile);
                            }
                          }}
                        >
                          <Avatar 
                            src={profile.data.image_url}
                            size={15}
                            className="rounded-full"
                          />
                          <span dogs-selection-enabled="false">{getProfileName(profile)}</span>
                        </DropdownMenuItem>
                      ))}
                      <DropdownMenuItem
                        key="unassigned"
                        className="flex flex-row gap-x-2 text-xs font-medium"
                        onClick={e => {
                          if (!getCanEditPhase?.(phase)) return;
                          const selectedTasks = Array.from(selectedIds).map(id => tasksById[id]).filter(Boolean) as Task[];
                          if (!selectedIds.has(row.id)) {
                            if (task) {
                              onMoveTasksToUser?.([task], phase, null);
                            }
                          } else {
                            onMoveTasksToUser?.(selectedTasks, phase, null);
                          }
                        }}
                      >
                        <Avatar 
                          src={""}
                          size={15}
                          className="rounded-full"
                        />
                        <span>Unassigned</span>
                      </DropdownMenuItem>
                    </DropdownMenuSubContent>
                  </DropdownMenuSub>
                ))}
              </DropdownMenuSubContent>
            </DropdownMenuSub>
            <DropdownMenuItem className="items-center flex flex-row gap-x-2" onClick={e => {
              if (!parentPhase) return;
              if (!getCanEditPhase?.(parentPhase)) return;
              const selectedTasks = Array.from(selectedIds).map(id => tasksById[id]).filter(Boolean) as Task[];
              if (!selectedIds.has(row.id)) {
                if (task) {
                  onRemoveTasksFromPhase?.([{tasks: [task], phase: parentPhase}]).then(() => {
                    row.setSelected(false, e.target, true);
                  });
                }
              } else {
                onRemoveTasksFromPhase?.([{tasks: selectedTasks, phase: parentPhase}]).then(() => {
                  row.setSelected(false, e.target, true);
                });
              }
            }}>
              <TrashIcon width={16} height={16} className="text-destructive" />
              <span className="text-destructive text-xs font-medium">Remove</span>
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu> : null;

      return inSidebar ? (

        <TimelineTitle
          ref={row.ref}
          dogs-dragging-enabled="true"
          dogs-selection-enabled="true"
          key={`title-${row.id}`}
          rowId={row.id}
          className={cn(
            "h-[32px] text-sm pl-[48px] flex items-center group/title",
            "text-xs font-semibold select-none",

            canEditParentPhase ? "cursor-grab" : "cursor-auto",

            // Border
            "border-l-[1px] border-b-[1px]",

            // Turn off row hover while dragging
            row.isDropping ? "data-[row-hover=true]:bg-transparent" : "",

            row.isSelected && !(row.isDraggingThis || row.isDropping) ? "bg-[#202020]" : "",
            row.isDraggingThis && row.isDropping ? "bg-[#202020]/50" : "",

            row.isDroppingWithinParent ? "border-l-primary" : "border-l-transparent",
            row.isLastSibling && row.isDroppingWithinParent ? "border-b-primary rounded-bl-[4px]" : "border-b-transparent",
          )}
        >
          <div key={`title-container-${row.id}`} className="flex flex-row min-w-[225px] max-w-[225px] overflow-hidden gap-x-[4px] mr-[4px] h-full items-center">
          <TextOverflowTooltip
            key={`tooltip-${row.id}-1`}
            tooltipText={`${row.item.data.title}`}
          >
            <div
              className="flex-1 min-w-0 flex h-full items-center"
            >
              <a
                dogs-selection-enabled={!!onClickTaskRef.current && !isMultiSelecting ? "false" : "true"}
                className="break-all truncate data-[clickable=true]:cursor-pointer"
                data-clickable={!!onClickTaskRef.current && !isMultiSelecting}
                onClick={e => { if (isMultiSelecting) return; e.stopPropagation(); onClickTaskRef.current?.(task) }}
              >
                {row.item.data.title}
              </a>
            </div>
          </TextOverflowTooltip>
          <TextOverflowTooltip
            key={`tooltip-${row.id}-2`}
            tooltipText={assetByTask?.[row.item.data.id]?.data.name || ""}
          >
            <div
              className="flex-1 min-w-0 truncate flex h-full items-center"
            >
              <a
                dogs-selection-enabled={!!onClickTaskRef.current && !isMultiSelecting ? "false" : "true"}
                className="break-all truncate data-[clickable=true]:cursor-pointer"
                data-clickable={!!onClickTaskRef.current && !isMultiSelecting}
                onClick={e => { if (isMultiSelecting) return; e.stopPropagation(); onClickTaskRef.current?.(task) }}
              >
                {assetByTask?.[row.item.data.id]?.data.name || ""}
              </a>
            </div>
          </TextOverflowTooltip>
          </div>
          {moreOptions}
        </TimelineTitle>

      ) : (

        <TimelineLane
          ref={row.ref}
          dogs-dragging-enabled="false"
          dogs-selection-enabled="false"
          key={`lane-${row.id}`}
          rowId={row.id}
          onMouseEnter={() => {
            hoveredLaneId.current = row.id;
          }}
          onMouseLeave={() => {
            if (hoveredLaneId.current === row.id) {
              hoveredLaneId.current = null;
              cancelDateRangeDrag.current?.();
            }
          }}
          className={cn(
            "h-[32px]",

            // Border
            "border-l-[1px] border-b-[1px]",

            // Turn off row hover while dragging
            row.isDropping ? "data-[row-hover=true]:bg-transparent" : "",

            row.isSelected && !(row.isDraggingThis || row.isDropping) ? "bg-[#202020]" : "",
            row.isDraggingThis && row.isDropping ? "bg-[#202020]/50" : "",

            row.isDroppingWithinParent ? "border-l-primary" : "border-l-transparent",
            row.isLastSibling && row.isDroppingWithinParent ? "border-b-primary" : "border-b-transparent",
          )}
        >
          <TimelineDateRange
            onMouseEnter={() => hoveredRangeId.current = row.id}
            onMouseLeave={() => { if (hoveredRangeId.current === row.id) hoveredRangeId.current = null }}
            onCustomDragStart={(e, cancel) => { cancelDateRangeDrag.current = cancel }}
            onCustomDragEnd={(e) => { cancelDateRangeDrag.current = null }}
            dogs-dragging-enabled="true"
            dogs-selection-enabled="true"
            start={new Date(row.item.data.end.getTime() - row.item.data.duration)}
            end={row.item.data.end}
            className={cn(
              TASK_STYLES[row.item.data.status || TaskStatus.NotStarted],
              row.isSelected ? "ring-[0.5px] ring-white ring-offset-[1px] ring-offset-black/50" : "",
              row.isDraggingThis && row.isDropping ? "opacity-50" : "",
            )}

            onMouseDown={e => {
              setLastDraggedIdRef.current(null);
              lastMouseDownId.current = row.id;
            }}

            onPreviewUpdate={onPreviewUpdateRef.current || undefined}
            onUpdate={(...args) => onUpdateRef.current?.(row.id, ...args)}

          />
        </TimelineLane>

      );
    } else if (row.itemType === 'group' && row.group.data.rowType === 'unassigned') {

      return (
        <React.Fragment key={row.id}>
          {inSidebar ? (
            <TimelineTitle
              ref={row.ref}
              rowId={row.id}
              dogs-dragging-enabled="false"
              dogs-selection-enabled="false"
              className={cn(
                "h-[32px] flex flex-row gap-x-[4px] items-center pl-[24px] group/title",
                "text-medium font-semibold",

                // Cursor
                "select-none cursor-pointer",

                // Border
                "border-t-[1px] border-l-[1px] border-b-[1px]",

                // Turn off row hover while dragging
                row.isDroppingOnThis ? "data-[row-hover=true]:bg-transparent" : "",

                // Is dropping within
                !(row.isEmpty || isGroupCollapsed) && (row.isDroppingOnThis || row.isDroppingWithin)
                  ? "border-t-primary border-l-primary border-b-transparent rounded-tl-[4px]"
                  : (row.isEmpty || isGroupCollapsed) && row.isDroppingOnThis
                    ? "border-t-primary border-l-primary border-b-primary rounded-l-[4px]"
                    : "border-t-transparent border-l-transparent border-b-transparent",

              )}
              onClick={() => toggleCollapse(row)}
            >
              <ChevronDownIcon
                width={16}
                height={16}
                className={cn("transition-transform duration-100", isGroupCollapsed ? "-rotate-90" : "")}
              />
              <Avatar 
                src={""}
                size={20}
                className="rounded-full"
              />
              {row.group.data.title}
            </TimelineTitle>
          ) : (
            <TimelineLane
              rowId={row.id}
              ref={row.ref}
              dogs-dragging-enabled="false"
              dogs-selection-enabled="false"
              className={cn(
                "h-[32px]",

                // Border
                "border-t-[1px] border-l-[1px] border-b-[1px]",

                // Turn off row hover while dragging
                row.isDroppingOnThis ? "data-[row-hover=true]:bg-transparent" : "",

                // Is dropping within
                !(row.isEmpty || isGroupCollapsed) && (row.isDroppingOnThis || row.isDroppingWithin)
                  ? "border-t-primary border-l-primary border-b-transparent"
                  : (row.isEmpty || isGroupCollapsed) && row.isDroppingOnThis
                    ? "border-t-primary border-l-primary border-b-primary"
                    : "border-t-transparent border-l-transparent border-b-transparent",

              )}
            />
          )}
          {!isGroupCollapsed
            ? children
            : null
          }
        </React.Fragment>
      );
    }
  };

  const [isOpenCreatePhaseModal, setIsOpenCreatePhaseModal] = React.useState(false);
  const [phaseToRename, setPhaseToRename] = React.useState<Phase | null>(null);
  const [phaseToDelete, setPhaseToDelete] = React.useState<Phase | null>(null);
  const modals = <>
    <CreatePhaseModal
      open={isOpenCreatePhaseModal}
      onClose={() => setIsOpenCreatePhaseModal(false)}
      canCreatePhase={canCreatePhase}
      onCreatePhase={onCreatePhase}
    />
    <RenamePhaseModal
      open={!!phaseToRename}
      onClose={() => setPhaseToRename(null)}
      phase={phaseToRename}
      disabled={!getCanEditPhase(phaseToRename)}
      onRename={async newName => phaseToRename ? await onRenamePhase?.({id: phaseToRename.id, name: newName}) : undefined}
    />
    <DeletePhaseModal
      open={!!phaseToDelete}
      onClose={() => setPhaseToDelete(null)}
      disabled={!getCanDeletePhase(phaseToDelete)}
      onDelete={async () => phaseToDelete ? await onDeletePhase?.({id: phaseToDelete.id}) : undefined}
    />
  </>

  return <>
    {modals}
    <TooltipProvider>
        <TimelineRoot className={className} persistence={{ key: "phase-timeline", storage: "local" }}>
          <TimelineHeader>
            <TimelineCorner className="h-full flex flex-row items-center justify-end p-2">
              <SwapButton
                variant="outline"
                className="h-[32px] px-[8px]"
                options={[
                  { id: "phase", label: "Phase", icon: <UsersIcon className="w-[16px] h-[16px]" />, onClick: () => onSwapTimeline?.("phase") },
                  { id: "category", label: "Category", icon: <FolderIcon className="w-[16px] h-[16px]" />, onClick: () => onSwapTimeline?.("category") },
                ]}
                currentId="phase"
                iconOnly
              />
              <div className="flex-1" />
              {canCreatePhase && <Button variant="cta" onClick={() => setIsOpenCreatePhaseModal(true)} size="sm">New</Button>}
            </TimelineCorner>
          </TimelineHeader>
          <TimelineBody>
            {render({
              root({ children, ...item }) {
                return <TimelineSidebar ref={item.ref} className="min-w-[303px]">{children}</TimelineSidebar>
              },
              group(item) {
                return renderTimelineRow(item, true, item.children);
              },
              child(item) {
                return renderTimelineRow(item, true);
              },
              dragImage({}) {
                return null;
              },
              groupRenderDeps(item) {
                // Common group deps
                const isGroupCollapsed = isCollapsed(item.id, item.itemType === 'group' && (item.group.data.rowType === 'profile' || item.group.data.rowType === 'unassigned') ? true : undefined);
                let common = [
                  item.id,
                  item.depth,
                  item.isEmpty,
                  //item.isDragging,
                  item.isDroppingOnThis,
                  item.isDroppingWithin,
                  item.isSelected,
                  item.isHovering,
                  item.group.__children.length,
                  item.group.data.updated_at,
                  isGroupCollapsed,
                  toggleCollapse,
                  // profiles,
                  // profilesByPhase,
                  openMoreOptions === item.id,
                  setOpenMoreOptions,
                ];

                if (item.group.data.rowType === 'phase') {
                  const phase = phasesById[item.group.data.id];
                  return [
                    ...common,
                    getCanEditPhase?.(phase),
                    getCanDeletePhase?.(phase),
                    onAddUserToPhase,
                    onSchedulePhasesAndTasks,
                  ]
                }

                if (item.group.data.rowType === 'profile') {
                  return [
                    ...common,
                    item.group.data.image_url,
                    //profilesById,
                    //phasesById,
                    onRemoveUserFromPhase,
                    getCanEditPhase,
                  ]
                }

                return common;
              },
              childRenderDeps(item) {
                return [
                  item.id,
                  item.depth,
                  item.isHovering,
                  //item.isDragging,
                  item.isDraggingThis,
                  item.isDroppingOnThis,
                  item.isDropping,
                  item.isLastSibling,
                  item.isDroppingWithinParent,
                  item.isSelected,
                  item.item.data.updated_at,
                  assetByTask?.[item.item.data.id]?.data.updated_at,
                  tasksById?.[item.item.data.id]?.data.updated_at,
                  openMoreOptions === item.id,
                  setOpenMoreOptions,
                  onMoveTasksToUser,
                  onRemoveTasksFromPhase,
                  onSchedulePhasesAndTasks,
                  item.isHovering && isMultiSelecting,
                ];
              },
            })}
            {render({
              root({ children, ...item }) {
                return <TimelineContent ref={item.ref}>{children}</TimelineContent>
              },
              group(item) {
                return renderTimelineRow(item, false, item.children);
              },
              child(item) {
                return renderTimelineRow(item, false);
              },
              groupRenderDeps(item) {
                // Common group deps
                const isGroupCollapsed = isCollapsed(item.id, item.itemType === 'group' && (item.group.data.rowType === 'profile' || item.group.data.rowType === 'unassigned') ? true : undefined);
                let common = [
                  item.id,
                  item.depth,
                  item.isEmpty,
                  //item.isDragging,
                  item.isDroppingOnThis,
                  item.isDroppingWithin,
                  item.isSelected,
                  item.isHovering,
                  item.group.__children.length,
                  item.group.data.updated_at,
                  isGroupCollapsed,
                  toggleCollapse,
                  // profiles,
                  // profilesByPhase,
                  openMoreOptions === item.id,
                  setOpenMoreOptions,
                ];

                if (item.group.data.rowType === 'phase') {
                  const phase = phasesById[item.group.data.id];
                  return [
                    ...common,
                    getCanEditPhase?.(phase),
                    getCanDeletePhase?.(phase),
                    onAddUserToPhase,
                    onSchedulePhasesAndTasks,
                  ]
                }

                if (item.group.data.rowType === 'profile') {
                  const parentPhase = phasesById?.[item.group.data.parent_phase_id] || null;
                  return [
                    ...common,
                    item.group.data.image_url,
                    //profilesById,
                    //phasesById,
                    onRemoveUserFromPhase,
                    getCanEditPhase?.(parentPhase),
                  ]
                }

                return common;
              },
              childRenderDeps(item) {
                return [
                  item.id,
                  item.depth,
                  //item.isDragging,
                  item.isDraggingThis,
                  item.isDroppingOnThis,
                  item.isDropping,
                  item.isLastSibling,
                  item.isDroppingWithinParent,
                  item.isSelected,
                  item.isHovering,
                  item.item.data.updated_at,
                  assetByTask?.[item.item.data.id]?.data.updated_at,
                  openMoreOptions === item.id,
                  setOpenMoreOptions,
                  onMoveTasksToUser,
                  onRemoveTasksFromPhase,
                  onSchedulePhasesAndTasks,
                ];
              },
            })}
        </TimelineBody>
        <TimelineZoom />
      </TimelineRoot>
    </TooltipProvider>
  </>
};

