"use client";

import React from "react";

import { DogsSelectionChangeEvent } from "@palette.tools/react.dogs";



export const useCategoryTimelineSelection = () => {
  const isSelectionBlocked = React.useRef(false);
  const selectedIdToIgnore = React.useRef<string | null>(null);

  React.useEffect(() => {
    const handleSelectionChange = (e: DogsSelectionChangeEvent) => {

      if (isSelectionBlocked.current) {
        e.preventDefault();
        return;
      }

      if (e.detail.originalEvent?.type !== "mouseup") {
        return;
      };

      if (selectedIdToIgnore.current && e.detail.oldSelection.has(selectedIdToIgnore.current)) {
        selectedIdToIgnore.current = null;
        e.preventDefault();
        return;
      }

    };

    document.addEventListener('ondogsselectionchange', handleSelectionChange as EventListener);

    return () => {
      document.removeEventListener('ondogsselectionchange', handleSelectionChange as EventListener);
    };
  }, []);

  return {
    isSelectionBlocked,
    blockSelection: React.useCallback((blocked: boolean) => isSelectionBlocked.current = blocked, []),
    selectedIdToIgnore,
    ignoreNextDeselection: React.useCallback((id: string | null) => selectedIdToIgnore.current = id, []),
  };

}
