"use client";

import React from "react";
import { TimelineRoot, TimelineHeader, TimelineBody, TimelineTitle, TimelineLane, TimelineDateRange, TimelineSidebar, TimelineContent, useStickyCollapse, TimelineCorner, TooltipProvider, Button, TimelineZoom } from "@palette.tools/react";
import { IterateGroup, IterateRoot, RenderableGroup, RenderableRoot, SelectionManager, useDogs } from "@palette.tools/react.dogs";
import { CategoryRow, ProjectRow, ProjectTimelineCallbacks, ProjectTimelineModel, ProjectTimelineRow, useProjectTimeline } from "./model";
import { cn } from "@/lib/utils";
import { ChevronDownIcon } from "@radix-ui/react-icons";
import { Emoji } from "emoji-picker-react";
import { EmojiDefaultAssetCategory } from "@palette.tools/utils/src/constants";
import { useProjectTimelineSelection } from "./selection";
import { ArrowRightIcon } from "lucide-react";


const TitleRoot = ({ item }: { item: RenderableRoot<ProjectTimelineRow> }) => {
  return <TimelineSidebar ref={item.ref} className="min-w-[276px]">
    {item.children}
  </TimelineSidebar>;
}
TitleRoot.deps = (item: IterateRoot<ProjectTimelineRow>) => {
  return [item.id];
}


const LaneRoot = ({ item }: { item: RenderableRoot<ProjectTimelineRow> }) => {
  return <TimelineContent ref={item.ref}>
    {item.children}
  </TimelineContent>;
}
LaneRoot.deps = (item: IterateRoot<ProjectTimelineRow>) => {
  return [item.id];
}


// Project


const ProjectTitle = ({ item, isCollapsed, toggleCollapsed, onClickTitleNav }: { item: RenderableGroup<ProjectRow>, isCollapsed: boolean, toggleCollapsed: () => void, onClickTitleNav?: () => void}) => {

  return <React.Fragment key={item.id}>
    <TimelineTitle
      ref={item.ref}
      rowId={item.id}
      dogs-dragging-enabled="false"
      dogs-selection-enabled="false"
      onClick={e => { e.stopPropagation(); toggleCollapsed() }}
      className={cn(

        // Base
      "h-[30px] flex flex-row gap-x-[4px] items-center group/title",

      // Typography
      "text-md font-semibold",

      // Cursors
      "cursor-pointer select-none",

    )}>
      <ChevronDownIcon
        dogs-selection-enabled="false"
        width={16}
        height={16}
        className={cn("transition-transform duration-100", isCollapsed ? "-rotate-90" : "")}
      />
      {item.group.data.title}
      {onClickTitleNav ? (
        <Button
          data-clickable={!!onClickTitleNav}
          variant="ghost"
          size="icon"
          className="w-[24px] h-[24px] p-0 opacity-0 group-hover/title:opacity-100 data-[clickable=false]:opacity-0"
          onClick={e => {
            e.stopPropagation();
            onClickTitleNav();
          }}
        >
          <ArrowRightIcon width={16} height={16} />
        </Button>
      ) : null}
      <div className="flex-1" />
    </TimelineTitle>
    {isCollapsed ? null : item.children}
  </React.Fragment>;

};
ProjectTitle.deps = (item: IterateGroup<ProjectRow>) => {
  return [
    item.id,
    item.group.__children.length,
    item.group.data.updated_at,
  ];
}


const ProjectLane = ({ item, isCollapsed }: { item: RenderableGroup<ProjectRow>, isCollapsed: boolean }) => {
  const start: Date | undefined = item.group.data.end && item.group.data.duration ? new Date(item.group.data.end.getTime() - (item.group.data.duration ?? 0)) : undefined;
  const end: Date | undefined = item.group.data.end ?? new Date();

  return <React.Fragment key={item.id}>
    <TimelineLane
      ref={item.ref}
      rowId={item.id}
      dogs-dragging-enabled="false"
      dogs-selection-enabled="false"
      className={cn(

        // Base
        "h-[30px]",

    )}>
      {start && end && <TimelineDateRange start={start} end={end} />}
    </TimelineLane>
    {isCollapsed ? null : item.children}
  </React.Fragment>;

};
ProjectLane.deps = (item: IterateGroup<ProjectRow>) => {
  return [
    item.id,
    item.group.__children.length,
    item.group.data.updated_at,
  ];
}


// Category


const CategoryTitle = ({ item, isCollapsed, toggleCollapsed, onClickTitleNav }: { item: RenderableGroup<CategoryRow>, isCollapsed: boolean, toggleCollapsed?: () => void, onClickTitleNav?: () => void}) => {

  return <React.Fragment key={item.id}>
    <TimelineTitle
      ref={item.ref}
      rowId={item.id}
      dogs-dragging-enabled="false"
      dogs-selection-enabled="false"
      onClick={e => { e.stopPropagation(); toggleCollapsed?.() }}
      data-clickable={!!toggleCollapsed}
      className={cn(

        // Base
        "h-[30px] flex flex-row gap-x-[4px] items-center pl-[24px] group/title",

        // Typography
        "text-sm font-semibold select-none",

        // Cursors
        "data-[clickable=true]:cursor-pointer select-none",

        // Group
        "group/title",

      )}
    >
      {toggleCollapsed ? <ChevronDownIcon
        dogs-selection-enabled="false"
        width={16}
        height={16}
          className={cn("transition-transform duration-100", isCollapsed ? "-rotate-90" : "")}
        /> : null}
      <Emoji unified={item.group.data.emoji || EmojiDefaultAssetCategory} size={16} />
      {item.group.data.title}
      {onClickTitleNav ? (
        <Button
          data-clickable={!!onClickTitleNav}
          variant="ghost"
          size="icon"
          className="w-[24px] h-[24px] p-0 opacity-0 group-hover/title:opacity-100 data-[clickable=false]:opacity-0"
          onClick={e => {
            e.stopPropagation();
            onClickTitleNav();
          }}
        >
          <ArrowRightIcon width={16} height={16} />
        </Button>
      ) : null}
    </TimelineTitle>
    {isCollapsed ? null : item.children}
  </React.Fragment>;

};
CategoryTitle.deps = (item: IterateGroup<CategoryRow>) => {
  return [
    item.id,
    item.group.__children.length,
    item.group.data.updated_at,
  ];
}


const CategoryLane = ({ item, isCollapsed }: { item: RenderableGroup<CategoryRow>, isCollapsed: boolean }) => {
  const start: Date | undefined = item.group.data.end && item.group.data.duration ? new Date(item.group.data.end.getTime() - (item.group.data.duration ?? 0)) : undefined;
  const end: Date | undefined = item.group.data.end ?? new Date();

  return <React.Fragment key={item.id}>
    <TimelineLane
      ref={item.ref}
      rowId={item.id}
      className={cn(

        // Base
        "h-[30px]",

      )}
    >
      {start && end && <TimelineDateRange
        start={start}
        end={end}
        className="bg-white/50"
      />}
    </TimelineLane>
    {isCollapsed ? null : item.children}
  </React.Fragment>;

};
CategoryLane.deps = (item: IterateGroup<CategoryRow>) => {
  return [
    item.id,
    item.group.__children.length,
    item.group.data.updated_at,
  ];
}


const OPTIONS_DEFAULT = {
  ghostRangeDays: 3,
}

export const ProjectTimeline = ({
  className,
  model,
  callbacks,
  options = OPTIONS_DEFAULT,
}: {
  className?: string;
  model: ProjectTimelineModel;
  callbacks: ProjectTimelineCallbacks;
  options?: {
    ghostRangeDays?: number;
  };
}) => {


  // Callbacks
  const callbacksRef = React.useRef(callbacks);
  callbacksRef.current = callbacks;
  

  // Model
  const { items, rowsById } = useProjectTimeline(model);
  const modelRef = React.useRef(model);
  modelRef.current = model;
  const rowsByIdRef = React.useRef(rowsById);
  rowsByIdRef.current = rowsById;


  // Selection
  const selectedTaskIdsRef = React.useRef<string[]>([]);
  const { ignoreNextDeselection } = useProjectTimelineSelection();
  const selectionManagerRef: React.MutableRefObject<SelectionManager | null> = React.useRef(null);


  // Collapse
  const [isCollapsed, setCollapsed] = useStickyCollapse({ key: "project-timeline-collapsed" });
  const toggleCollapsed = React.useCallback((id: string, defaultValue?: boolean) => setCollapsed(id, !isCollapsed(id, defaultValue)), [
    isCollapsed,
    setCollapsed,
  ]);


  // DOGS configuration
  const { render } = useDogs({
    items,
    selectionMode: "multi",
    selectionManagerRef,
    onSelectionChanged(selectedIds) {
      selectedTaskIdsRef.current = Array.from(selectedIds);
    }
  });


  return <TooltipProvider>
  <TimelineRoot className={className} persistence={{ key: "project-timeline", storage: "local" }}>
    <TimelineHeader>
      <TimelineCorner className="h-full flex flex-row items-center justify-end p-2">
      </TimelineCorner>
    </TimelineHeader>
    <TimelineBody>

      {render({

        root: x => <TitleRoot item={x as RenderableRoot<ProjectTimelineRow>} />,

        rootRenderDeps: TitleRoot.deps,

        group: x => x.group.data.rowType === "project"
          ? <ProjectTitle
            item={x as RenderableGroup<ProjectRow>}
            isCollapsed={isCollapsed(x.group.data.id)}
            toggleCollapsed={() => toggleCollapsed(x.group.data.id)}
            onClickTitleNav={() => callbacks.onClickProject?.(modelRef.current.projectsById[x.group.data.id])}
          />
          : <CategoryTitle
            item={x as RenderableGroup<CategoryRow>}
            isCollapsed={false}
            onClickTitleNav={() => callbacks.onClickCategory?.(modelRef.current.categoriesById[x.group.data.id])}
            // isCollapsed={isCollapsed(x.group.data.id, true)}
            // toggleCollapsed={() => toggleCollapsed(x.group.data.id, true)}
          />,

        groupRenderDeps: x => x.group.data.rowType === "project"
          ? [
            ...ProjectTitle.deps(x as IterateGroup<ProjectRow>),
            toggleCollapsed,
            isCollapsed(x.group.data.id)
          ]
          : [
            ...CategoryTitle.deps(x as IterateGroup<CategoryRow>),
            toggleCollapsed,
            isCollapsed(x.group.data.id, true)
          ]
        ,

        child: x => null,

        childRenderDeps: x => [x.id],

      })}

      {render({

        root: x => <LaneRoot item={x as RenderableRoot<ProjectTimelineRow>} />,

        rootRenderDeps: LaneRoot.deps,

        group: x => x.group.data.rowType === "project"
          ? <ProjectLane
            item={x as RenderableGroup<ProjectRow>}
            isCollapsed={isCollapsed(x.group.data.id)}
          />
          : <CategoryLane
            item={x as RenderableGroup<CategoryRow>}
            isCollapsed={false}
            // isCollapsed={isCollapsed(x.group.data.id, true)}
          />,

        groupRenderDeps: x => x.group.data.rowType === "project"
          ? [
            ...ProjectLane.deps(x as IterateGroup<ProjectRow>),
            isCollapsed(x.group.data.id),
          ]
          : [
            ...CategoryLane.deps(x as IterateGroup<CategoryRow>),
            isCollapsed(x.group.data.id, true),
          ],

        child: x => null,

        childRenderDeps: x => [x.id],

      })}

      </TimelineBody>
      <TimelineZoom />
    </TimelineRoot>
  </TooltipProvider>;

};
