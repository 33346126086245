import { ProjectRoles } from "@palette.tools/model";
import { Project, UserProfile } from "./model";


export function getProjectRole(project: Project, profile: UserProfile) {
  if (project.is_role("admin", profile.id)) return ProjectRoles.admin;
  if (project.is_role("reviewer", profile.id)) return ProjectRoles.reviewer;
  if (project.is_role("editor", profile.id)) return ProjectRoles.editor;
  return ProjectRoles.commenter;
}
