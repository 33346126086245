"use client";

import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useHistoryChange } from './historyChange';


const MAX_HISTORY_ITEMS = 1000; // Set a maximum number of history items to store

// Global state to track all active (mounted) keys
const activeKeys: string[] = [];


// Add these generic functions at the top level
export const getHistoryForKey = (key: string): string[] => {
  return JSON.parse(sessionStorage.getItem(key) || '[]');
};

export const isolatedHistoryBack = (key: string, fallback?: () => void) => {
  const storedHistory = getHistoryForKey(key);
  console.log("isolatedHistoryBack", { key, storedHistory });
  const reversedStack = [...storedHistory].reverse();

  if (reversedStack.length > 1) {
    const stepsBack = storedHistory.length - 1 - 1;
    window.history.go(-stepsBack);
    return;
  }

  if (fallback) {
    fallback();
  }
};

export const isolatedHistoryPeekBack = (key: string): string | null => {
  const storedHistory = getHistoryForKey(key);
  if (storedHistory.length > 1) {
    return storedHistory[storedHistory.length - 2];
  }
  return null;
};

export const isolatedHistoryCurrent = (key: string): string | undefined => {
  const items = getHistoryForKey(key);
  if (items.length) {
    return items[items.length - 1];
  }
};

// The context that will hold our history keys
const IsolatedHistoryContext = createContext<{
  key: string,
  history: string[],
}>({
  key: '',
  history: [],
});

interface IsolatedHistoryProviderProps {
  keyName: string;
  recordIf?: (pathname: string) => boolean;
  children: React.ReactNode;
}


export const IsolatedHistoryProvider: React.FC<IsolatedHistoryProviderProps> = ({ keyName, recordIf, children }) => {
  const { key: parentKey } = useContext(IsolatedHistoryContext);
  const currentKey = parentKey ? `${parentKey}/${keyName}` : keyName;
  const [history, setHistory] = useState<string[]>([]);

  const { addListener, removeListener } = useHistoryChange();

  useEffect(() => {
    // Check for duplicate keys upon mounting
    if (activeKeys.includes(currentKey)) {
      console.error(`Duplicate keyName "${currentKey}" found in IsolatedHistoryProvider`);
    } else {
      activeKeys.push(currentKey);
    }

    // Cleanup upon unmounting
    return () => {
      const index = activeKeys.indexOf(currentKey);
      if (index > -1) {
        activeKeys.splice(index, 1);
      }
    };
  }, [currentKey]);

  // Listen for URL changes to update the history stack
  const handlePopState = useCallback(() => {
    const location = window.location.toString();
    
    // Use a filter to determine whether to record.
    if (recordIf && !recordIf(window.location.pathname)) {
      return;
    }

    let storedHistory = JSON.parse(sessionStorage.getItem(currentKey) || '[]') as string[];

    // Do not record if is a duplicate.
    if (storedHistory.length && storedHistory[storedHistory.length - 1] === location) {
      return;
    }
    console.log("recorded", { currentKey, location });
    storedHistory.push(location);

    // Prune the history if it exceeds the maximum number of items
    if (storedHistory.length > MAX_HISTORY_ITEMS) {
      storedHistory = storedHistory.slice(storedHistory.length - MAX_HISTORY_ITEMS);
    }

    sessionStorage.setItem(currentKey, JSON.stringify(storedHistory));
  }, [currentKey, recordIf]);

  useEffect(() => {
    handlePopState(); // Initialize with the current pathname
    window.addEventListener('popstate', handlePopState);
    addListener(handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
      removeListener(handlePopState);
    };
  }, [currentKey, recordIf, handlePopState, addListener, removeListener]);

  useEffect(() => {
    const storedHistory = JSON.parse(sessionStorage.getItem(currentKey) || '[]') as string[];
    setHistory(storedHistory);
  }, [currentKey]);

  return (
    <IsolatedHistoryContext.Provider value={{ key: currentKey, history }}>
      {children}
    </IsolatedHistoryContext.Provider>
  );
};


export const useIsolatedHistory = (key?: string) => {
  const { key: contextKey } = useContext(IsolatedHistoryContext);
  const targetKey = key || contextKey;

  const _back = useCallback((fallback?: () => void) => {
    isolatedHistoryBack(targetKey, fallback);
  }, [targetKey]);

  const _peekBack = useCallback((): string | null => {
    return isolatedHistoryPeekBack(targetKey);
  }, [targetKey]);

  const _current = useCallback((): string | undefined => {
    return isolatedHistoryCurrent(targetKey);
  }, [targetKey]);

  return { back: _back, peekBack: _peekBack, current: _current };
};
